import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
// import PortfolioMasonry from './PortfolioMasonry';
import { FiCheck } from "react-icons/fi";
import { FaGlobe } from "react-icons/fa";
import VerbosSimilarApps from "../component/Verbos/VerbosSimilarApps";
import { Link } from "react-router-dom";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
// import HospitalAppDemo from "./HospitalAppDemo";
// import HospitalAdminDemo from "./HospitalAdminDemo";
// import CoursierDelivrable from "./CoursierDeliverables";
// import PlatformUsed from "../component/Verbos/PlatformUsed";
const CommonMasonry = React.lazy(() => import("./CommonMasonry"))
const PortfolioList = [
    {
        image: 'hospital-chain-1',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'hospital-chain-2',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'hospital-chain-3',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'hospital-chain-4',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },

]
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
// const technologies = [

//     { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
//     { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
//     { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
//     { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

// ]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #8ab2ff 0%, #4636fc 100%)', link: '/healthcare-insurance-marketplace', img: 'healthcare.webp', h2: 'DocFinder', p: 'Healthcare Insurance Marketplace' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/primary-care-management-system', img: 'federate.webp', h2: 'Federate', p: 'Primry Care Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

const SocialShare = [
    { Social: <FaGlobe />, link: 'https://demo.hanadoc.co.uk/' }
]
class HospitalPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Hospital Managment || Noorisys Technologies Pvt Ltd' pageUrl='/hospital-chain-management-system' metaTitle="Hospital Chain Management System" metaImage="/assets/images/portfolio/dp-portfolio-05.jpg"
                    metaDescription="Efficiently manage your healthcare professional networks and hospital operations with our Hospital Chain Management software." />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb title='Hospital Chain Management System (Secondary Care)' dataBlackOverlay={5} bgImage='bg_image--47' p='Web / Android / iOS Application ' pb='pb--120' socialShare={SocialShare} />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Web / Android / iOS Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>Hospital Management </h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>.NET (C#),Android, JAVA, iOS, Swift, AngularJS</h6>
                                            </div>
                                        </div>
                                        {/* custom-secondary-color */}
                                        <h3 className="rn-page-title theme-gradient ">Hospital Chain Management System</h3>
                                        {/* <p className="subtitle">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p> */}
                                        <p className="justifypara">Hospital chain management system is a powerhouse platform for the healthcare industry to build strong professional networks between doctos and hospitals. Hospital chain management system is a device agnostic platform that works seamlessly on any handheld device or computer. It enables doctors to securely apply for specific desired opportunites across locations, manage their professional profiles and network with other doctors. This system also comes built with customizable dashboards for hospital administrators to create and publish rotas, advertise jobs, verify confidential documents and approve payments.</p>
                                        <p className="justifypara">Hospital chain management system is a robust, integrated platform that has evolved over many years of understanding the core needs of hospital management by the people who have worked on the front line of the NHS hospital structure. It has matured with the advent of contemporary technologies and today is available as a lightweight, contemporary downloadable apps that both doctors and hospitals can use to effectively manage rotas, easily apply for open job positions and facilitate smooth payments.</p>

                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck />Useful for primary care staff including doctors, advanced nurse practitioners, practice nurses, clinical pharmacists, health care assistances and admin staff.</li>
                                            <li className="justifypara"><FiCheck />Each Hospital will be given unique login and can manage their Hospital's profiles, overview of vacancies, obtain holistic views of staff and operations and much more</li>
                                            <li className="justifypara"><FiCheck />Build your professional network</li>
                                            <li className="justifypara"><FiCheck />Powers-up your entire recruitement lifecycle</li>
                                            <li className="justifypara"><FiCheck />Profile management</li>
                                            <li className="justifypara"><FiCheck />Rota management</li>
                                            <li className="justifypara"><FiCheck />Store, Verify, Access and Share Documents.</li>
                                            <li className="justifypara"><FiCheck />Payment approvals</li>
                                            <li className="justifypara"><FiCheck />Smart colaboration</li>
                                            <li className="justifypara"><FiCheck />Advertise jobs</li>
                                            <li className="justifypara"><FiCheck />Leave Management</li>
                                            <li className="justifypara"><FiCheck />Notifications in system</li>
                                            <li className="justifypara"><FiCheck />Push notification on mobile and whatsapp</li>
                                            <li className="justifypara"><FiCheck />Job search and resume development</li>
                                            <li className="justifypara"><FiCheck />Interactive dashboard</li>
                                            <li className="justifypara"><FiCheck />Makes finding available doctors a simple task by eliminating agencies</li>
                                            <li className="justifypara"><FiCheck />Hospitals can communicate with doctors to assign rotas</li>
                                            <li className="justifypara"><FiCheck />View and manage invoices. Track payments for completed shifts. View pending payments and effectively manage all accounting needs for the shifts assigned and/or completed.</li>
                                            <li className="justifypara"><FiCheck />Cross communicate with hosptial admins, accounting staff and other doctors can to bring a cohesive work environment</li>



                                        </ul>



                                        {/* <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> */}
                                    </div>
                                    {/* <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--40"
                >
                    {/* // style={{backgroundColor:'#1d237a'}} */}
                    <div className="container">
                        {/*  <CoursierDelivrable title={'16 Deliverables'} items={deliverables} class={'coursier'} value={[7, 5, 4]} />
                        </div>
                        <div className="ptb--90">
                            <div className="container">
                                <PlatformUsed color='custom-coursier-color' items={technologies} />
                            </div>
                        </div>
                        <div className="rn-portfolio-area bg_color--1 ptb--40">
                            <div className="container"> */}
                        <div className="row">
                            {/* <React.Suspense fallback={<div>loading...</div>} >
                                <HospitalAppDemo />
                                <HospitalAdminDemo />
                            </React.Suspense> */}
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h3 className="title">Screenshots</h3>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" PortfolioList={PortfolioList} folder={'hospital-chain'} />
                            </React.Suspense>
                        </div>

                    </div>
                    <div className="pt--30 pb--10">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <VerbosSimilarApps color='theme-gradient' title={'Similar Apps'} items={similarapps} slidestoshow={[3, 3]} />
                        </React.Suspense>
                        <div className="wrapper">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-12">
                                        <div className="view-more-btn mt--40 text-center">
                                            <Link className="rn-button-style--2 btn-common-color" to="/portfolio"><span>View All Our Projects</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default HospitalPortfolioDetails;
