import React, { useState, useRef } from 'react';
// import emailjs from 'emailjs-com';
import axios from 'axios';
// import { Select } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';

const Result = () => {
    return (
        <p className="success-message">Thank you for contacting us, our HR will contact you shortly.</p>
    )
}
const select_options = ['Job Searching Platform', 'Friend or Aquaintance', 'News Publication', 'Event', 'Social Media', 'Recruiter Contacted Me', 'other']


function Web_DevelopmentForm(props) {
    const [result, showresult] = useState(false);
    const [showbutton, setShowbutton] = useState(false);
    const captchaRef = useRef(null)
    // const [firstname, setFirstname] = useState('')
    // const [lastname, setLastname] = useState('')
    // const [email, setEmail] = useState('')
    // const [phone, setPhone] = useState('')
    // const [location, setLocation] = useState('')
    // const [source, setSource] = useState('')
    // const [cv, setCv] = useState()
    // const [error, setError] = useState(false)
    // // const [posts, setPosts] = useState([]);
    // // const [title, setTitle] = useState('');
    // // const [body, setBody] = useState('');
    // // const [state, setState] = useState('');


    const submitHandler = (event) => {
        event.preventDefault();
        // console.log(props.vacancy)
        if (showbutton) {
            // console.log(source)
            const body = new FormData();
            body.append('form', 'submit-cv')
            body.append('firstname', event.target.firstname.value);
            body.append('lastname', event.target.lastname.value);
            body.append('email', event.target.email.value);
            body.append('applied_for', props.vacancy);
            body.append('phone', event.target.phone.value);
            body.append('location', event.target.location.value);
            body.append('source', event.target.source.value);
            body.append('cv', event.target.cv.files[0])
            // if (firstname.length == 0 || lastname.length == 0) {
            //     return setError(true)
            // }

            axios
                .post(' https://noorisys.com/contact-submit/test/send-email.php', body)
                .then((result) => {
                    // showresult(true);
                    event.target.reset();
                    Swal.fire({ icon: 'success', text: 'Your Resume has been send succesfully we will contact you shortly', title: 'Send Succesfully' })
                    // setTimeout(() => {
                    //     showresult(false);
                    // }, 10000);
                    // console.log(result);
                },
                    (error) => {
                        Swal.fire({ icon: 'error', text: 'Something wrong happened please try again later', title: 'Oops! Something went wrong.' })
                        console.log(error.text);
                    }
                );



            // console.log('The link was clicked.');
        }
        else {
            Swal.fire({ icon: 'info', text: 'Verify You are Human', title: 'Please verify' })
        }
    }


    const onChange = (token) => {
        setShowbutton(true)
        //     const seccret_key = '6LcQafklAAAAALGqtb7_DW6jNdJw-olG-E9yc5pR'
        //     axios.post(`https://www.google.com/recaptcha/api/siteverify`,{
        //         secret:seccret_key,
        //         response:token
        //     })
        //    .then((response)=>{
        //     if(response.data.success){
        //     }
        //    })
        //    .catch((error)=>{
        //     console.log(error)
        //    })
    }

    return (
        <form onSubmit={submitHandler} className="contact-form--2">
            <div className="rn-form-group plr--10">
                <input
                    type="text"
                    name="firstname"
                    id="firstname"
                    // onChange={(e) => setFirstname(e.target.value)}
                    // value={firstname}
                    placeholder="First Name"
                    required
                />
            </div>
            {/* {error && firstname.length <= 0 ? <label>First Name can't be empty</label> : ""} */}
            <div className="rn-form-group plr--10">
                <input
                    type="text"
                    name="lastname"
                    id="lastname"
                    // onChange={(e) => setLastname(e.target.value)}
                    // value={lastname}
                    placeholder="Last Name"
                    required
                />
            </div>

            <div className="rn-form-group plr--10">
                <input
                    type="email"
                    name="email"
                    id="email"
                    // onChange={(e) => setEmail(e.target.value)}
                    // value={email}
                    placeholder="Your Email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    required
                />
            </div>

            <div className="rn-form-group plr--10">
                <input
                    type="tel"
                    name="phone"
                    id="phone"
                    // onChange={(e) => setPhone(e.target.value)}
                    // value={phone}
                    placeholder="Phone Number"
                    pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                    required
                />
            </div>

            <div className="rn-form-group plr--10">
                <input
                    type="text"
                    name="location"
                    id="location"
                    // onChange={(e) => setLocation(e.target.value)}
                    // value={location}
                    placeholder="Location/Remote"
                    required
                />
            </div>

            <div className="rn-form-group plr--10">
                <select id="source" name='source'
                    // onChange={(e) => setSource(e.target.value)} 
                    // value={source}
                    defaultValue=""
                    required>
                    <option value="" disabled>
                        How Did You Hear About Us
                    </option>
                    {select_options.map((data, index) => (
                        <option key={index} >{data}</option>
                    ))}
                </select>
            </div>
            <div className="rn-form-group row plr--5">
                <div className="rn-form-group pl--20">
                    <label className="rn-form-group ptb--10">Upload Your CV in pdf format</label>
                </div>
                <div className="rn-form-group file-input pl--20">
                    <input type="file"
                        //  onChange={(e) => setCv(e.target.files[0])}
                        id="cv"
                        name='cv'
                        accept=".pdf" required />
                </div>
            </div>
            <div className='rn-form-group'>
                <ReCAPTCHA
                    sitekey=" 6LcQafklAAAAABsk4ORLbJLmuYSxYqgNuL_wEyQq"
                    ref={captchaRef}
                    // onVerify={verify} 
                    onChange={onChange}
                />
            </div>
            <div className="rn-form-group pt--20">

                <button className="rn-button-style--2 btn-solid " type="submit" value="submit" name="submit">Submit Now</button>
            </div>

            <div className="rn-form-group">
                {result ? <Result /> : null}
            </div>

        </form>
    )
}
export default Web_DevelopmentForm;
