import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'PHP', p: 'User Panel', img: 'php.png' },
];

const userButtonNames = ['Home', 'Dashboard', 'Composer', 'Account Manager', 'File Manager', 'Water Mark Manager'];
const userImageAddress = [
    '/assets/images/portfolio/social-media-marketing-tool/user-01.webp',
    '/assets/images/portfolio/social-media-marketing-tool/user-02.webp',
    '/assets/images/portfolio/social-media-marketing-tool/user-03.webp',
    '/assets/images/portfolio/social-media-marketing-tool/user-04.webp',
    '/assets/images/portfolio/social-media-marketing-tool/user-05.webp',
    '/assets/images/portfolio/social-media-marketing-tool/user-06.webp',
];

class SocialMediaMarketingToolPortfolio extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Social Media Marketing Tool' pageUrl='/social-media-marketing-tool' metaTitle="The NFC Card project aims to develop a smart visiting card using Near Field Communication (NFC) technology. This card will allow users to easily share their contact details with others by tapping the card on a compatible mobile device." />
                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Social Media Marketing Tool"
                    // customColor="custom-social-media-marketing-portfolio"
                    customColor="theme-gradient"
                    dataBlackOverlay={5}
                    bgImage="bg_image--124"
                    p="Web Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-social-media-marketing-portfolio">Social Media Marketing Tool</h3>
                                        <p className="justifypara">This is a Social Media Marketing Tool that streamlines content planning and execution. It offers features like streamlined scheduling for stress-free content calendars, bulk post scheduling for efficiency, and an organized Image and Video Library. Users can save time with reusable captions and benefit from post-planning tools like the Schedule Calendar and Post Preview. The user-friendly interface facilitates easy social account connection, collaborative management with the Group Manager feature, and content protection through branded watermarks. The built-in File Manager ensures an organized workspace, while Analytics Performance provides valuable insights for a successful social media presence. Streamline daily posting and enhance efficiency with this comprehensive tool.</p>
                                        <h3 className="custom-social-media-marketing-portfolio">Features</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#080e41" />Streamlined Scheduling</li>
                                            <li><FiCheck color="#080e41" />Bulk Post Upload</li>
                                            <li><FiCheck color="#080e41" />Image and Video Library</li>
                                            <li><FiCheck color="#080e41" />Schedule Calendar</li>
                                            <li><FiCheck color="#080e41" />Post Preview</li>
                                            <li><FiCheck color="#080e41" />Social Logins</li>
                                            <li><FiCheck color="#080e41" />Group Manager</li>
                                            <li><FiCheck color="#080e41" />Watermarking</li>
                                            <li><FiCheck color="#080e41" />File Manager</li>
                                            <li><FiCheck color="#080e41" />Analytics Performance</li>
                                            <li><FiCheck color="#080e41" />Bulk Post Scheduling</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-social-media-marketing'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-social-media-marketing-portfolio' items={technologies} />
                        </React.Suspense>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-social-media-marketing'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 pb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='User Website '
                                    p={`Users can effortlessly manage and schedule their social media content and gain valuable insights with this comprehensive Social Media Marketing Tool.`}
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt="social media marketing"
                                    color='social-media-marketing'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-social-media-marketing'} paddingTop={'pt--30'} paddingBottom={'pb--30'} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        {/* <VerbosSimilarApps color='custom-food-delivery-product' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} /> */}
                        <VerbosSimilarApps color='custom-social-media-marketing-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>

                    <CommonPortfolioButtons class='btn-social-media-marketing' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default SocialMediaMarketingToolPortfolio;
