import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PageHelmet from "../common/Helmet";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import WebDevelopmentContactPage from "./Web_Development_Contact_Page";
import CommonBreadcrumb from "../common/CommonBreadcrumb";
import { FiCheck } from "react-icons/fi";

class CareerSalesMarketing extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='career sales and marketing ' pageUrl='/sales-and-marketing-career' metaTitle="Get in touch with Noorisys" metaImage="/assets/images/bg/bg-image-17.jpg"
                    metaDescription="Get in touch with Noorisys - the leading mobile and web development company in India. We are here to answer any of your questions and help you bring your digital vision to life. Contact us today!" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb title='Join Our Team' dataBlackOverlay={6} bgImage='bg_image--96' />
                {/* End Breadcrump Area */}

                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area pt--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        <p>We are seeking a dynamic and results-driven Senior Sales Executive to join our growing team at Noorisys Technologies Pvt Ltd. This is an excellent opportunity for experienced sales professionals to elevate their careers and work on innovative software solutions in a fast-paced environment.</p>
                                        <h4 className="title">Responsibilities:</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck />Develop and implement strategic sales plans to achieve sales targets and objectives.</li>
                                            <li className="justifypara"><FiCheck />Identify and qualify new business opportunities through proactive prospecting and lead generation activities.</li>
                                            <li className="justifypara"><FiCheck />Build and maintain relationships with key decision-makers and stakeholders to understand their business needs and challenges.</li>
                                            <li className="justifypara"><FiCheck />Present and demonstrate our software products and solutions to prospective clients, highlighting key features and benefits.</li>
                                            <li className="justifypara"><FiCheck />Prepare and deliver persuasive sales presentations and proposals that effectively communicate the value proposition of our software offerings.</li>
                                            <li className="justifypara"><FiCheck />Negotiate and close sales agreements, contracts, and pricing terms in accordance with company policies and guidelines.</li>
                                            <li className="justifypara"><FiCheck />Collaborate with internal teams, including marketing, product development, and customer success, to ensure seamless execution and delivery of solutions.</li>
                                            <li className="justifypara"><FiCheck />Provide regular updates and reports on sales activities, pipeline, and performance metrics to senior management.</li>
                                        </ul><br />
                                        <h4 className="title">Requirements:</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck />Bachelor's degree in Business Administration, Marketing, or a related field.</li>
                                            <li className="justifypara"><FiCheck />Proven experience as a Sales Executive or similar role in the software industry, with a strong track record of achieving and exceeding sales targets.</li>
                                            <li className="justifypara"><FiCheck />2-3 years of sales experience in IT sales is a must.</li>
                                            <li className="justifypara"><FiCheck />Excellent communication and interpersonal skills, with the ability to build rapport and establish trust with clients and colleagues.</li>
                                            <li className="justifypara"><FiCheck />Strong negotiation and closing skills, with the ability to effectively overcome objections and address customer concerns.</li>
                                            <li className="justifypara"><FiCheck />Demonstrated ability to work independently and as part of a team in a fast-paced, dynamic environment.
                                            </li>
                                            <li className="justifypara"><FiCheck />Knowledge of software sales techniques, methodologies, and best practices.
                                            </li>
                                            <li className="justifypara"><FiCheck />Experience with CRM software, such as Salesforce, is preferred.
                                            </li>
                                            <li className="justifypara"><FiCheck />Willingness to travel as needed to meet with clients and attend industry events.
                                            </li>
                                        </ul>
                                        <p>If you're a passionate and motivated sales professional ready to drive results and contribute to our company's growth, submit your CV now and fill out the form to join our dynamic team at Noorisys Technologies Pvt Ltd.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}


                </main>

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--60 bg_color--1">
                    <WebDevelopmentContactPage vacancy={'Sales & Marketing'} application='sale_marketing' />
                </div>
                {/* End Contact Page Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default CareerSalesMarketing;