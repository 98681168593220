import React, { Component } from "react";
import ContactForm from "../creative_homePage/ContactForm";

class ContactPage extends Component {
    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">{this.props.title}</h2>
                                <p className="description justifypara">{this.props.text}</p>
                            </div>
                            <div className="form-wrapper">
                                <ContactForm subject={this.props.subject} myParam={this.props.myParam} />
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/about/about-6.webp" alt="Contact Us" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactPage;