import React, { Component } from "react"
import { Link } from "react-router-dom";
import Slider from "react-slick";
// import { portfolioSlick2 } from "../../page-demo/script";



class VerbosSimilarApps extends Component {
    constructor(props) {
        super(props);
        this.sliderRef = React.createRef();
    }

    previousSlide = () => {
        this.sliderRef.current.slickPrev();
    };

    nextSlide = () => {
        this.sliderRef.current.slickNext();
    };
    render() {
        const settings = {
            loop: true,
            // centerMode: true,
            margin: 0,
            hashNavigation: true,
            dots: false,
            responsiveClass: false,
            nav: false,
            autoplay: true,
            draggable: true,
            swipeable: true,
            infinite: true,
            autoplaySpeed: 2400,
            responsive: [
                {
                    breakpoint: 2560,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        centerMode: true
                    }
                },
                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        centerMode: true,
                    }
                },
                {
                    breakpoint: 1124,
                    settings: {
                        slidesToShow: this.props.slidestoshow[1],
                        slidesToScroll: this.props.slidestoshow[1],
                        centerMode: true,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        centerMode: true,
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                    }
                },
                {
                    breakpoint: 300,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false
                    }
                },
                {
                    breakpoint: 0,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false
                    }
                }
            ]
        };

        return (

            <section className="pricing_area_four sec_pad similar_apps">
                <div className="container">
                    <div className="sec_title text-center mb_70 col-12 col-md-10 col-lg-8 mx-auto mb-4" data-wow-delay="0.3s">
                        <div className="row">
                            <div className="col-12 col-md-12 m-auto">
                                <p className="f_400 f_size_16 mb-0 wow fadeInUp mt-0" >
                                    {this.props.text ? this.props.text : "Other apps you may like"}
                                </p>
                                {this.props.h2 ? <h2 className={`${this.props.color}`} style={{ fontSize: '45px' }}> {this.props.title}</h2> :
                                    <h2 className={`${this.props.color}`} >
                                        {this.props.title}
                                    </h2>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Slider ref={this.sliderRef} {...settings} className="section5_carousel owl-carousel owl-theme">
                    {this.props.items.map((value, index) => (
                        <div key={index} className="item">
                            <div className="background" style={{
                                backgroundImage: `${value.backgroundcolor}`,
                                backgroundPosition: 'center',
                                backgroundSize: 'closest-side',
                            }}>
                            </div>
                            <div className="item_img">
                                <Link to={value.link}>
                                    <img src={`/assets/images/similar_apps/${value.img}`} className="img-fluid" alt={`similar ${index}`} loading="lazy" />
                                </Link>
                            </div>
                            <div className="item_info w-100">
                                <div className="item_info_inner">
                                    <Link to={value.link} title={value.p} className="btn_six d-block">
                                        {/* <h2>{value.h2}</h2> */}
                                        <h2 dangerouslySetInnerHTML={{ __html: value.h2 }} />
                                        <p>{value.p}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </section>

        )
    }
}
export default VerbosSimilarApps;
