import React, { useState, useRef, useEffect } from 'react';
// import emailjs from 'emailjs-com';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
// import { name } from 'file-loader';
// import PhoneInput from "react-phone-input-2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const Result = () => {
    return (
        <p className="success-message">Thank you for contacting us, our team will contact you shortly.</p>
    )
}



function ContactForm(props) {
    const [result, showresult] = useState(false);
    const [showbutton, setShowbutton] = useState(false);
    const captchaRef = useRef(null)
    const [errors, setErrors] = useState({});
    const [userbody, setuserBody] = useState({ name: '', email: '', phoneNumber: '', message: '' });
    const [phone, setPhone] = useState({dialCode:'',number:''});
    const [isFocused, setIsFocused] = useState(false);
    const onChange = (token) => {
        setShowbutton(true)
    }
    useEffect(() => {
        if (props.notRobot)
            setShowbutton(true)
    }, [])

    const submitHandler = (event) => {
        event.preventDefault();
        if (props.notRobot)
            setShowbutton(true)
        const isFormValid = validateForm();
        console.log(phone)
        if (isFormValid) {
            if (showbutton) {
                const body = new FormData();
                body.append('name', userbody.name);
                body.append('email', userbody.email);
                body.append('subject', props.subject);
                body.append('mobile', `+${phone.dialCode}${phone.number}`);
                body.append('message', userbody.message);
                body.forEach((value, name) => {
                    console.log(name, value);
                })
                let url = ''
                let url2 = ''
                if (props.myParam === null) {
                    url = 'https://noorisys.com/contact-submit/contact-api.php'
                }
                else {
                    url = 'https://noorisys.com/contact-submit/landing-form.php'
                }
                url2 = 'https://crm.thetechtrial.com/backend/api/inquiry/add'
                // this.props.myParam ? url = 'https://noorisys.com/contact-submit/contact-api.php' : 'https://noorisys.com/contact-submit/landing-form.php'
                // return console.log("user Data", userbody)

                const body2 = new FormData();
                body2.append('name', userbody.name);
                body2.append('email', userbody.email);
                body2.append('mobile', `+${phone.dialCode}${phone.number}`);
                body2.append('message', userbody.message);
                body2.append('inquiry_source', '6');
                body2.append('inquiry_for', props.subject);

                axios
                    .post(url, body)
                    .then((result) => {
                        event.target.reset();
                        setuserBody({ name: '', email: '', phoneNumber: '', message: '' })
                        // console.log(result.text);
                        if (props.closePopup) {
                            props.closePopup()
                        }
                        Swal.fire({ title: 'Thank you', icon: 'success', text: 'Thank you for your interest we will contact you shortly' })
                    },
                        (error) => {
                            Swal.fire({ title: 'Oops', icon: 'error', text: 'Something wrong happened please try again later' })
                        }
                    );

                axios
                    .post(url2, body2)
                    .then((result) => {
                        event.target.reset();
                        setuserBody({ name: '', email: '', phoneNumber: '', message: '' })
                        if (props.closePopup) {
                            props.closePopup()
                        }
                        Swal.fire({ title: 'Thank you', icon: 'success', text: 'Thank you for your interest we will contact you shortly' })
                    },
                        (error) => {
                            Swal.fire({ title: 'Oops', icon: 'error', text: 'Something wrong happened please try again later' })
                            // console.log(error.text);
                        }
                    );

            }
            else {
                Swal.fire({ icon: 'info', text: 'Verify You are Human', title: 'Please verify' })
            }
        }
    }

    const validateForm = () => {
        const newErrors = {};
        const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        if (userbody.name.trim() === "") {
            newErrors.name = "Please provide your name";
        }

        // Validate Email
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userbody.email)) {
            newErrors.email = "Email Address Invalid";
        }

        if (userbody.email.trim() === "") {
            newErrors.email = "Please provide email address";
        }
        if (!phone.number || phone.number.trim() === "") {
            newErrors.phone = "Phone number is required";
        } else if (!phoneNumberRegex.test(phone.number)) {
            newErrors.phone = "Please provide a valid phone number";
        }
        // if (userbody.phoneNumber.trim() !== '') {
        //     if (!phoneNumberRegex.test(userbody.phoneNumber)) {
        //         newErrors.phone = "Please provide valid phone number";
        //     }
        // }
        // if (userbody.phoneNumber.trim() === "") {
        //     newErrors.phone = "Phone number is required";
        // }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    return (
        <form onSubmit={submitHandler}>
            <div className="rn-form-group">
                <input
                    type="text"
                    name="name"
                    id="name"
                    value={userbody.name}
                    placeholder="Your Name"
                    onChange={(e) => setuserBody((prev) => ({ ...prev, name: e.target.value }))}
                />
                {errors.name && (
                    <span className="error-message pb-4 mb-4" style={{ color: "red" }}>
                        {errors.name}
                    </span>
                )}
            </div>

            <div className="rn-form-group">
                <input
                    type="email"
                    name="email"
                    id="email"
                    value={userbody.email}
                    placeholder="Your Email"
                   onChange={(e) => setuserBody((prev) => ({ ...prev, email: e.target.value }))}
                />
                {errors.email && (
                    <span className="error-message mb-4" style={{ color: "red" }}>
                        {errors.email}
                    </span>
                )}
            </div>

            <div className="rn-form-group  mb-4">
                <PhoneInput
                    country={"us"}
                    className="phoneInput"                    
                    value={`${phone.dialCode}${phone.number}`} 
                    onChange={(value,data) => {
                        const dialingCode = data?.dialCode;
                        const phoneNo = value.substring(data.dialCode?.length);
                        setPhone({number: phoneNo, dialCode:dialingCode})
                    }}
                   
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
                  {errors.phone && (
                    <span className="error-message mb-4" style={{ color: "red" }}>
                        {errors.phone}
                    </span>
                )}
                {/* <PhoneInput
                    value={phone}
                    onChange={handlePhoneNumber}
                    country={"sa"}
                    containerClass="form-control"
                    containerStyle={{}}
                    inputClass={` `}
                    inputStyle={{ border: 'none' }}
                    dropdownClass={``}
                    searchClass={``}
                    countryCodeEditable={false}
                    enableSearch
                    searchPlaceholder="Search your country"
                    searchNotFound="Country not found"
                    preferredCountries={["sa", "in", "us"]}
                /> */}
            </div>

            {/* <div className="rn-form-group">
                <input 
                type="text"
                name="subject"
                placeholder="Subject"
                
                />
            </div> */}

            <div className="rn-form-group">
                <textarea
                    name="message"
                    placeholder="Your Message"
                    value={userbody.message}
                    onChange={(e) => setuserBody((prev) => ({ ...prev, message: e.target.value }))}

                >
                </textarea>
            </div>
            {!props.notRobot && <div className='rn-form-group'>
                <ReCAPTCHA
                    sitekey="6LcQafklAAAAABsk4ORLbJLmuYSxYqgNuL_wEyQq"
                    ref={captchaRef}
                    // onVerify={verify} 
                    onChange={onChange}
                />
            </div>}
            <div className="rn-form-group pt--20">
                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit">Submit Now</button>
            </div>

            <div className="rn-form-group">
                {result ? <Result /> : null}
            </div>
        </form>
    )
}
export default ContactForm;
