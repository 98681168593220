import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
// import { FiCast, FiLayers, FiUsers, FiMonitor, FiTruck, FiShoppingCart, FiFlag } from "react-icons/fi";
// import { FaMobileAlt, FaMobile } from "react-icons/fa";
// import { GiHealthNormal } from "react-icons/gi";
import { FaFileAlt, FaCheckSquare, FaChartBar, FaHeadset, FaConnectdevelop, FaLevelUpAlt, FaCloudUploadAlt, FaCode, FaVial, } from "react-icons/fa";
// import { SlWallet } from "react-icons/si";
import Brand from "../creative_homePage/CreativeBrand";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
// import { FcFile, FcApproval, FcDocument } from "react-icons/fc";

// const SlideList2 = [
//     {
//         textPosition: 'text-center',
//         bgImage: 'bg_image--21',
//         category: '',
//         title: 'Customer Support',
//         description: 'Delivering a great customer experience can be a challenging task, Especially if you don’t have the right support. Get it from us.',
//         buttonText: 'Get In Touch',
//         buttonLink: '/contact'
//     }

// ]

const ServiceList = [
    {
        icon: <FaFileAlt />,
        title: 'Proposal',
        description: 'Our highly qualified and experienced professionals look into your requirements and gather required information about your business and the project. We make sure that we understand your requirements well, and then create a proposal.',

    },
    {
        icon: <FaCheckSquare />,
        title: 'Evaluation',
        description: 'Our business process starts with understanding and identifying your idea or business challenges. As we receive information about your project our professionals get straight to work on developing the perfect solution with a great understanding of core requirements.',

    },
    {
        icon: <FaChartBar />,
        title: 'Pre-development Analysis',
        description: "Once the development has been handed over to our team the Project Manager will work on clearly defining the project's scope. The specification process is a highly transparent analysis, with our constant commenting and feedback model.",

    },
    {
        icon: <FaConnectdevelop />,
        title: 'Prototyping',
        description: 'At this stage we offer design mock-ups which are combined with the proposed user structure. This process is in place to ensure the resulting project will be work in accordance with the learned interface interaction, have quality assured feedback before any development starts.',

    },
    {
        icon: <FaCode />,
        title: 'Coding',
        description: 'Our next step is to start the coding, following the best accepted methods. We use various techniques for the front end and backend coding. During the process, we offer you with continual updates and listen to your feedback and implement the same in the next update.',

    },
    {
        icon: <FaVial />,
        title: 'Testing',
        description: 'The quality assurance process is a systematic procedure that monitors our software engineering competency, and regularly confirms the quality in our software development practices. This is driven by our passion to secure conformance to high quality standards such as ISO 9001 and CMMI.',

    },
    {
        icon: <FaCloudUploadAlt />,
        title: 'Publishing/Launching/Deployment',
        description: "This is the final stage of the application development process where the product undergoes customer's approval. We help our clients with the construction of User Acceptance Testing methods, client use cases and in-house testing protocols.",

    },
    {
        icon: <FaLevelUpAlt />,
        title: 'Updates',
        description: 'Ever been to a small business project that looks a decade late and money short? That’s because those sites haven’t been updated and maintained. Lucky for you Noorisys IT Consultant has an updates team committed to keeping your project current, functional and aesthetically pleasing.',

    },
    {
        icon: <FaHeadset />,
        title: 'Support',
        description: 'To maintain the service standards and for the satisfaction of our clients, we offer 2 months warranty totally free. Once the warranty period is complete we offer custom support packages to reflect their individual needs.',

    }
]

class Our_approch extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Our Approach || Noorisys Technologies Pvt Ltd' pageUrl='/our-approach' metaTitle="Our Approach" metaImage="/assets/images/bg/bg-image-5.jpg"
                    metaDescription="At Noorisys, we use the Agile approach for software development. This methodology allows us to work closely with our clients, understand their unique needs and deliver tailored solutions that address those needs." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                {/* <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Our Approach</h2>
                                    <p>If you’re worried about faster performance and reliability, then, our Node JS development solution is for you.</p>
                                </div>
                                <br />
                                <div className="view-more-btn mt--10 text-center">
                                    <Link className="rn-button-style--2 btn-solid text-center" to="/contact-us"><span>Get In Touch</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <CommonBreadcrumb title='Our Approach' dataBlackOverlay={5} bgImage='bg_image--5' buttonTitle={'Get In Touch'} buttonLink={'/contact-us'} />
                {/* End Breadcrump Area */}


                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--40 pb--40 bg_color--1">
                    <div className="container">
                        <div className="row creative-service">
                            {ServiceList.map((val, i) => (
                                <div className="col-12" key={i}>

                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p className="justifypara">{val.description}</p>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}



                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--40">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Brand />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}





                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Our_approch;
