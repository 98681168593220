import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonBlogBanner from "../component/common/CommonBlogBanner";

class DigitalBlogDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Digital Twin || Noorisys Technologies Pvt Ltd' pageUrl='/digital-twin-blog' metaTitle="Digital Twin Of An Organisation: The Emerging Trend" metaImage="/assets/images/blog/blog-04.webp"
                    metaDescription="Explore the latest trends and insights with our blog. Stay informed and up-to-date with our in-depth articles." />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBlogBanner date={'May 27, 2022'} writer={'AFIFA ANSARI'} titlehtml={'Digital Twin Of An Organisation: <br />The Emerging Trend'} />
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <h3 className="rn-page-title theme-gradient">Digital Twin Of An Organisation:The Emerging Trend</h3>
                                        <p className="justifypara">A Digital Twin of an Organization is a dynamic digital model of any organization. It guides you to focus on your organization’s strategic objectives and internal processes to increase your overall productivity.  A digital twin is a virtual model that simulates the physical components and behaviors of how an IoT device functions throughout all phases of its lifecycle. A digital twin is created using sensors to collect comprehensive real-time data from real-world elements. That data is then used to create a digital duplicate that can help teams better understand and analyze real-world things or systems.</p>
                                        <p className="justifypara">Data and analytics are the drivers of digital twins. Key elements of digital twins are a model of a real-world object; data including identity, time, context, and events; uniqueness, meaning that the model corresponds to a unique physical thing; and an ability to monitor the real-world object, including an ability to query or receive notifications about specific events or changes.</p>
                                        <h4 className="title">Need of DTO</h4>
                                        <p className="justifypara">We live in a time of unprecedented social and economic change. For an organization to survive and prosper, it must be able to innovate continually and adapt rapidly to new situations on an enterprise-wide basis. Transformations can consist of a change in mission, organizational re-design, new systems, or IT infrastructure. These broad transformations impact not only people but also processes and technology. Mavim offers software that facilitates the creation of a dynamic, virtual representation of an organization in its operational context – what Gartner calls a Digital Twin of an Organization.</p>
                                        <p className="justifypara">Digital Twin of an Organization technologie have many applications that range from enterprise performance and cost optimization to customer experience management, but all applications of the digital twin are intended to support organizations in their decision-making processes. Creating a virtual copy of an organization is meant to help business leaders explore their options, engage in scenario planning, and minimize the risk of the organization’s chosen path.</p>
                                        <p className="justifypara">Internet of Things technology will enable many more use cases. A Digital Twin of your Organization (DTO) is the same, a digital model that shows how your enterprise is constructed operates, and evolves. Now, this idea is not exactly new, even though the term was coined by Gartner only recently.</p>
                                        <p className="justifypara">There are some new developments, however. Such models were designed and maintained separately from the business-in-operation in the past. Nowadays, we can integrate live operational data from many different sources into our models. As argued in the first blog mentioned at the top, this offers a whole range of new possibilities.</p>
                                        <p className="justifypara">Creating a Digital Twin of your Organization</p>
                                        <p className="justifypara">But how do you go about building such a digital twin? We use a five-step iterative process, shown below:</p>
                                        <h4 className="title">1. Model your enterprise</h4>
                                        <p className="justifypara">The first step in creating a DTO is of course to use formalized models of your:</p>
                                        <ul className="list-style">
                                            <li className="justifypara">Strategic direction and business models</li>
                                            <li className="justifypara">Current and desired assets & capabilities</li>
                                            <li className="justifypara">Organization & processes</li>
                                            <li className="justifypara">IT & other technology that supports your operations</li>
                                            <li className="justifypara">Change initiatives that evolve and transform your enterprise</li>
                                        </ul>
                                        <p className="justifypara">In many different blog posts and whitepapers, we have explained these kinds of models, so we will not elaborate on those here. However, one important thing to note is that increasingly, you can use automation to speed up modeling the current state of your enterprise. For example, you can import information from workflow tools, process mining, CMDBs, et cetera, and generate models instead of building them manually.</p>
                                        <p className="justifypara">Of course, the expertise of architects and other designers is still essential in creating the necessary abstractions that help you see the forest for the trees: deciding what to abstract from, where to generalize and which details are irrelevant is not something we can easily automate. Moreover, designing the future of your enterprise cannot be automated either.</p>
                                        <p className="justifypara">This is not based on one monolithic, all-encompassing model of everything. Rather, different aspects are captured in different models, maintained by different (but collaborating) communities and disciplines. And all these various models are interconnected to form a coherent backbone that offers a line of sight between the strategic direction, operations, and change of the enterprise.</p>
                                        <h4 className="title">2. Add data to your enterprise models</h4>
                                        <p className="justifypara">The second step in creating a DTO is to add relevant operational data from the live enterprise to your integrated models. This can include, for example:</p>
                                        <ul className="list-style">
                                            <li className="justifypara">Data on your product portfolio (market share, revenue, etc.)</li>
                                            <li className="justifypara">Customer experience data (e.g. user satisfaction, net promoter score)</li>
                                            <li className="justifypara">Performance data (process throughput, productivity, availability, etc.)</li>
                                            <li className="justifypara">Cost of resources (e.g. personnel, licenses, maintenance, infrastructure)</li>
                                            <li className="justifypara">Technology lifecycle (business & technical value, end-of-life, etc.)</li>
                                            <li className="justifypara">Project performance (time, budget, the value created, etc.)</li>
                                        </ul>
                                        <br />
                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail">
                                                <img className="w-100" src="/assets/images/blog/blog-single-04.webp" alt="digital twin blog" loading="lazy" />
                                            </div>
                                            <div className="content">
                                                <p className="justifypara">Of course, these are just some examples, and the specifics of your enterprise will determine what kinds of data are available and useful. There may be some battles to fight within your organization to gain access to certain data sources, but that is beyond the scope of this blog.</p>
                                                <p className="justifypara">More importantly, data quality is key: garbage in, garbage out. So before adding any data to your model, you should evaluate that data according to common quality attributes, such as:</p>
                                                <ul className="list-style">
                                                    <li className="justifypara">Accuracy: Does the data correctly represent the world it describes?</li>
                                                    <li className="justifypara">Precision: What is the level of detail of the data?</li>
                                                    <li className="justifypara">Completeness: Is the data available for relevant parts of your model?</li>
                                                    <li className="justifypara">Currency: is the data current with the world it describes?</li>
                                                    <li className="justifypara">Timeliness: Is the data available on time?</li>
                                                    <li className="justifypara">Consistency: Is the data consistent with other data sets?</li>
                                                    <li className="justifypara">Lineage: Do you know where the data comes from (e.g. a trusted source)?</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <p className="justifypara">You can aggregate and integrate the data you added in many different ways. The picture below, for example, shows a cost calculation model for applications, where the different cost drivers are added up according to volume, resource usage et cetera. The aggregated cost per application can in turn be distributed to, for example, the business units of the organization based on the intensity of their use in the various business processes.</p>
                                        <h4 className="title">3. Visualize and analyze your enterprise</h4>
                                        <p className="justifypara">Once you have enriched your models with relevant data, you can use this to perform various kinds of analyses. Think of aspects such as:</p>
                                        <ul className="list-style">
                                            <li className="justifypara">Business model scenarios</li>
                                            <li className="justifypara">Financial parameters, cost allocation, investment priorities</li>
                                            <li className="justifypara">Product portfolio performance, market share, revenue</li>
                                            <li className="justifypara">Capability maturity and growth</li>
                                            <li className="justifypara">Business continuity, dependencies, risks</li>
                                            <li className="justifypara">Customer experience and satisfaction</li>
                                            <li className="justifypara">Process performance, bottlenecks, failure rates, utilization</li>
                                            <li className="justifypara">Security & compliance, data usage, vulnerabilities</li>
                                            <li className="justifypara">Application portfolio, IT lifecycle</li>
                                        </ul>
                                        <p className="justifypara">To convey the right message and create a solid understanding of your enterprise, suitable visualizations are also key. This may range from simple tables and lists via ‘classical’ models in languages like ArchiMate and BPMN, to colorful heatmaps, charts, and interactive dashboards.</p>
                                        <h4 className="title">4. Control and change your enterprise</h4>
                                        <p className="justifypara">This is where the rubber meets the road. Based on the analyses and visualizations from the previous steps, decision-makers at all levels of the organization can use the information to direct, control, and change the enterprise. This may range from simple parameter optimizations in a production process by domain experts on the ‘shop floor’ to major business transformations initiated by C-level management. Since all the information is connected to incoherent model space, any change can be evaluated up-front as part of a holistic picture. Vice versa, changes in relevant data from the outside world can be fed into the Digital Twin to assess their impact on the enterprise.</p>
                                        <h4 className="title">5. Improve your models</h4>
                                        <p className="justifypara">Finally, you have to close the loop. As George Box famously said: “All models are wrong but some are useful.” Your model is never a complete picture of reality, but you should keep improving it. First of all, you should check their quality: Are your models technically sound? Are they consistent? Are they understood? Second, you need to make sure that your models don’t deviate from reality too much. Do they still represent the real world accurately enough? What has changed out there? Do you need to recalibrate?</p>
                                        <p className="justifypara">Building a DTO is not a one-shot exercise; it is a journey, not a destination. You gradually add more and more information, finetuning and enriching your model over time. Finally, to improve the quality of your models, you need to make sure that this feedback loop is fast enough. That way you can prevent your models from becoming outdated.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default DigitalBlogDetails;