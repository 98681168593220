import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

// const SocialShare =[
//     {Social:<FaGlobe />,link:' https://annachi.fr/ '}
// ]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'Angular', p: 'Customer Panel Development', img: 'angular.png' },
    { h5: 'Node JS', p: 'Customer Backend Development', img: 'node.png' },
    { h5: 'C#', p: 'Admin Panel Development', img: 'c.png' },
    { h5: 'DotNet', p: 'Admin Backend Development', img: 'dotnet.png' },
    // { h5: 'Native  Android', p: 'App Development', img: 'native.png' },
]




const adminButtonNames = ['Home', 'Admin', 'Prefer Suppliers', 'Logistics', 'Logistics Dashboard', 'Inventory Status', 'Case Status'];
const adminImageAddress = [
    '/assets/images/portfolio/service-management/admin/admin-01.webp',
    '/assets/images/portfolio/service-management/admin/admin-02.webp',
    '/assets/images/portfolio/service-management/admin/admin-03.webp',
    '/assets/images/portfolio/service-management/admin/admin-04.webp',
    '/assets/images/portfolio/service-management/admin/admin-05.webp',
    '/assets/images/portfolio/service-management/admin/admin-06.webp',
    '/assets/images/portfolio/service-management/admin/admin-07.webp',
];

const userButtonNames = ['Dashboard', 'Incidents Overview', 'Incidents Details', 'Manage User', 'Setup Company', 'Invite Company User'];
const userImageAddress = [
    '/assets/images/portfolio/service-management/user/user-01.webp',
    '/assets/images/portfolio/service-management/user/user-02.webp',
    '/assets/images/portfolio/service-management/user/user-03.webp',
    '/assets/images/portfolio/service-management/user/user-04.webp',
    '/assets/images/portfolio/service-management/user/user-05.webp',
    '/assets/images/portfolio/service-management/user/user-06.webp',
    '/assets/images/portfolio/service-management/user/user-07.webp',
];
class ServiceManagementPortfolio extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Service Management and Resolution Platform' pageUrl='/service-management-resolution-platform' metaTitle="This comprehensive system comprises two main web panels: the Admin Panel, where users created by the admin take charge of resolving service cases and managing suppliers, logistics, sales, and the response centre; and the Customer Panel, enabling users to create service case tickets for customer-raised issues. " />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Service Management and Resolution Platform"
                    customColor="theme-gradient"
                    dataBlackOverlay={5}
                    bgImage="bg_image--118"
                    p="Web Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-ssg-portfolio">Service Management and Resolution Platform</h3>
                                        <p className="justifypara">This comprehensive system comprises two main web panels: the Admin Panel, where users created by the admin take charge of resolving service cases and managing suppliers, logistics, sales, and the response centre; and the Customer Panel, enabling users to create service case tickets for customer-raised issues. The admin monitors and resolves cases through the Response Centre, while the customer panel allows the creation and management of incidents, users, and companies, ensuring a streamlined process for effective issue resolution.</p>
                                        <h3 className="custom-ssg-portfolio">Admin Panel Features</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#3B2064" />User Management</li>
                                            <li><FiCheck color="#3B2064" />Supplier Management</li>
                                            <li><FiCheck color="#3B2064" />Inventory Control</li>
                                            <li><FiCheck color="#3B2064" />Sales Management</li>
                                            <li><FiCheck color="#3B2064" />Response Center</li>
                                            <li><FiCheck color="#3B2064" />Planning</li>
                                        </ul>
                                        <h3 className="custom-ssg-portfolio">Customer Panel Features</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#3B2064" />Document Management</li>
                                            <li><FiCheck color="#3B2064" />Service Request</li>
                                            <li><FiCheck color="#3B2064" />User Creation</li>
                                            <li><FiCheck color="#3B2064" />Company Management</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-ssg'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-ssg-portfolio' items={technologies} />
                        </React.Suspense>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-ssg'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 pb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Customer Panel'
                                    p={`In the customer panel, users can create new incident/service cases, manage documents and users, and define roles and responsibilities, contributing to a seamless process visible on the Admin Panel under the Response Centre tab for effective issue resolution.`}
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt="Customer"
                                    color='ssg'
                                />

                                <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-ssg'} paddingTop={'pt--30'} />

                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p={`The admin panel facilitates the creation and management of users responsible for resolving service cases, overseeing supplier relationships, logistics and sales activities, and the response centre for addressing service cases efficiently.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt="Admin"
                                    color='ssg'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-ssg'} paddingTop={'pt--30'} paddingBottom={'pb--30'} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-ssg-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-ssg' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default ServiceManagementPortfolio;
