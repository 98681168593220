import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import FooterTwo from "../component/footer/FooterTwo";
import Header from "../component/header/Header";
import PageHelmet from "../component/common/Helmet";
import CommonSliderBanner from "../component/common/CommonSliderBanner";

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Exclusive Referral Program ',
        // title: 'Exclusive Referral Program - Terms and Conditions',
        // description: 'There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.',
        buttonTexts: 'Contact Us',
        buttonLinks: `https://wa.me/7796614664?text='Hi Team Noorisys! I'm interested in referring a project to you. '`
    }
]

const ServiceListOne = [
    {
        icon: "icon-011.png",
        title: 'Give a reference',
        description: 'Refer a new project to Noorisys and if it gets approved, you become eligible for rewarding benefits.'
    },
    {
        icon: "icon-021.png",
        title: 'Get a rewarded',
        description: 'You will be qualified for up to 100 hours of free work for your project by meeting the eligibility criteria.'
    },
    {
        icon: "icon-031.png",
        title: 'Reward to referred project',
        description: 'The referred project will experience our services directly with a special bonus of upto 50 hour of free work.'
    },

]

class InteriorLanding extends Component {

    render() {

        return (
            <Fragment>
                <PageHelmet pageTitle='Exclusive Referral Program' pageUrl='/referral-program' metaTitle="Exclusive Referral Program" metaImage="/assets/images/portfolio/dp-portfolio-11.jpg"
                    metaDescription="Exclusive Referral Program for Existing Client" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo1.png" />
                {/* <Helmet pageTitle="Interior Design" /> */}



                {/* Start Slider Area   */}
                {/* <div className="slider-activation slider-creative-agency" id="home">
                    <div className="bg_image bg_image--98" data-black-overlay="4">
                        {SlideList.map((value, index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`} target="_blank" rel="noopener noreferrer">{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}
                <CommonSliderBanner
                    slideList={SlideList}
                    col={'col-lg-12'}
                    classes={'slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center bg_image bg_image--98'}
                    titleClass={'theme-gradient'}
                    dataBlackOverlay={4}
                />
                {/* End Slider Area   */}

                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="service">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="feature-area">
                                    {/* <span>FUTURE HOUSE</span> */}
                                    <h3 className="title justifypara mt--20 fontWeight500 lineheight--1-8">We are thrilled to share an exciting new offer exclusively for our valued clients at Noorisys. By participating, you can earn up to 100 hours of free work for your current project, while the referred project will receive a bonus upto 50 free work hours.</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper mt--30">
                            {ServiceListOne.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--1">
                                            <div className="icon">
                                                <img src={`/assets/images/icons/${val.icon}`} alt="Service Icon" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">{val.title}</h4>
                                                <p className="justifypara">{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Call To Action Area  */}
                <div className="call-to-action-wrapper call-to-action bg_image bg_image--99 text-white-wrapper ptb--180 ptb_lg--80 ptb_md--80 ptb_sm--80" id="getstart">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="inner text-left">
                                    <span>READY TO DO THIS</span>
                                    <h2>Let's get to work</h2>
                                    <a className="rn-button-style--2 mt--70 mt_sm--30 mt_md--30" href="https://wa.me/7796614664?text='Hi Team Noorisys! I'm interested in referring a project to you. '" target="_blank" rel="noopener noreferrer"><span>Refer a Project</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Call To Action Area  */}



                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <h4>Here's a quick overview of how it works:</h4>
                                        <p className="justifypara">1. If you refer a new project to Noorisys and it gets approved, you will be eligible for up to 100 hours of free work for your ongoing project.</p>
                                        <p className="justifypara">2. The referred project will receive a special bonus upto 50 hours of free work, allowing them to experience our services firsthand.</p>
                                        <h4 className="justifypara">We kindly request you to review the following Terms and Conditions*</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck />As part of our appreciation, we offer a 10% bonus in free work hours for the project. For instance, if the project duration is 1,000 hours, you will receive 100 hours and the referred project will receive upto 50 hours of free work.</li>
                                            <li className="justifypara"><FiCheck />The allocation of free work hours will be applied once the referred project is successfully on boarded. </li>
                                            {/* <li className="justifypara"></li> */}
                                        </ul>
                                        {/* <h4></h4>
                                        <h4></h4>
                                        <h4></h4>
                                        */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Video Area  */}
                <div className="rn-section  bg_color--1">
                    <div className="container">
                        <div className="row sercice-details-content align-items-center">
                            <div className="col-lg-12">
                                <div className="thumb position-relative">
                                    <img className="w-100" src="/assets/images/bg/image_3.png" alt="referal 1" loading="lazy" />
                                    {/* <VideoModal /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Video Area  */}

                <div className="rn-blog-details pt--110  bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p className="justifypara">We believe this referral program presents a fantastic opportunity for you to earn additional work hours and extend the benefits to your network. By referring new projects to Noorisys, you not only help us grow our client base but also unlock added value for your own projects. </p>
                                        <p className="justifypara">Thank you for your continued trust and support as a valued client of Noorisys. We genuinely appreciate the opportunity to work together on exciting new projects.</p>
                                        <p className="justifypara">If you have any inquiries or require further information about this exclusive offer, please do not hesitate to reach out to us. Our dedicated team is here to assist you.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper pb--40">
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--10 text-center">
                                    <a className="rn-button-style--2 btn-solid" href="https://wa.me/7796614664?text='Hi Team Noorisys! I'm interested in referring a project to you. '" text="schedule a demo" target="_blank" rel="noopener noreferrer">Contact Us</a>
                                    {/* <Link className="rn-button-style--2 btn-solid" to="/tech-stack"><span>View Our Tech Stack</span></Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default InteriorLanding;