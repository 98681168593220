import React, { Component } from "react";
import { Helmet } from 'react-helmet'
// require('dotenv').config()

class PageHelmet extends Component {
    //   google_veri = process.env.GoogleVerificationKey
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle}</title>
                    <meta name="title" content={`${this.props.metaTitle}`} />
                    <meta name="description" content={`${this.props.metaDescription}`} />
                    <meta property="og:url" content={`https://noorisys.com${this.props.pageUrl}`} />
                    <meta property="og:image" content={`${this.props.metaImage}`} />
                    <meta property="og:image:width" content="800" />
                    <meta property="og:image:height" content="530" />
                    <meta property="og:image:width" content="300" />
                    <meta property="og:image:height" content="250" />
                    <meta property="og:description" content={`${this.props.metaDescription}`} />
                    <meta property="og:title" content={`${this.props.metaTitle}`} />
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={`${this.props.metaTitle}`} />
                    <meta name="twitter:description" content={`${this.props.metaDescription}`} />
                    <meta name="twitter:image" content={`${this.props.metaImage}`} />
                    <meta name="language" content="en" />
                    <meta name="keywords" content={`${this.props.metaKeywords}`} />
                    <meta name="google-site-verification" content="cAPsvUVhg7dWfBU5BXNcc_Ogh4UsHSDqT8WFJI0RJMI" />
                    {/*  Google tag (gtag.js) */}
                    {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-04C129YJ06"></script>
                    <script>
                        {`
                           window.dataLayer = window.dataLayer || [];
                           function gtag(){dataLayer.push(arguments);}
                           gtag('js', new Date());
                           gtag('config','G-04C129YJ06' );
                        `}
                    </script> */}

                    <link rel="canonical" href={`https://noorisys.com${this.props.pageUrl}`} />
                    {/* <script type="application/ld+json" >
                        {JSON.stringify({
                            "@context": "https://schema.org/",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Home Page | Noorisys Technologies Pvt. Ltd.",
                                "item": "https://noorisys.com/"
                            }, {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "Our Services | Web and Mobile App Development",
                                "item": "https://noorisys.com/our-services"
                            }, {
                                "@type": "ListItem",
                                "position": 3,
                                "name": "Healthtech",
                                "item": "https://noorisys.com/healthtech"
                            }, {
                                "@type": "ListItem",
                                "position": 4,
                                "name": "Portfolio",
                                "item": "https://noorisys.com/portfolio"
                            }]
                        })}
                    </script> */}


                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
