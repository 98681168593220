import React from 'react';
import { Modal } from 'antd';
import ContactForm from '../../creative_homePage/ContactForm';
function GetaquotePopup({ isVisible, closePopup }) {
    const currentUrl = window.location.href;
    return (
        <div>

            <Modal
                title="Get a Quote"
                visible={isVisible}
                footer={null}
                onCancel={closePopup}
            >
                <div className='contact-form--1'>
                    <ContactForm notRobot={true} closePopup={closePopup} subject={currentUrl} />
                </div>
            </Modal>
        </div>
    );
};
export default GetaquotePopup