import React, { useEffect } from 'react'
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import BrandTwo from '../creative_homePage/CreativeBrand';
import OurProductsTab from '../portfolio/OurProducts';
import CommonTextLoop from '../component/common/CommonTextloopBanner';
const VerbosSimilarApps = React.lazy(() => import('../component/Verbos/VerbosSimilarApps'));
const NoorisysVideo = React.lazy(() => import('./NoorisysVideo'));

function usePageViewTracking(location) {
    useEffect(() => {
        window.gtag("config", "AW-16650476770", {
            page_title: location.pathname,
            page_path: location.pathname,
        });
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', location.pathname]);
        _hsq.push(['trackPageView']);
    }, [location]);
}

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Welcome to my World',
        description: '',
        buttonText: '',
        buttonLink: '',
        imageSrc: '/assets/images/about/our-products_2.webp',
        title: '',
        bgClass: 'bg_image--101',
        textLoopItems: [
            ' Digital  <br />Business Card',
            'Social <br />Platform',
            'Food <br /> Delivery',
            'Service<br /> Marketplace',
            'Grocery <br />App',
            'School <br />Management',
            'Learning <br />Management',
            'Electronic <br />Medical Report'],
        titleColor: '#ffffff',
        ourProduct: 'designer-thumbnail-ourproduct'
    }
]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

const OurProductsTechTrailcopy = (props) => {
    usePageViewTracking(props.location);
    return (
        <div>
            <Helmet pageTitle="Our Products || Noorisys Technologies Pvt Ltd" pageUrl='/our-products' metaTitle="Our Products" metaImage="/assets/images/about/Healthcare_header _03.webp"
                metaDescription="Noorisys - A trusted partner for healthcare technology solutions. We provide secure and efficient software development services for hospitals, clinics, and medical devices companies. Let us help you improve patient outcomes and streamline processes." />
            {/* Start Header Area  */}
            <Header headerPosition="header--static" logo="symbol-dark" color="color-black" />
            {/* End Header Area  */}
            {/* <div className="slider-wrapper">

                Start Single Slide
                {SlideList.map((value, index) => (
                    <div className="slide designer-portfolio slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--101" key={index}>

                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-5">
                                    <div className="designer-thumbnail-ourproduct">
                                        <img src="/assets/images/about/our-products_2.webp" alt="Slider " loading='lazy' />
                                    </div>
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className={`inner ${value.textPosition}`}>
                                        <h1 className="title" style={{ color: '#ffffff' }}>Get Your <br />
                                            <TextLoop>
                                                <span> Digital  <br />Business Card</span>
                                                <span> Social <br />Platform</span>
                                                <span> Food <br /> Delivery</span>
                                                <span>Service<br /> Marketplace</span>
                                                <span> Grocery <br />App</span>
                                                <span> School <br />Management</span>
                                                <span> Learning <br />Management</span>
                                                <span> Electronic <br />Medical Report</span>
                                            </TextLoop>{" "}
                                        </h1>
                                        <h2 style={{ color: '#ffffff' }}>NOW</h2>
                                        {value.description ? <p className="description">{value.description}</p> : ''}
                                        {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="view-more-btn mt--10 text-left">
                                                <Link className="rn-button-style--2 btn-solid" to="/contact-us"><span>Send an inquiry</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ))}
                End Single Slide
            </div> */}

            <CommonTextLoop slideList={SlideList} />

            {/* Start Page Wrapper */}
            <div className="designer-portfolio-area ptb--40 bg_color--1">
                <div className="wrapper plr--70 plr_sm--30 plr_md--30">
                    <OurProductsTab column="col-lg-4 col-md-6 col-sm-6 col-12" />
                </div>
            </div>


            {/* Start Brand Area */}
            <div className="rn-brand-area bg_color--1 ptb--30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <BrandTwo />
                            </React.Suspense>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Brand Area */}
            {/* <Video />   */}

            <React.Suspense fallback={<div>loading...</div>} >
                <NoorisysVideo />
            </React.Suspense>

            <div className="portfolio-area ptb--40 bg_color--5">
                <div className='pt--100'>
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='theme-gradient' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                </div>
            </div>



            {/* Start Footer Style  */}
            <Footer />
            {/* End Footer Style  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}



        </div>
    )
}

export default OurProductsTechTrailcopy
