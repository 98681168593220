import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
const ProductCommonApp = React.lazy(() => import("../portfolio/ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("../portfolio/CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'PHP', p: 'Admin Panel', img: 'php.png' },
    { h5: 'CodeIgniter', p: 'Backend Development', img: 'ci4.png' },
    { h5: 'Mysql', p: 'Database', img: 'mysql.png' },
    { h5: 'Native  Android', p: 'App Development', img: 'native.png' },
]
const studentAppButtonNames = ['Login', 'Dashboard', 'Attendance', 'Report Card', 'Fees', 'Time Table', 'Lesson Plan', 'Syllabus Status', 'Lesson Topic', 'Homework', 'Apply Leave', 'Edit Leave', 'Download Center'];
const studentAppImageAddress = [
    '/assets/images/our-products/school-management/student-app/student-01.webp',
    '/assets/images/our-products/school-management/student-app/student-02.webp',
    '/assets/images/our-products/school-management/student-app/student-03.webp',
    '/assets/images/our-products/school-management/student-app/student-06.webp',
    '/assets/images/our-products/school-management/student-app/student-07.webp',
    '/assets/images/our-products/school-management/student-app/student-08.webp',
    '/assets/images/our-products/school-management/student-app/student-09.webp',
    '/assets/images/our-products/school-management/student-app/student-10.webp',
    '/assets/images/our-products/school-management/student-app/student-11.webp',
    '/assets/images/our-products/school-management/student-app/student-12.webp',
    '/assets/images/our-products/school-management/student-app/student-13.webp',
    '/assets/images/our-products/school-management/student-app/student-14.webp',
    '/assets/images/our-products/school-management/student-app/student-15.webp',
];
const parentAppButtonNames = ['Home', 'Side Menu', 'Attendance', 'Examinations', 'Exam Schedule', 'Report Card', 'Apply Leave', 'Profile Personal', 'Profile Parents', 'Timetable', 'Download Center', 'Notice Board', 'Teachers Review', 'Transport Route'];
const parentAppImageAddress = [
    '/assets/images/our-products/school-management/parent-app/parent-01.webp',
    '/assets/images/our-products/school-management/parent-app/parent-02.webp',
    '/assets/images/our-products/school-management/parent-app/parent-03.webp',
    '/assets/images/our-products/school-management/parent-app/parent-06.webp',
    '/assets/images/our-products/school-management/parent-app/parent-07.webp',
    '/assets/images/our-products/school-management/parent-app/parent-08.webp',
    '/assets/images/our-products/school-management/parent-app/parent-09.webp',
    '/assets/images/our-products/school-management/parent-app/parent-10.webp',
    '/assets/images/our-products/school-management/parent-app/parent-11.webp',
    '/assets/images/our-products/school-management/parent-app/parent-12.webp',
    '/assets/images/our-products/school-management/parent-app/parent-13.webp',
    '/assets/images/our-products/school-management/parent-app/parent-14.webp',
    '/assets/images/our-products/school-management/parent-app/parent-15.webp',
    '/assets/images/our-products/school-management/parent-app/parent-16.webp',
    '/assets/images/our-products/school-management/parent-app/parent-17.webp',
];
const studentButtonNames = ['My Profile', 'Fees', 'Class Timetable', 'Lesson Plan', 'Syllabus Status', 'Homework', 'Online Exam', 'Apply Leave', 'Download Center', 'Attendance', 'Examinations', 'Notice Board', 'Teachers Reviews', 'Library', 'Transport Routes', 'Hostel Rooms']
const studentImageAddress = [
    '/assets/images/our-products/school-management/parent/parent-01.webp',
    '/assets/images/our-products/school-management/parent/parent-02.webp',
    '/assets/images/our-products/school-management/parent/parent-03.webp',
    '/assets/images/our-products/school-management/parent/parent-04.webp',
    '/assets/images/our-products/school-management/parent/parent-05.webp',
    '/assets/images/our-products/school-management/parent/parent-06.webp',
    '/assets/images/our-products/school-management/parent/parent-07.webp',
    '/assets/images/our-products/school-management/parent/parent-08.webp',
    '/assets/images/our-products/school-management/parent/parent-09.webp',
    '/assets/images/our-products/school-management/parent/parent-10.webp',
    '/assets/images/our-products/school-management/parent/parent-11.webp',
    '/assets/images/our-products/school-management/parent/parent-12.webp',
    '/assets/images/our-products/school-management/parent/parent-13.webp',
    '/assets/images/our-products/school-management/parent/parent-14.webp',
    '/assets/images/our-products/school-management/parent/parent-15.webp',
    '/assets/images/our-products/school-management/parent/parent-16.webp',
]

const parentButtonNames = ['My Profile', 'Fees', 'Class Timetable', 'Lesson Plan', 'Syllabus Status', 'Homework', 'Online Exam', 'Apply Leave', 'Download Center', 'Attendance', 'Examinations', 'Notice Board', 'Teachers Reviews', 'Library', 'Transport Routes', 'Hostel Rooms'];
const parentImageAddress = [
    '/assets/images/our-products/school-management/parent/parent-01.webp',
    '/assets/images/our-products/school-management/parent/parent-02.webp',
    '/assets/images/our-products/school-management/parent/parent-03.webp',
    '/assets/images/our-products/school-management/parent/parent-04.webp',
    '/assets/images/our-products/school-management/parent/parent-05.webp',
    '/assets/images/our-products/school-management/parent/parent-06.webp',
    '/assets/images/our-products/school-management/parent/parent-07.webp',
    '/assets/images/our-products/school-management/parent/parent-08.webp',
    '/assets/images/our-products/school-management/parent/parent-09.webp',
    '/assets/images/our-products/school-management/parent/parent-10.webp',
    '/assets/images/our-products/school-management/parent/parent-11.webp',
    '/assets/images/our-products/school-management/parent/parent-12.webp',
    '/assets/images/our-products/school-management/parent/parent-13.webp',
    '/assets/images/our-products/school-management/parent/parent-14.webp',
    '/assets/images/our-products/school-management/parent/parent-15.webp',
    '/assets/images/our-products/school-management/parent/parent-16.webp',
];

const teachersButtonNames = ['Dashboard', 'Student Information', 'Examination Admit Card', 'Examination Design Marksheet', 'Academics Class Timetable', 'Academics Subjects', 'Human Resource', 'Communicate', 'Certificate', 'Reports Online Examination', 'Reports Lesson Plan'];
const teachersImageAddress = [
    '/assets/images/our-products/school-management/teacher/teacher-01.webp',
    '/assets/images/our-products/school-management/teacher/teacher-02.webp',
    '/assets/images/our-products/school-management/teacher/teacher-03.webp',
    '/assets/images/our-products/school-management/teacher/teacher-04.webp',
    '/assets/images/our-products/school-management/teacher/teacher-05.webp',
    '/assets/images/our-products/school-management/teacher/teacher-06.webp',
    '/assets/images/our-products/school-management/teacher/teacher-07.webp',
    '/assets/images/our-products/school-management/teacher/teacher-08.webp',
    '/assets/images/our-products/school-management/teacher/teacher-09.webp',
    '/assets/images/our-products/school-management/teacher/teacher-10.webp',
    '/assets/images/our-products/school-management/teacher/teacher-11.webp',
];

const librarianButtonNames = ['Dashboard', 'Human Resource', 'Communicate', 'Book List', 'Add Book', 'Issue Book', 'Add Member', 'Reports'];
const librarianImageAddress = [
    '/assets/images/our-products/school-management/librarian/librarian-01.webp',
    '/assets/images/our-products/school-management/librarian/librarian-02.webp',
    '/assets/images/our-products/school-management/librarian/librarian-03.webp',
    '/assets/images/our-products/school-management/librarian/librarian-04.webp',
    '/assets/images/our-products/school-management/librarian/librarian-05.webp',
    '/assets/images/our-products/school-management/librarian/librarian-06.webp',
    '/assets/images/our-products/school-management/librarian/librarian-07.webp',
    '/assets/images/our-products/school-management/librarian/librarian-08.webp',
];

const accountButtonNames = ['Dashboard', 'Income', 'Expenses', 'Human Resource', 'Human Resource Payroll', 'Teachers Rating', 'Communicate', 'Inventory Add Item', 'Item Supplier', 'Certificate', 'Reports Finance', 'Reports Human Resource'];
const accountImageAddress = [
    '/assets/images/our-products/school-management/accountant/accountant-01.webp',
    '/assets/images/our-products/school-management/accountant/accountant-02.webp',
    '/assets/images/our-products/school-management/accountant/accountant-03.webp',
    '/assets/images/our-products/school-management/accountant/accountant-04.webp',
    '/assets/images/our-products/school-management/accountant/accountant-05.webp',
    '/assets/images/our-products/school-management/accountant/accountant-06.webp',
    '/assets/images/our-products/school-management/accountant/accountant-07.webp',
    '/assets/images/our-products/school-management/accountant/accountant-08.webp',
    '/assets/images/our-products/school-management/accountant/accountant-09.webp',
    '/assets/images/our-products/school-management/accountant/accountant-10.webp',
    '/assets/images/our-products/school-management/accountant/accountant-11.webp',
    '/assets/images/our-products/school-management/accountant/accountant-12.webp',
];

const receptionistButtonNames = ['Dashboard', 'Admission Enquiry', 'Visitor Book', 'Phone Call Log', 'Complain', 'Student Information', 'Academics Class Timetable', 'Generate Staff ID Card', 'Communicate Send Email'];
const receptionistImageAddress = [
    '/assets/images/our-products/school-management/receptionist/receptionist-01.webp',
    '/assets/images/our-products/school-management/receptionist/receptionist-02.webp',
    '/assets/images/our-products/school-management/receptionist/receptionist-03.webp',
    '/assets/images/our-products/school-management/receptionist/receptionist-04.webp',
    '/assets/images/our-products/school-management/receptionist/receptionist-05.webp',
    '/assets/images/our-products/school-management/receptionist/receptionist-06.webp',
    '/assets/images/our-products/school-management/receptionist/receptionist-07.webp',
    '/assets/images/our-products/school-management/receptionist/receptionist-08.webp',
    '/assets/images/our-products/school-management/receptionist/receptionist-09.webp',
];

const adminButtonNames = ['Dashboard', 'Examination', 'Academics', 'Human Resource', 'Certificate', 'Front CMS', 'Reports Student Information', 'Reports Lesson Plan', 'Reports Human Resource', 'Reports User Log', 'Audit Trail Report', 'System General Setting', 'Front CMS Setting', 'Users', 'Student Profile Update'];
const adminImageAddress = [
    '/assets/images/our-products/school-management/admin/admin-01.webp',
    '/assets/images/our-products/school-management/admin/admin-02.webp',
    '/assets/images/our-products/school-management/admin/admin-03.webp',
    '/assets/images/our-products/school-management/admin/admin-04.webp',
    '/assets/images/our-products/school-management/admin/admin-05.webp',
    '/assets/images/our-products/school-management/admin/admin-06.webp',
    '/assets/images/our-products/school-management/admin/admin-07.webp',
    '/assets/images/our-products/school-management/admin/admin-08.webp',
    '/assets/images/our-products/school-management/admin/admin-09.webp',
    '/assets/images/our-products/school-management/admin/admin-10.webp',
    '/assets/images/our-products/school-management/admin/admin-11.webp',
    '/assets/images/our-products/school-management/admin/admin-12.webp',
    '/assets/images/our-products/school-management/admin/admin-13.webp',
    '/assets/images/our-products/school-management/admin/admin-14.webp',
    '/assets/images/our-products/school-management/admin/admin-15.webp',
];

const superAdminButtonNames = ['Dashboard', 'Front Office', 'Student Information', 'Library', 'Inventory', 'Download Center', 'Certificate', 'Examinations', 'Front CMS', 'Reports Student Information', 'Reports Finance', 'Reports Attendance', 'Reports Online Examinations'];
const superAdminImageAddress = [
    '/assets/images/our-products/school-management/super-admin/super-admin-01.webp',
    '/assets/images/our-products/school-management/super-admin/super-admin-02.webp',
    '/assets/images/our-products/school-management/super-admin/super-admin-03.webp',
    '/assets/images/our-products/school-management/super-admin/super-admin-04.webp',
    '/assets/images/our-products/school-management/super-admin/super-admin-05.webp',
    '/assets/images/our-products/school-management/super-admin/super-admin-06.webp',
    '/assets/images/our-products/school-management/super-admin/super-admin-08.webp',
    '/assets/images/our-products/school-management/super-admin/super-admin-10.webp',
    '/assets/images/our-products/school-management/super-admin/super-admin-11.webp',
    '/assets/images/our-products/school-management/super-admin/super-admin-12.webp',
    '/assets/images/our-products/school-management/super-admin/super-admin-13.webp',
    '/assets/images/our-products/school-management/super-admin/super-admin-14.webp',
    '/assets/images/our-products/school-management/super-admin/super-admin-15.webp',
];
class SchoolManagementOurProducts extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='School Management System' pageUrl='/school-management' metaTitle="School Management System" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription="This School Management App has been designed with parents in mind, providing them with
timely and comprehensive information about their child's school activities." />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="School Management System"
                    // customColor="custom-school-management-product"
                    customColor="theme-gradient"
                    dataBlackOverlay={5}
                    bgImage="bg_image--105"
                    p="Web/Android/iOS Application"
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-school-management-product">School Management System</h3>
                                        <p className="justifypara">This School Management App has been designed with parents in mind, providing them with
                                            timely and comprehensive information about their child's school activities. It enables
                                            parents to conveniently access updates, circulars, notifications, videos, audio, and photos
                                            from the school using their mobile devices, regardless of their location or the time of day.</p>
                                        <p className="justifypara">It encompasses a diverse user base, including Students, Parents, Teachers, Accountants,
                                            Librarians, Receptionists and Administrators, thereby constituting a comprehensive school
                                            management system.</p>
                                        <h3 className="rn-page-title custom-school-management-product">Student App Functionalities</h3>
                                        <p className="justifypara">The Student App within this School Management system allows students to effortlessly
                                            manage various aspects of their academic journey, including viewing attendance, accessing
                                            notices, managing homework, checking timetables, handling fees and reviewing examination
                                            schedules and results.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#AA0F1B" />View Attendance</li>
                                            <li><FiCheck color="#AA0F1B" />View Notices</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Homework</li>
                                            <li><FiCheck color="#AA0F1B" />View Time Table</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Fees</li>
                                            <li><FiCheck color="#AA0F1B" />View Examination Schedule and Results</li>
                                            <li><FiCheck color="#AA0F1B" />Apply for Leave</li>
                                            <li><FiCheck color="#AA0F1B" />View and Manage Books Issued via the Library</li>
                                            <li><FiCheck color="#AA0F1B" />View Hostels</li>
                                            <li><FiCheck color="#AA0F1B" />View Transport Details</li>
                                            <li><FiCheck color="#AA0F1B" />Profile Management</li>
                                        </ul>
                                        <h3 className="rn-page-title">Parent App Functionalities</h3>
                                        <p className="justifypara">The Parent App within this School Management system allows parents to effortlessly
                                            manage various aspects of the academic journey, including viewing attendance, accessing
                                            notices, managing homework, checking timetables, handling fees and reviewing examination
                                            schedules and results.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#AA0F1B" />View Attendance</li>
                                            <li><FiCheck color="#AA0F1B" />View Notices</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Homework</li>
                                            <li><FiCheck color="#AA0F1B" />View Time Table</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Fees</li>
                                            <li><FiCheck color="#AA0F1B" />View Examination Schedule and Results</li>
                                            <li><FiCheck color="#AA0F1B" />Apply for Leave</li>
                                            <li><FiCheck color="#AA0F1B" />View and Manage Books Issued via the Library</li>
                                            <li><FiCheck color="#AA0F1B" />View Hostels</li>
                                            <li><FiCheck color="#AA0F1B" />View Transport Details</li>
                                            <li><FiCheck color="#AA0F1B" />Profile Management</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <div className="d-sm-block d-flex justify-content-center align-items-center ptb--30">
                                            <button className="get-a-quote btn-school text-center custom-school-management-product" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                        <h3 className="rn-page-title custom-school-management-product">Teacher Panel Functionalities</h3>
                                        <p className="justifypara">The Teacher Panel of this School Management App empowers educators with functionalities
                                            to efficiently manage classes, subjects, students, homework, attendance, timetable,
                                            examinations, results and profiles.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#AA0F1B" />Manage Class</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Subjects</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Students</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Homework</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Attendance</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Time Table</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Examinations and Results</li>
                                            <li><FiCheck color="#AA0F1B" />Profile Management</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-school-management-product">Accountant Panel Functionalities</h3>
                                        <p className="justifypara">The accountant panel of this School Management App offers a streamlined dashboard and
                                            essential functionalities, including managing fee collection, income, expenses, human
                                            resources and payrolls, ensuring efficient financial and administrative operations within the
                                            comprehensive school management system.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#AA0F1B" />Dashboard</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Fees Collection</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Income</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Expenses</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Human Resources</li>
                                            <li><FiCheck color="#AA0F1B" /> Manage Payrolls</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-school-management-product">Librarian Panel Functionalities</h3>
                                        <p className="justifypara">The Librarian Panel of this School Management App offers a streamlined dashboard and
                                            functionalities for managing human resources, books, book issuing, and book returns,
                                            contributing to the overall efficiency of the comprehensive school management system.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#AA0F1B" />Dashboard</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Human Resources</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Books</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Book Issue</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Book Return</li>
                                        </ul>
                                        <div className="d-sm-block d-flex justify-content-center align-items-center ptb--30">
                                            <button className="get-a-quote btn-school text-center" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                        <h3 className="rn-page-title custom-school-management-product">Receptionist Panel Functionalities</h3>
                                        <p className="justifypara">The receptionist panel of this School Management App offers a streamlined interface with a
                                            dashboard, allowing efficient management of admission enquiries, visitor logs and
                                            complaints.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#AA0F1B" />Dashboard</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Admission Enquiry</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Visitor Book</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Complains</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-school-management-product">Admin Panel Functionalities</h3>
                                        <p className="justifypara">The admin panel for this School Management App offers a robust set of functionalities,
                                            including user administration for students, parents, teachers, accountants, librarians, and
                                            receptionists, along with features for handling fee collection, income and expenses,
                                            examinations, human resources, academics, front website, hostels, and transport.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#AA0F1B" />Dashboard</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Users: Students, Parents, Teachers, Accountants, Librarians, Receptionists</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Fees Collection</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Income and Expenses</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Examinations</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Human Resources</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Academics</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Front Website</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Hostels</li>
                                            <li><FiCheck color="#AA0F1B" />Manage Transport</li>
                                        </ul>
                                        <div className="d-sm-block d-flex justify-content-center align-items-center pt--30">
                                            <button className="get-a-quote btn-school text-center" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-school-management-product' items={technologies} />
                            <div className="d-flex justify-content-center align-items-center ptb--30">
                                <button className="get-a-quote btn-school text-center" onClick={this.showPopup}>
                                    <span>Get a quote </span>
                                </button>
                            </div>
                        </React.Suspense>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3="Student App"
                                    p="The Student App within this School Management system allows students to effortlessly manage various aspects of their academic journey, including viewing attendance, accessing notices, managing homework, checking timetables, handling fees and reviewing examination schedules and results."
                                    buttonNames={studentAppButtonNames}
                                    image={studentAppImageAddress}
                                    alt='student app'
                                    color='school-management-app-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-school text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3="Student's Panel"
                                    p="The Student App within this School Management system allows student to effortlessly manage various aspects of the academic journey, including viewing attendance, accessing notices, managing homework, checking timetables, handling fees and reviewing examination schedules and results."
                                    buttonNames={studentButtonNames}
                                    image={studentImageAddress}
                                    alt="student"
                                    color='school-management-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-school text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3="Parent App"
                                    p="The Parent App within this School Management system allows parents to effortlessly manage various aspects of the academic journey, including viewing attendance, accessing notices, managing homework, checking timetables, handling fees and reviewing examination schedules and results."
                                    buttonNames={parentAppButtonNames}
                                    image={parentAppImageAddress}
                                    alt='parent app'
                                    color='school-management-app-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-school text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3="Parent Panel"
                                    p="The Parent App within this School Management system allows parents to effortlessly manage various aspects of the academic journey, including viewing attendance, accessing notices, managing homework, checking timetables, handling fees and reviewing examination schedules and results."
                                    buttonNames={parentButtonNames}
                                    image={parentImageAddress}
                                    alt="parent"
                                    color='school-management-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-school text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3="Teacher's Panel"
                                    p="The Teacher Panel of this School Management App empowers educators with functionalities to efficiently manage classes, subjects, students, homework, attendance, timetable, examinations, results and profiles."
                                    buttonNames={teachersButtonNames}
                                    image={teachersImageAddress}
                                    alt="teacher"
                                    color='school-management-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-school text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3="Librarian Panel"
                                    p="The Librarian Panel of this School Management App offers a streamlined dashboard and functionalities for managing human resources, books, book issuing, and book returns, contributing to the overall efficiency of the comprehensive school management system."
                                    buttonNames={librarianButtonNames}
                                    image={librarianImageAddress}
                                    alt="librarian"
                                    color='school-management-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-school text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3="Accountant Panel"
                                    p="The accountant panel of this School Management App offers a streamlined dashboard and essential functionalities, including managing fee collection, income, expenses, human resources and payrolls, ensuring efficient financial and administrative operations within the comprehensive school management system."
                                    buttonNames={accountButtonNames}
                                    image={accountImageAddress}
                                    alt="accoutant"
                                    color='school-management-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-school text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3="Receptionist Panel"
                                    p="The receptionist panel of this School Management App offers a streamlined interface with a dashboard, allowing efficient management of admission enquiries, visitor logs and complaints."
                                    buttonNames={receptionistButtonNames}
                                    image={receptionistImageAddress}
                                    alt="receptionist"
                                    color='school-management-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-school text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3="Admin Panel"
                                    p="The admin panel for this School Management App offers a robust set of functionalities, including user administration for students, parents, teachers, accountants, librarians, and receptionists, along with features for handling fee collection, income and expenses, examinations, human resources, academics, front website, hostels, and transport."
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt="admin"
                                    color='school-management-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-school text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3="Super Admin Panel"
                                    p="The admin panel for this School Management App offers a robust set of functionalities, including user administration for students, parents, teachers, accountants, librarians, and receptionists, along with features for handling fee collection, income and expenses, examinations, human resources, academics, front website, hostels, and transport."
                                    buttonNames={superAdminButtonNames}
                                    image={superAdminImageAddress}
                                    alt="super admin"
                                    color='school-management-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center ptb--30">
                        <button className="get-a-quote btn-school text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-school-management-product' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                </div>
                <CommonPortfolioButtons class='btn-school-management-product' text='View All Our Projects' padding='pb--20' />
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default SchoolManagementOurProducts;
