import React, { Component, Fragment } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';




class AboutCounter extends Component {
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ didViewCountUp: true });
        }
    }
    render() {
        // let Data = [
        //     {
        //         countNum: 12,
        //         countTitle: 'With our aspiration to keep evolving as an IT service provider, Noorisys has been standing strong for the past 12 years.',
        //         countDesc: 'Years',
        //     },
        //     {
        //         countNum: 3,
        //         countTitle: 'Noorisys Technologies was founded in India but our transcendence has evolved beyond the boundaries to a global stage in the competitive market.',
        //         countDesc: 'Locations',
        //     },
        //     {
        //         countNum: 20,
        //         countTitle: 'Our diversified experience has helped us to develop and deliver complex projects smoothly with better performance and security.',
        //         countDesc: 'Technologies & Frameworks',
        //     },
        // ];

        return (
            <Fragment>
                {/* <div className="row">
                    {Data.map((value, index) => (
                        <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                            <h5 className="counter">
                                <VisibilitySensor onChange={this.onVisibilityChange} offset={{ top: 10 }} delayedCall>
                                    <CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
                                </VisibilitySensor>
                            </h5>
                            <p className="description">{value.countDesc}</p>
                            <p className="description">{value.countTitle}</p>
                        </div>
                    ))}
                </div> */}
                <div className="row">
                    <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" >
                        <h5 className="counter">
                            <VisibilitySensor onChange={this.onVisibilityChange} offset={{ top: 10 }} delayedCall>
                                <CountUp end={this.state.didViewCountUp ? 13 : 0} />
                            </VisibilitySensor>
                        </h5>
                        <p className="description">Years</p>
                        <p className="description">With our aspiration to keep evolving as an IT service provider, Noorisys has been standing strong for the past 12 years.</p>
                    </div>

                    <div className="counterup_style_about--1 col-lg-4 col-md-4 col-sm-6 col-12" >
                        <h5 className="counter">
                            <VisibilitySensor onChange={this.onVisibilityChange} offset={{ top: 10 }} delayedCall>
                                <CountUp end={this.state.didViewCountUp ? 4 : 0} />
                            </VisibilitySensor>
                        </h5>
                        <p className="description">Locations</p>
                        <p className="description">Noorisys Technologies was founded in India but our transcendence has evolved beyond the boundaries to a global stage in the competitive market.</p>
                    </div>

                    <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" >
                        <h5 className="counter">
                            <VisibilitySensor onChange={this.onVisibilityChange} offset={{ top: 10 }} delayedCall>
                                <CountUp end={this.state.didViewCountUp ? 20 : 0} />
                            </VisibilitySensor>
                        </h5>
                        <p className="description">Technologies & Frameworks</p>
                        <p className="description">Our diversified experience has helped us to develop and deliver complex projects smoothly with better performance and security.</p>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default AboutCounter;