import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonBlogBanner from "../component/common/CommonBlogBanner";

class BlogDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='REST API SECURITY' pageUrl='/rest-api-blog' metaTitle="REST API || Noorisys Technologies" metaImage="/assets/images/blog/blog-01.webp"
                    metaDescription="Explore the latest trends and insights with our blog. Stay informed and up-to-date with our in-depth articles." />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBlogBanner date={'July 25, 2022'} writer={'ANSARI RIZWAN'} title={'REST API SECURITY'} />
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <div className="content pb--40">
                                            <h4 className="title">Overview</h4>
                                            <ul className="list-style">
                                                <li className="justifypara">Authentication is a common way to handle security for all the application.</li>
                                                <li className="justifypara">The basic keyword engaged in this process is “Authentication” and “Authorization”.</li>
                                                <li className="justifypara">Authentication can be defined as the process of verifying someone's identity by using pre-required details (Commonly username and password).</li>
                                                <li className="justifypara">To secure the information which will be rendered on the client side then it should be controlled to access the data with Authentication.</li>
                                                <li className="justifypara">Currently, a lot of websites have integrated with a security system to protect their data from hackers, and to protect data they should access the Rest APIs securely.</li>
                                            </ul>
                                        </div>
                                        <h5 className="title">Rest API Security Methods</h5>
                                        <div className="content pb--20">
                                            <h5 className="title">Following are the commonly used Rest API security methods which can be used to protect the Rest API access from hackers.</h5>
                                            <ul className="list-style">
                                                <li className="justifypara">Cookie-Based authentication.</li>
                                                <li className="justifypara">Token-Based authentication.</li>
                                                <li className="justifypara">Third-party access (OAuth, API-token).</li>
                                            </ul>
                                        </div>
                                        <br />
                                        <div className="content pb--20">
                                            <h5 className="title">Cookie-based authentication.</h5>
                                            <ul className="list-style">
                                                <li className="justifypara">has been the default method for handling user authentication for a long time.</li>
                                                <li className="justifypara">The client posts the login credential to the server, and the server verifies the credential and creates a session id which is stored in the server and returned to the client via a set cookie..</li>
                                                <li className="justifypara">On subsequent requests, the session id from the cookie is verified in the server and the request gets processed.</li>
                                                <li className="justifypara">Upon logout, the session id will be cleared from both client cookie and server.</li>
                                            </ul>
                                            <br />

                                            <h4 className="title">Token-based authentication.</h4>
                                            <ul className="list-style">
                                                <li className="justifypara">Single page application (SPA) and statelessness (RESTful APIs) of the application</li>
                                                <li className="justifypara">There are different ways to implement token-based authentication, we will focus most commonly used      JSON Web Token (JWT)</li>
                                                <li className="justifypara">On receiving the credentials from the client, the server validates the credentials and generates a signed JWT which contains the user information. Note, that the token will never get stored in the server.</li>
                                                <li className="justifypara">On subsequent requests, the token will be passed to the server and gets verified(decoded) in the server. The token can be maintained on the client side in local storage, session storage, or even in cookies.    </li>
                                            </ul>
                                        </div>

                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail">
                                                <img className="w-100" src="/assets/images/blog/blog-single-01.webp" alt="rest api blog" loading="lazy" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">Third-party access (OAuth, API-token)</h4>
                                                <ul className="list-style">
                                                    <li className="justifypara">If we have a need to expose our APIs outside of our system like third-party app or even to access it from mobile apps, we end up with two common ways to share the information.</li>
                                                    <li className="justifypara">Via API-token which is the same as JWT token, where the token will be sent via Authorization header which will get handled at API gateway to authenticate the user.</li>
                                                    <li className="justifypara">The other option is via Open Authentication (OAuth), OAuth is a protocol that allows an application to authenticate against a server as a user.</li>
                                                    <li className="justifypara">The recommendation is to implement OAuth 1.0, or OAuth 2.0. OAuth 2.0 relies on HTTPS for security and it is currently implemented by Google, Facebook, Twitter, etc. OAuth 2.0 provides secured delegate access to a resource based on the user.</li>

                                                </ul>
                                                <h4 className="title">Selection Of Rest API Security Method.</h4>
                                                <ul className="list-style">
                                                    <li className="justifypara">If you have to support a web application only, either cookies or tokens are fine - for cookies think about CSRF, for JWT take care of XSS.</li>
                                                    <li className="justifypara">If you have to support both a web application and a mobile client, go with an API that supports token-based authentication.</li>


                                                </ul>
                                            </div>
                                        </div>

                                        <h5>Best Practices to Secure REST APIs</h5>


                                        <div className="content">
                                            <h4 className="title">OFFER YOUR API OVER HTTPS</h4>
                                            <ul className="list-style">
                                                <li className="justifypara">APIs are accessed directly from within an application</li>
                                                <li className="justifypara">Makes setting up HTTPS easier, as you do need to support a redirect from HTTP</li>
                                                <li className="justifypara">Simply disable HTTP for your API endpoints altogether.</li>
                                            </ul>
                                            <h4 className="title">Network-based attacks can still attempt a fallback to HTTP.</h4>
                                            <ul className="list-style">
                                                <li className="justifypara">Configure HTTP Strict Transport Security (HSTS) to prevent this from happening.</li>
                                                <li className="justifypara"> HSTS will tell the browser to use HTTPS for every request, regardless of the scheme.</li>


                                            </ul>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default BlogDetails;