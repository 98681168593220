import React from 'react';
import { Modal } from 'react-bootstrap';

function CalendarModal(props) {
    console.log(props);
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Schedule a Meeting
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe
                    src="https://meetings-eu1.hubspot.com/afifa-ansari?embed=true"
                    title="Embedded Link"
                    width="100%"
                    height="500"
                ></iframe>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    );
}

export default CalendarModal;
