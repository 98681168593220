import React, { Component } from "react";

class BrandTwo extends Component {
    render() {
        return (
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-1.png" alt="microsoft" loading="lazy" />
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-2.png" alt="cyber" loading="lazy" />
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-3.png" alt="google partner" loading="lazy" />
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-4.png" alt="oracle partner" loading="lazy" />
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-5.png" alt="aws" loading="lazy" />
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-6.png" alt="maqam" loading="lazy" />
                    </li>
                    <li>
                        <a href="https://www.escindia.in/membership-certifica/noorisys-technologies-pvt-ltd/" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/images/brand/esc.png" alt="Esc" loading="lazy" />
                        </a>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-9.png" alt="azure" loading="lazy" />
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-10.png" alt="mysql" loading="lazy" />
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-8.png" alt="Iso" loading="lazy" />
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;