import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";

class DotNet extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='.NET || Noorisys Technologies Pvt Ltd' pageUrl='/dot-net' metaTitle=".NET" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Build powerful and scalable .NET applications with Noorisys. Our team of experts provides top-notch development services using the latest technologies." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title='.NET'
                    dataBlackOverlay={5}
                    bgImage='bg_image--25'
                    buttonTitle={'Hire Now'}
                    buttonLink={'/contact-us'}
                    p='If you care about resilient software architecture, good app performance and lead the market, you’re at the right place.'
                />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        {/* <h4 className="tilte">One Full</h4> */}
                                        <p className="justifypara">Noorisys Technologies specializes in .NET development for custom business applications with great practiced in the C#/.NET programming language. </p>
                                        <p className="justifypara">.NET platform with its ASP.NET development framework is one of the core technologies used for web application development in our company.</p>
                                        <p className="justifypara">Noorisys Technologies delivers full cycle services, from business analysis and requirement management to continuous support and maintenance of the deployed software.</p>
                                        <p className="justifypara">Our development capabilities allow our clients to safely trust Noorisys Technologies with their most complex and ambitious .NET projects.</p>
                                        <p className="justifypara">Noorisys Technologies developer has great experience in custom enterprise applications development using different Microsoft development environments (C#, ASP .NET, VB .NET, WinForms and others).</p>
                                        <p className="justifypara">We apply experienced application development professionals (including Microsoft Certified specialists) who are ready to invest their knowledge to development and enhancement of solutions that we offer to our clients.</p>
                                        <h4 className="title">Our broad range of .NET expertise includes:</h4>

                                        <ul className="list-style--1">
                                            <li><FiCheck />.NET web based solution development</li>
                                            <li><FiCheck />Migration of both desktop and web applications to .NET platform</li>
                                            <li><FiCheck />.NET custom software development</li>
                                            <li><FiCheck />Mobile applications development (based on .NET Compact Framework)</li>
                                            <li><FiCheck />.NET application development consulting</li>
                                            <li><FiCheck />Web Services based .NET Application development</li>
                                        </ul>
                                        <h4 className="title">Noorisys Technologies 's Microsoft .NET technology competencies include:</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />ASP.Net 4.5 /4.0 / 3.5 / 2.0 / 1.1</li>
                                            <li><FiCheck />ASP.NET CORE 2.0 / 3.0 / 3.1 / 5.0 / 6.0 </li>
                                            <li><FiCheck />Web Forms, Web Services and Web Server Controls</li>
                                            <li><FiCheck />Win Forms</li>
                                            <li><FiCheck />LINQ</li>
                                            <li><FiCheck />AJAX</li>
                                            <li><FiCheck />ADO.NET</li>
                                            <li><FiCheck />XML / XSLT / Xpath</li>
                                            <li><FiCheck />COM Interoperability</li>
                                            <li><FiCheck />SQL Server 2014 / 2016 / 2017 / 2019</li>
                                            <li><FiCheck />Microsoft Visual Studio IDE 2013 / 2015 / 2019 / 2022 </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>
                {/* End Page Wrapper  */}




                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default DotNet;