import React, { Component, Fragment } from "react";

class PlatformUsed extends Component {
    render() {
        return (
            <Fragment>

                <div className="platform_used container">
                    <div className="row align-items-start pl-lg-5 ml-lg-3">
                        <div className="col-lg-5">
                            <div className="row intregration_logo">
                                {this.props.items.map((value, index) => (
                                    <div key={index} className="col-12 intregration_item ">
                                        <div className="intregration_icon wow fadeInUp w-100" data-wow-delay="0.1s">
                                            <div className="media align-items-center">
                                                <img src={`/assets/images/platform-used/${value.img}`} alt={`${value.img}`} loading="lazy" />
                                                <div className="media-body">
                                                    <h5 className="mt-0">{value.h5}</h5>
                                                    <p>{value.p}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>))}
                            </div>
                        </div>
                        <div className="col-lg-7 d-flex align-items-center pt-4">
                            <div className="intregration_content col-12 col-lg-8  ">
                                <h2 className={`${this.props.color}`} data-wow-delay="0.1s">Platform Used</h2>
                                <p className=" justifypara" data-wow-delay="0.15s"> Behind the scenes of such applications, there are a number of app development frameworks, which are striving to give you the best user experience. Among which we are using the frameworks that offer the ultimate experience for
                                    creating robust applications.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default PlatformUsed