import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import 'reactjs-popup/dist/index.css';
import PageHelmet from "../common/Helmet";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import WebDevelopmentContactPage from "./Web_Development_Contact_Page";
import CommonBreadcrumb from "../common/CommonBreadcrumb";


class CareerCustomerSupport extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='career customer support ' pageUrl='/customer-support-career' metaTitle="Get in touch with Noorisys" metaImage="/assets/images/bg/bg-image-17.jpg"
                    metaDescription="Get in touch with Noorisys - the leading mobile and web development company in India. We are here to answer any of your questions and help you bring your digital vision to life. Contact us today!" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb title='Join Our Team' dataBlackOverlay={6} bgImage='bg_image--95' />
                {/* End Breadcrump Area */}

                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area pt--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        <p>Thank you for your interest in working at Noorisys Technologies Pvt. Ltd. At this time, we do not have any open positions available. However, we're always on the lookout for talented and motivated individuals to join our team. If you're interested in future opportunities, we encourage you to check back on our careers page regularly or submit your resume and cover letter to our general application.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}


                </main>

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--60 bg_color--1">
                    <WebDevelopmentContactPage vacancy={'Customer & Support'} application='customer_executive' />
                </div>
                {/* End Contact Page Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default CareerCustomerSupport;