import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PageHelmet from "../common/Helmet";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import WebDevelopmentContactPage from "./Web_Development_Contact_Page";
import CommonBreadcrumb from "../common/CommonBreadcrumb";
import { FiCheck } from "react-icons/fi";

class CareerUXUI extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='career mobile app development ' pageUrl='/ux-ui-career' metaTitle="Get in touch with Noorisys" metaImage="/assets/images/bg/bg-image-17.jpg"
                    metaDescription="Get in touch with Noorisys - the leading mobile and web development company in India. We are here to answer any of your questions and help you bring your digital vision to life. Contact us today!" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb title='Join Our Team' dataBlackOverlay={6} bgImage='bg_image--93' />
                {/* End Breadcrump Area */}

                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area pt--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        <p>We are seeking a talented and creative UI/UX Designer / Senior Graphic Designer to join our team at Noorisys Technologies Pvt Ltd. This is an excellent opportunity for experienced designers to showcase their skills and work on exciting projects that drive the visual identity of our company.
                                        </p>
                                        <h4 className="title">Responsibilities:</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck />Must have strong knowledge of Adobe Photoshop, Adobe Illustrator, Adobe After Effects, and animated video creation.</li>
                                            <li className="justifypara"><FiCheck />Develop and maintain robust web-style guides for all our consumer and business-facing products.</li>
                                            <li className="justifypara"><FiCheck />Provide thought leadership on web design best practices and next-generation digital trends.</li>
                                            <li className="justifypara"><FiCheck />Create high-fidelity mock-ups for vetting and user testing and deliver finished .psd files for development.</li>
                                            <li className="justifypara"><FiCheck /></li>
                                        </ul><br />
                                        <h4 className="title">Requirements:</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck />Experience as a graphic designer or in a related field.</li>
                                            <li className="justifypara"><FiCheck />Demonstrable graphic design skills with a strong portfolio.</li>
                                            <li className="justifypara"><FiCheck />Proficiency with required desktop publishing tools, including Photoshop, InDesign, Quark, Illustrator, Figma, and XD.</li>
                                            <li className="justifypara"><FiCheck />Strong skills in CorelDRAW.</li>
                                            <li className="justifypara"><FiCheck />A strong eye for visual composition.</li>
                                            <li className="justifypara"><FiCheck />Effective time management skills and the ability to meet deadlines.
                                            </li>
                                            <li className="justifypara"><FiCheck />Able to give and receive constructive criticism.
                                            </li>
                                            <li className="justifypara"><FiCheck />Understanding of marketing, production, website design, corporate identity, product packaging, advertisements, and multimedia design.
                                            </li>
                                            <li className="justifypara"><FiCheck />Experience with computer-aided design.
                                            </li>
                                        </ul><br />
                                        <h4 className="title">Candidate’s Soft Skills:</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck />Excellent written and verbal communication skills are essential.</li>
                                            <li className="justifypara"><FiCheck />Must be dynamic, flexible, and possess perseverance skills.</li>
                                            <li className="justifypara"><FiCheck />Should have an understanding of business objectives and organizational structure.</li>
                                            <li className="justifypara"><FiCheck />Must have a strong work ethic and trustworthiness.</li>
                                            <li className="justifypara"><FiCheck />Attention to detail and the ability to maintain confidentiality and integrity.</li>
                                            <li className="justifypara"><FiCheck />Must be highly collaborative and be a team player with a commitment to excellence.
                                            </li>
                                            <li className="justifypara"><FiCheck />Self-motivated, fast learner with the ability to operate independently.
                                            </li>
                                            <li className="justifypara"><FiCheck />Ability to work under pressure to achieve multiple daily deadlines for client deliverables with a mature approach.
                                            </li>
                                            <li className="justifypara"><FiCheck />Proficiency in MS Office.
                                            </li>
                                            <li className="justifypara"><FiCheck />Excellent multitasking skills.
                                            </li>
                                            <li className="justifypara"><FiCheck />Ability to prioritize tasks.
                                            </li>
                                            <li className="justifypara"><FiCheck />Ability to present and explain ideas to a variety of audiences.
                                            </li>
                                            <li className="justifypara"><FiCheck />Strong organizational and time management skills.
                                            </li>
                                            <li className="justifypara"><FiCheck />Ability to maintain a high level of professionalism and confidentiality.
                                            </li>
                                        </ul><br />
                                        <p>If you're a passionate and motivated designer ready to contribute to our company's visual identity and work on exciting projects, submit your CV now and join our dynamic team at Noorisys Technologies Pvt Ltd.</p>
                                        <br />
                                        <div className="d-flex"><h4 className="title">Location: </h4>&nbsp;&nbsp;<small style={{fontSize:'18px'}}> Navi Mumbai</small> </div>
                                        <div className="d-flex"><h4 className="title">Qualification: </h4>&nbsp;&nbsp;<small style={{fontSize:'18px'}}> Graduate (Male Candidate)</small> </div>
                                        <div className="d-flex"><h4 className="title">Experience: </h4>&nbsp;&nbsp;<small style={{fontSize:'18px'}}> 2+ years</small> </div>
                                        <div className="d-flex"><h4 className="title">Apply at: </h4>&nbsp;&nbsp;<small style={{fontSize:'18px'}}> <a href="mailto:hr@noorisys.com">
                                        hr@noorisys.com
                                        </a></small> </div>
                                        {/* <h4 className="title"><b>Location :</b>Navi Mumbai</h4>
                                        <h4 className="title"><b>Qualification :</b>Graduate (Male Candidate)</h4>
                                        <h4 className="title"><b>Experience :</b>2+ years</h4>
                                        <h4 className="title"><b>Apply at :</b> <a href="mailto:hr@noorisys.com">
                                        hr@noorisys.com
                                        </a> </h4> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}


                </main>

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--60 bg_color--1">
                    <WebDevelopmentContactPage vacancy={'UX UI development'} application='ux_ui' />
                </div>
                {/* End Contact Page Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default CareerUXUI;