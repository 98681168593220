import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import { FaApple, FaGlobe } from "react-icons/fa";
import PlatformUsed from "../component/Verbos/PlatformUsed";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
const technologies = [

    { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
    { h5: 'Angular', p: 'Provider Panel Development', img: 'angular.png' },
    { h5: 'PHP', p: 'Backend Development', img: 'php.png' },
    { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/doctor-appointment-and-online-pharmacy', img: 'mediconnect.webp', h2: 'MediConnect', p: 'Doctor Appointment and online pharmacy' },
]
const SocialShare = [
    { Social: <FaGlobe />, link: ' http://fasterchecks.co.uk/ ' },
    { Social: <FaApple />, link: 'https://apps.apple.com/in/app/fasterchecks/id1560469605 ' }
]
const adminButtonNames = ['Homepage', 'Login', 'Dashboard', 'Manage Video QR Code', 'Manage Video Test', 'Manage Photo Test', 'Manage Users', 'Manage Checkers', 'Manage Supprot', 'Manage Partner'];
const adminImageAddress = [
    '/assets/images/portfolio/fastercheck/admin-1.webp',
    '/assets/images/portfolio/fastercheck/admin-2.webp',
    '/assets/images/portfolio/fastercheck/admin-3.webp',
    '/assets/images/portfolio/fastercheck/admin-4.webp',
    '/assets/images/portfolio/fastercheck/admin-5.webp',
    '/assets/images/portfolio/fastercheck/admin-6.webp',
    '/assets/images/portfolio/fastercheck/admin-7.webp',
    '/assets/images/portfolio/fastercheck/admin-8.webp',
    '/assets/images/portfolio/fastercheck/admin-9.webp',
    '/assets/images/portfolio/fastercheck/admin-10.webp',
];

class CovidAntigenPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Antigen Test Verification Platform' pageUrl='/covid-antigen-test-verification' metaTitle="Antigen Test Verification Platform" metaImage="/assets/images/portfolio/dp-portfolio-11.jpg"
                    metaDescription="This is a test verification platform that allows you to take Covid Antigen Test and get the certificate within 15 minutes." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo1.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Covid Antigen Test Verification"
                    customColor="custom-fasterchecks-color"
                    dataBlackOverlay={7}
                    bgImage="bg_image--50"
                    p="Web / Android / iOS Application"
                    socialShare={SocialShare}
                    pb="pb--120"
                />

                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details pt--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-fasterchecks-color">Covid Antigen Test Verification Platform</h3>
                                        <p className="justifypara">FasterChecks is a test verification platform that allows you to take Covid Antigen Test and get the certificate within 15 minutes. There is no need to get an appointment, go to the test center anymore, you can perform the antigen test whenever and wherever you want. After receiving the test kit from your test provider, register yourself on the web platform provided on your INSTRUCTION card. After signing up, you can continue on the web browser or if you are an Apple user, you can download the app from the App store. It is the quick method that allows you to perform the test, upload it using our platform and get the test certificate in less than half an hour, that’s it!</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color={'#ff5500'} />One-stop-shop for Covid Testing</li>
                                            <li><FiCheck color={'#ff5500'} />Cross-platform application</li>
                                            <li><FiCheck color={'#ff5500'} />Suitable for all age groups</li>
                                            <li><FiCheck color={'#ff5500'} />Quick request processing</li>
                                            <li><FiCheck color={'#ff5500'} />Fully Responsive Design</li>
                                            <li><FiCheck color={'#ff5500'} />Attractive dashboard</li>
                                            <li><FiCheck color={'#ff5500'} />Easy registration</li>
                                            <li><FiCheck color={'#ff5500'} />QR code scanning</li>
                                            <li><FiCheck color={'#ff5500'} />Data Security</li>
                                            <li><FiCheck color={'#ff5500'} />User-friendly</li>
                                            <li><FiCheck color={'#ff5500'} />And many more….</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-fasterchecks'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ">
                    <div className="pt--30">
                        <div className="container">
                            <PlatformUsed color='custom-fasterchecks-color' items={technologies} />
                            <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-fasterchecks'} paddingTop={'pt--30'} />
                        </div>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--1 pb--20">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                {/* <FasterchecksAppDemo /> */}
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='Admin can manage various aspects of the admin panel, including video QR codes, photo QR codes, video tests, photo tests, event tests, manual tests, declaration tests, users, checkers, support requests, partners, rejection reasons and countries.'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='faster-checks admin'
                                    color='faster-checks'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-fasterchecks'} paddingTop={'pt--30'} paddingBottom={`pb--30`} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-fasterchecks-color' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-fasterchecks' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default CovidAntigenPortfolioDetails;
