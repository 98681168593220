import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LazyLoad from 'react-lazyload';
import 'react-image-lightbox/style.css';
import { Link } from 'react-router-dom';

const TabOne = [
    // {
    //     image: '01',
    //     bigImage: '/assets/images/portfolio/big/dp-big--portfolio-07.jpg',
    //     category: 'Web Design',
    //     title: 'Digital Banking System',
    //     url: '/digital-banking-system',
    //     img_name: 'digital banking image'
    // },
    {
        image: '07',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-07.jpg',
        category: 'Logo Design',
        title: 'Primary Care Management System',
        url: '/primary-care-management-system',
        img_name: 'primary care image'
    },

    {
        image: '02',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-02.jpg',
        category: 'Mobile App',
        title: 'Scholarship Distribution with KYC Verification',
        url: '/scholarship-distribution-system',
        img_name: 'scholarship distribution image'
    },
    {
        image: '03',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-03.jpg',
        category: 'Web Design',
        title: 'Online Grocery Ordering System',
        url: '/online-groceries-ordering-system',
        img_name: 'online groceries image'
    },
    {
        image: '04',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-04.jpg',
        category: 'Mobile App',
        title: 'Courier And Transport Management',
        url: '/courier-and-transport-management',
        img_name: 'courier & transport  image'
    },
    {
        image: '09',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Pregnancy Management App',
        url: '/multilingual-pregnancy-app',
        img_name: 'Pregnancy Management App image'
    },
    {
        image: '51',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Doctor Appointment and Online Pharmacy',
        url: '/doctor-appointment-and-online-pharmacy',
        img_name: 'Doctor Appointment and Online Pharmacy '
    },
    {
        image: '06',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-06.jpg',
        category: 'Logo Design',
        title: 'Healthcare Insurance Marketplace',
        url: '/healthcare-insurance-marketplace',
        img_name: 'healthcare insurance image'
    },
    {
        image: '05',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-05.jpg',
        category: 'Web Design',
        title: 'Hospital Chain Management System (Secondary Care)',
        url: '/hospital-chain-management-system',
        img_name: 'hospital chain image'
    },
    {
        image: '08',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-08.jpg',
        category: 'Logo Design',
        title: 'Ticket Booking Platform',
        url: '/ticket-booking-platform',
        img_name: 'ticket booking image'
    },
    {
        image: '53',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-53.jpg',
        category: 'Web Design',
        title: 'Visa Processing OTA',
        url: '/visa-processing-system',
        img_name: 'Visa Processing image'
    },
    {
        image: '52',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Digital Solution for Civil Documents',
        url: '/digital-solution-for-civil-documents',
        img_name: 'digital solution image'
    },
    // {
    //     image: '01',
    //     bigImage: '/assets/images/portfolio/big/dp-big--portfolio-01.jpg',
    //     category: 'Web Design',
    //     title: 'Tourism Company Management System',
    //     url: '/tourism-company-management-system',
    //     img_name: 'tourism compony image'
    // },
    {
        image: '10',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Hotel Booking Platform',
        url: '/hotel-booking-platform',
        img_name: 'hotel booking platform image'
    },
    {
        image: '11',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Faster Checks',
        url: '/faster-checks',
        img_name: 'fasterchecks image'
    },
    {
        image: '12',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Nursing Platform',
        url: '/nursing-platform',
        img_name: 'nursing platform image'
    },
    {
        image: '54',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Car Space Rental',
        url: '/car-space-rental-platform',
        img_name: 'car space image'
    },
    {
        image: '13',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Service Provider Marketplace',
        url: '/service-provider-marketplace',
        img_name: 'service provider image'
    },
    {
        image: '55',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Influencer Marketplace',
        url: '/influencer-marketplace',
        img_name: 'Influencer Marketplace image'
    },
    {
        image: '14',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Online Book',
        url: '/online-book',
        img_name: 'online book image'
    },


    {
        image: '21',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Pharmacist Marketplace',
        url: '/pharmacist-marketplace',
        img_name: 'pharmacist marketplace image'
    },


    {
        image: '16',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Online Shop',
        url: '/online-shop',
        img_name: 'online shop image'
    },

    {
        image: '17',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Researcher Personal Presentation',
        url: '/researcher-personal-presentation',
        img_name: 'researcher personal presentation image'
    },
    {
        image: '11',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Covid Antigen Test Verification',
        url: '/covid-antigen-test-verification',
        img_name: 'covid antigen image'
    },
    {
        image: '18',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Delivery Management',
        url: '/delivery-management',
        img_name: 'delivery managment image'
    },
    {
        image: '19',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Government Financial Scheme Presentation System',
        url: '/government-financial-scheme-presentation-system',
        img_name: 'government financial image'
    },

    {
        image: '20',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Electronic Medical Record',
        url: '/electronic-medical-record',
        img_name: 'electronic medical record image'
    },
    {
        image: '15',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'e-Learning',
        url: '/e-learning',
        img_name: 'e-learning image'
    },
    {
        image: '22',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Diet App',
        url: '/diet-app',
        img_name: 'diet app image'
    },
    {
        image: '23',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Coupons And Cashback Offer Application',
        url: '/coupons-and-cashback-offer-application',
        img_name: 'coupons & cashback image'
    },
    {
        image: '24',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Doctor Appointment And Hospital Management',
        url: '/doctor-appointment-and-hospital-management',
        img_name: 'doctor appointment image'
    },
    {
        image: '25',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'ERP for Oil Company Outlets',
        url: '/erp-oil-company-outlets',
        img_name: 'erp image'
    },
    {
        image: '26',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Event Management System',
        url: '/event-management-system',
        img_name: 'event managment image'
    },
    {
        image: '27',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Lead Management System',
        url: '/lead-management-system',
        img_name: 'lead managment image'
    },
    {
        image: '28',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Meditation App',
        url: '/meditation-app',
        img_name: 'meditation app image'
    },
    {
        image: '29',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Online Food Delivery',
        url: '/online-food-delivery',
        img_name: 'online food delivery image'
    },
    {
        image: '30',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Online Payment Application',
        url: '/online-payment-application',
        img_name: 'online payment application image'
    },
    {
        image: '31',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'School Management System',
        url: '/school-management-system',
        img_name: 'school managment image'
    },
    {
        image: '32',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Asset Management For Repairing Service Provider',
        url: '/asset-management-repairing-service',
        img_name: 'asset managment image'
    },
    {
        image: '33',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Property Showcase',
        url: '/property-showcase',
        img_name: 'property showcase image'
    },
    {
        image: '57',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Fitness Application',
        url: '/fitness',
        img_name: 'fitness application'
    },
    {
        image: '58',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'BTK',
        url: '/btk',
        img_name: 'btk'
    },
    {
        image: '59',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Queue Management',
        url: '/queue-management',
        img_name: 'queue managemen'
    },
    {
        image: '60',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Online Travel Agency',
        url: '/online-travel-agency',
        img_name: 'online travel agency'
    },
    {
        image: '61',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Apparel  eCommerce',
        url: '/Apparel-ecommerce',
        img_name: 'Apparel  eCommerce'
    },
    {
        image: '62',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Match Making Application',
        url: '/match-making',
        img_name: 'Match Making Application'
    },
    {
        image: '63',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Study Matching Platform',
        url: '/study-matching',
        img_name: 'Study Matching Platform'
    },
    {
        image: '64',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Home Cleaning Services',
        url: '/home-cleaning-services',
        img_name: 'home cleaning services'
    },
    {
        image: '65',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Service Management Platform',
        url: '/service-management-resolution-platform',
        img_name: 'service management platform'
    },
    {
        image: '66',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Social Media Platform',
        url: '/social-media-platform',
        img_name: 'social media platform'
    },
    {
        image: '67',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Market Place',
        url: '/market-place',
        img_name: 'market place'
    },
    {
        image: '68',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Real Estate Portal',
        url: '/real-estate-portal',
        img_name: 'real state portal'
    },
    {
        image: '69',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Social Media Marketing Tool',
        url: '/social-media-marketing-tool',
        img_name: 'social media marketing tool'
    },

    {
        image: '34',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Hajj Application',
        url: '/hajj-application',
        img_name: 'hajj application image'
    },
    {
        image: '35',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Hide Numbers',
        url: '/hide-numbers',
        img_name: 'hide numbers image'
    },
    {
        image: '36',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Memories',
        url: '/memories',
        img_name: 'memories image'
    },
    {
        image: '38',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Powerloom Invoice Management',
        url: '/powerloom-invoice-management',
        img_name: 'powerloom invoice image'
    },
    {
        image: '39',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Service Center Booking System',
        url: '/service-center-booking-system',
        img_name: 'service center booking image'
    },
    {
        image: '40',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Social App',
        url: '/social-app',
        img_name: 'social app image'
    },
    {
        image: '41',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Vehicle Managment System',
        url: '/vehicle-managment-system',
        img_name: 'vehicle managment image'
    },
    {
        image: '42',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Survey Application',
        url: '/survey-application',
        img_name: 'Survey Application image'
    },
    {
        image: '43',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Choice Making App',
        url: '/choice-making-app',
        img_name: 'choice making app image'
    },
    {
        image: '45',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Discovery Music',
        url: '/discovery-music',
        img_name: 'discovery music image'
    },
    {
        image: '46',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Doctor Personal Presentation',
        url: '/doctor-personal-presentation',
        img_name: 'doctor personal presentation image'
    },
    // {
    //     image: '47',
    //     bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
    //     category: 'Logo Design',
    //     title: 'Habit Forming App',
    //     url: '/habit-forming-app',
    //     img_name: 'Habit Forming App image'
    // },
    {
        image: '48',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Online Laundry',
        url: '/online-laundry',
        img_name: 'online laundry image'
    },
    {
        image: '49',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Online Grocery',
        url: '/online-grocery',
        img_name: 'Online Grocery image'
    },
    {
        image: '50',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Freelancing Portal',
        url: '/freelancing-portal',
        img_name: 'freelancing portal image'
    },
    {
        image: '56',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Job Marketplace',
        url: '/job-marketplace',
        img_name: 'Job Marketplace'
    },

    // {
    //     image: '70',
    //     bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
    //     category: 'Logo Design',
    //     title: 'Doctor Appointment System With AI',
    //     url: '/doctor-appointment-system-with-AI',
    //     img_name: 'doctor appointment system with AI'
    // },
    {
        image: '71',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Data Scraper',
        url: '/data-scraper',
        img_name: 'data scraper'
    },
    {
        image: '72',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Senior Citizen Monitoring App',
        url: '/senior-citizen-monitoring-app',
        img_name: 'senior citizen monitoring app'
    },
    {
        image: '73',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Water Donation App',
        url: '/water-dontation-app',
        img_name: 'Water Donation App'
    },
    {
        image: '74',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Medical Emergency Call Management',
        url: '/medical-emergency-call-management',
        img_name: 'medical emergency management'
    },
    {
        image: '75',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Event Management Platform',
        url: '/event-management-platform',
        img_name: 'event-management-platform'
    },
    {
        image: '76',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Taxi App',
        url: '/taxi-app-portfolio',
        img_name: 'taxi app portfolio'
    },
]


const TabTwo = [

    {
        image: '13',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Service Provider Marketplace',
        url: '/service-provider-marketplace',
        img_name: 'service provider image'
    },
    {
        image: '54',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Car Space Rental',
        url: '/car-space-rental-platform',
        img_name: 'car space image'
    },
    {
        image: '55',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Influencer Marketplace',
        url: '/influencer-marketplace',
        img_name: 'Influencer Marketplace image'
    },
    {
        image: '14',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Online Book',
        url: '/online-book',
        img_name: 'online book image'
    },
    {
        image: '61',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Apparel  eCommerce',
        url: '/Apparel-ecommerce',
        img_name: 'Apparel  eCommerce'
    },
    {
        image: '64',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Home Cleaning Services',
        url: '/home-cleaning-services',
        img_name: 'home cleaning services'
    },
    {
        image: '53',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-53.jpg',
        category: 'Web Design',
        title: 'Visa Processing OTA',
        url: '/visa-processing-system',
        img_name: 'Visa Processing image'
    },
    {
        image: '60',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Online Travel Agency',
        url: '/online-travel-agency',
        img_name: 'online travel agency'
    },
    {
        image: '68',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Real Estate Portal',
        url: '/real-estate-portal',
        img_name: 'real state portal'
    },
    {
        image: '17',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Researcher Personal Presentation',
        url: '/researcher-personal-presentation',
        img_name: 'researcher personal presentation image'
    },
    {
        image: '63',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Study Matching Platform',
        url: '/study-matching',
        img_name: 'Study Matching Platform'
    },
    {
        image: '69',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Social Media Marketing Tool',
        url: '/social-media-marketing-tool',
        img_name: 'social media marketing tool'
    },
    {
        image: '15',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'e-Learning',
        url: '/e-learning',
        img_name: 'e-learning image'
    },
    {
        image: '24',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Doctor Appointment And Hospital Management',
        url: '/doctor-appointment-and-hospital-management',
        img_name: 'doctor appointment image'
    },
    {
        image: '27',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Lead Management System',
        url: '/lead-management-system',
        img_name: 'lead managment image'
    },
    {
        image: '56',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Job Marketplace',
        url: '/job-marketplace',
        img_name: 'Job Marketplace'
    },
    {
        image: '33',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Property Showcase',
        url: '/property-showcase',
        img_name: 'property showcase image'
    },
    {
        image: '58',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'BTK',
        url: '/btk',
        img_name: 'btk'
    },
    {
        image: '38',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Powerloom Invoice Management',
        url: '/powerloom-invoice-management',
        img_name: 'powerloom invoice image'
    },
    {
        image: '10',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Hotel Booking Platform',
        url: '/hotel-booking-platform',
        img_name: 'hotel booking platform image'
    },
    // {
    //     image: '44',
    //     bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
    //     category: 'Logo Design',
    //     title: 'Clinic Presentation',
    //     url: '/clinic-presentation',
    //     img_name: 'clinic presentation image'
    // },
    {
        image: '46',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Doctor Personal Presentation',
        url: '/doctor-personal-presentation',
        img_name: 'doctor personal presentation image'
    },
    {
        image: '65',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Service Management Platform',
        url: '/service-management-resolution-platform',
        img_name: 'service management platform'
    },
    {
        image: '66',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Social Media Platform',
        url: '/social-media-platform',
        img_name: 'social media platform'
    },
    {
        image: '52',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Digital Solution for Civil Documents',
        url: '/digital-solution-for-civil-documents',
        img_name: 'digital solution image'
    },
]



const TabThree = [
    // {
    //     image: '09',
    //     bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
    //     category: 'Logo Design',
    //     title: 'Pregnancy Management App',
    //     url:'/multilingual-pregnancy-app',
    //     img_name:'Pregnancy Management App image'
    // },
    // {
    //     image: '19',
    //     bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
    //     category: 'Logo Design',
    //     title: 'Government Financial Scheme Presentation System',
    //     url: '/government-financial-scheme-presentation-system',
    //     img_name: 'government financial image'
    // },
    {
        image: '22',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Diet App',
        url: '/diet-app',
        img_name: 'diet app image'
    },
    {
        image: '26',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Event Management System',
        url: '/event-management-system',
        img_name: 'event managment image'
    },
    {
        image: '28',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Meditation App',
        url: '/meditation-app',
        img_name: 'meditation app image'
    },
    {
        image: '67',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Ndashaka',
        url: '/ndashaka',
        img_name: 'ndashaka'
    },
    {
        image: '29',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Online Food Delivery',
        url: '/online-food-delivery',
        img_name: 'online food delivery image'
    },
    {
        image: '31',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'School Management System',
        url: '/school-management-system',
        img_name: 'school managment image'
    },
    {
        image: '32',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Asset Management For Repairing Service Provider',
        url: '/asset-management-repairing-service',
        img_name: 'asset managment image'
    },
    {
        image: '34',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Hajj Application',
        url: '/hajj-application',
        img_name: 'hajj application image'
    },
    {
        image: '35',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Hide Numbers',
        url: '/hide-numbers',
        img_name: 'hide numbers image'
    },
    {
        image: '36',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Memories',
        url: '/memories',
        img_name: 'memories image'
    },
    {
        image: '39',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Service Center Booking System',
        url: '/service-center-booking-system',
        img_name: 'service center booking image'
    },
    {
        image: '40',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Social App',
        url: '/social-app',
        img_name: 'social app image'
    },
    {
        image: '42',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Survey Application',
        url: '/survey-application',
        img_name: 'Survey Application image'
    },
    {
        image: '45',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Discovery Music',
        url: '/discovery-music',
        img_name: 'discovery music image'
    },
    {
        image: '72',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Senior Citizen Monitoring App',
        url: '/senior-citizen-monitoring-app',
        img_name: 'senior citizen monitoring app'
    },
    // {
    //     image: '47',
    //     bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
    //     category: 'Logo Design',
    //     title: 'Habit Forming App',
    //     url: '/habit-forming-app',
    //     img_name: 'Habit Forming App image'
    // },

]
const TabFour = [

    {
        image: '02',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-02.jpg',
        category: 'Mobile App',
        title: 'Scholarship Distribution with KYC Verification',
        url: '/scholarship-distribution-system',
        img_name: 'scholarship distribution image'
    },
    {
        image: '51',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Doctor Appointment and Online Pharmacy',
        url: '/doctor-appointment-and-online-pharmacy',
        img_name: 'Doctor Appointment and Online Pharmacy '
    },
    {
        image: '52',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Digital Solution for Civil Documents',
        url: '/digital-solution-for-civil-documents',
        img_name: 'digital solution image'
    },
    {
        image: '09',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Pregnancy Management App',
        url: '/multilingual-pregnancy-app',
        img_name: 'Pregnancy Management App image'
    },
    {
        image: '53',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-53.jpg',
        category: 'Web Design',
        title: 'Visa Processing OTA',
        url: '/visa-processing-system',
        img_name: 'Visa Processing image'
    },
    {
        image: '19',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Government Financial Scheme Presentation System',
        url: '/government-financial-scheme-presentation-system',
        img_name: 'government financial image'
    },

]

class TabStyleThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            tab2: 0,
            tab3: 0,
            tab4: 0,
            isOpen: false,
        };
    }
    render() {
        const { column } = this.props;
        // const { tab1, tab2, tab3, tab4, isOpen } = this.state;
        return (
            <div>
                <Tabs>
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <div className="tablist-inner">
                                <TabList className="pv-tab-button text-center mt--0">
                                    <Tab><span>All Projects</span></Tab>
                                    <Tab><span>Web Designs</span></Tab>
                                    {/* <Tab><span>Logo Design</span></Tab> */}
                                    <Tab><span>Mobile Apps</span></Tab>
                                    <Tab><span>Government Projects</span></Tab>
                                </TabList>
                            </div>
                        </div>
                    </div>

                    <TabPanel className="row row--35">
                        {TabOne.map((value, index) => (
                            <div className={`${column}`} key={index}>
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <Link to={value.url}>
                                                        <LazyLoad placeholder={<img src={`portfolio${value.image}`} alt='img' loading='lazy' />}>
                                                            <img src={`/assets/images/portfolio/dp-portfolio-${value.image}.webp`} alt={value.img_name} />
                                                        </LazyLoad>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    {/* <p>{value.category}</p> */}
                                                    <h4><Link to={value.url}>{value.title}</Link></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabTwo.map((value, index) => (
                            <div className={`${column}`} key={index}>
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab2: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <Link to={value.url}>
                                                        <LazyLoad placeholder={<img src={`portfolio${value.image}`} alt='img' loading='lazy' />}>
                                                            <img src={`/assets/images/portfolio/dp-portfolio-${value.image}.webp`} alt={value.img_name} />
                                                        </LazyLoad>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <h4><Link to={value.url}>{value.title}</Link></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>


                    <TabPanel className="row row--35">
                        {TabThree.map((value, index) => (
                            <div className={`${column}`} key={index}>
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <Link to={value.url}>
                                                        <LazyLoad placeholder={<img src={`portfolio${value.image}`} alt='img' loading='lazy' />}>
                                                            <img src={`/assets/images/portfolio/dp-portfolio-${value.image}.webp`} alt={value.img_name} />
                                                        </LazyLoad>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <h4><Link to={value.url}>{value.title}</Link></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabFour.map((value, index) => (
                            <div className={`${column}`} key={index}>
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <Link to={value.url}>
                                                        <LazyLoad placeholder={<img src={`portfolio${value.image}`} alt={value.image} loading='lazy' />}>
                                                            <img src={`/assets/images/portfolio/dp-portfolio-${value.image}.webp`} alt={value.img_name} />
                                                        </LazyLoad>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <h4><Link to={value.url}>{value.title}</Link></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                </Tabs>
            </div>
        )
    }
}


export default TabStyleThree
