import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
class CI4 extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='CI4 || Noorisys Technologies Pvt Ltd' pageUrl='/ci4' metaTitle="CodeIgniter" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Develop next-generation dynamic applications with CodeIgniter, a proven, agile, and open PHP framework. Noorisys' team of experts provides top-notch development services." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title='CI4'
                    dataBlackOverlay={5}
                    bgImage='bg_image--25'
                    buttonTitle={'Hire Now'}
                    buttonLink={'/contact-us'}
                    p='CodeIgniter, a proven, agile and open PHP framework, enables programmers to develop next gen dynamic applications.'
                />
                {/* End Breadcrump Area */}


                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        <p className="justifypara">This web application platform empowers developers to build projects much faster, by providing a rich set of libraries for commonly used tasks than having to write code from scratch.</p>
                                        <p className="justifypara">The MVC (Model-Viewer- Controller) architecture in this object-oriented framework allows building large apps in a manageable way.</p>
                                        <p className="justifypara">Easy to understand and loaded with hundreds of libraries, CodeIgniter handles connection to multiple databases within a single application quite seamlessly.</p>
                                        <p className="justifypara">At Noorisys Technologies - our CodeIngniter developers have been consistently exceeding the expectations of customers in every project.</p>
                                        <p className="justifypara">We have developed and grown many crimps in terms of expertise, experience and management during the execution of each of these projects. We can deliver projects of any size with organized structure, well-documented phases and optimum speed.</p>
                                        <h4 className="title">WHY CODEIGNITER?</h4>

                                        <ul className="list-style--1">
                                            <li><FiCheck />No INSTALLATION required.</li>
                                            <li><FiCheck />Easy to understand.</li>
                                            <li><FiCheck />Built in security tools</li>
                                            <li><FiCheck />MVC architecture</li>
                                            <li><FiCheck />Database abstraction</li>
                                            <li><FiCheck />Free coupling</li>
                                            <li><FiCheck />Code reusability</li>
                                            <li><FiCheck />Compatible with majority of web servers & platforms</li>
                                        </ul>
                                        <h4 className="title">Our Propositions</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Application development</li>
                                            <li><FiCheck />Portal development</li>
                                            <li><FiCheck />Website development</li>
                                            <li><FiCheck />Application maintenance and support</li>
                                            <li><FiCheck />Dedicated CodeIgniter developer</li>
                                            <li><FiCheck />Rich Internet Applications(RIA)</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>
                {/* End Page Wrapper  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default CI4;