import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
class Laravel extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Laravel || Noorisys Technologies Pvt Ltd' pageUrl='/laravel' metaTitle="Laravel" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Leverage the power of Laravel, one of the most popular backend web application frameworks, with expressive and elegant syntax. Noorisys' team of experts provides top-notch development services." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title='Laravel'
                    dataBlackOverlay={5}
                    bgImage='bg_image--25'
                    buttonTitle={'Hire Now'}
                    buttonLink={'/contact-us'}
                    p='Laravel is one of the most popular backend web application frameworks with expressive, elegant syntax.'
                />
                {/* End Breadcrump Area */}


                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        {/* <h4 className="tilte">One Full</h4> */}
                                        <p className="justifypara"> A web application platform built around various functions like security such as authentication and authorization, customer communication, accessibility and affordability to use irrespective of the size of your application.</p>
                                        <p className="justifypara">Laravel is one of the most popular backend web application frameworks with expressive, elegant syntax. We believe development must be an enjoyable, creative experience to be truly fulfilling. Laravel attempts to take the pain out of development by easing common tasks used in the majority of web projects, such as authentication, routing, sessions, and caching.</p>
                                        <p className="justifypara">Laravel aims to make the development process a pleasing one for the developer without sacrificing application functionality. Happy developers make the best code. To this end, we've attempted to combine the very best of what we have seen in other web frameworks, including frameworks implemented in other languages, such as Ruby on Rails, ASP.NET MVC, and Sinatra.</p>
                                        <p className="justifypara">Laravel is accessible, yet powerful, providing powerful tools needed for large, robust applications. A superb inversion of control container, expressive migration system, and tightly integrated unit testing support give you the tools you need to build any application with which you are tasked.</p>
                                        <h4 className="title">OUR SERVICES</h4>
                                        <p className="justifypara">Noorisys Technologies provides a wide array of solutions including Laravel development, Laravel customization, Laravel integration with third party integration, Shopping carts with Laravel CMS, Laravel upgrade, maintenance and support service.</p>
                                        <h4 className="title">Our Laravel solutions provide the following:</h4>

                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck />Laravel custom Landing Page Design.</li>
                                            <li className="justifypara"><FiCheck />Laravel plugins creation and installation.</li>
                                            <li className="justifypara"><FiCheck />Shipping and Payment modules development.</li>
                                            <li className="justifypara"><FiCheck />Laravel upgrade service.</li>
                                            <li className="justifypara"><FiCheck />Our Laravel consultants can assist you narrow your ecommerce choices.</li>
                                            <li className="justifypara"><FiCheck />Laravel integration with another party application.</li>
                                            <li className="justifypara"><FiCheck />Conversion from any present platform to Laravel e-commerce.</li>
                                            <li className="justifypara"><FiCheck />Creating shopping carts using Laravel.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>
                {/* End Page Wrapper  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Laravel;