import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
const ProductCommonApp = React.lazy(() => import("../portfolio/ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("../portfolio/CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'PHP', p: 'Admin Panel', img: 'php.png' },
    { h5: 'Flutter', p: 'App Development', img: 'flutter.png' },
    { h5: 'Mysql', p: 'Database', img: 'mysql.png' },
    { h5: 'Laravel', p: 'Backend Development', img: 'native.png' },
]

const userAppButtonNames = [
    "Login", "Medicines",
    "Doctors", "Appointments", "Account", "Edit Profile",
    "Wallet", "Recent Orders", "Order Details", "Appointment Details",
    "Chat With Doctor", "Search Doctor", "My Cart", "View Prescription"
];

const userAppImageAddresses = [
    "/assets/images/our-products/doctor-appointment/patient/patient-01.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-02.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-03.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-04.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-05.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-06.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-07.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-08.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-09.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-10.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-11.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-12.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-13.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-14.webp"
];
const doctorButtonNames = [
    "Login",
    "Appointments",
    "Appointment Details",
    "Chat With Patient",
    "Reviews",
    "Account",
    "Clinic Information",
    "My Profile",
    "Wallet",
    "Change Language",
    "Phone Verification",
    "Notifications"
];

const doctorImageAddresses = [
    "/assets/images/our-products/doctor-appointment/doctor/doctor-01.webp",
    "/assets/images/our-products/doctor-appointment/doctor/doctor-02.webp",
    "/assets/images/our-products/doctor-appointment/doctor/doctor-03.webp",
    "/assets/images/our-products/doctor-appointment/doctor/doctor-04.webp",
    "/assets/images/our-products/doctor-appointment/doctor/doctor-05.webp",
    "/assets/images/our-products/doctor-appointment/doctor/doctor-06.webp",
    "/assets/images/our-products/doctor-appointment/doctor/doctor-07.webp",
    "/assets/images/our-products/doctor-appointment/doctor/doctor-08.webp",
    "/assets/images/our-products/doctor-appointment/doctor/doctor-09.webp",
    "/assets/images/our-products/doctor-appointment/doctor/doctor-10.webp",
    "/assets/images/our-products/doctor-appointment/doctor/doctor-11.webp",
    "/assets/images/our-products/doctor-appointment/doctor/doctor-12.webp"
];
const pharmacyButtonNames = [
    "Login",
    "Recent Orders Quotation",
    "Recent Orders Active",
    "Recent Orders Completed",
    "My Items",
    "Item Details",
    "Account",
    "My Profile",
    "Order Detail"
];
const pharmacyImageAddress = [
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-01.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-02.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-03.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-04.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-05.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-06.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-07.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-08.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-09.webp',
];
const deliveryButtonNames = [
    "Login", "Go Online",
    "Orders Pending", "Orders Accepted",
    "Orders Delivered", "My Profile", "Wallet",
    "Notification", "Account", "Go Offline"
];
const deliveryImageAddress = [
    '/assets/images/our-products/doctor-appointment/delivery/delivery-01.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-02.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-03.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-04.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-05.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-06.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-07.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-08.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-09.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-10.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-11.webp',
];
const adminButtonNames = [
    "Dashboard",
    "Manage Appointment",
    "Manage Pharmacy",
    "Manage Delivery Man",
    "Manage Patients",
    "Manage Doctors",
    "Manage Orders",
    "Manage Medicines",
    "Notifications",
    "Withdrawals",
    "Master",
    "Promo Codes"
];
const adminImageAddress = [
    '/assets/images/our-products/doctor-appointment/admin/admin-01.webp',
    '/assets/images/our-products/doctor-appointment/admin/admin-02.webp',
    '/assets/images/our-products/doctor-appointment/admin/admin-03.webp',
    '/assets/images/our-products/doctor-appointment/admin/admin-04.webp',
    '/assets/images/our-products/doctor-appointment/admin/admin-05.webp',
    '/assets/images/our-products/doctor-appointment/admin/admin-06.webp',
    '/assets/images/our-products/doctor-appointment/admin/admin-07.webp',
    '/assets/images/our-products/doctor-appointment/admin/admin-08.webp',
    '/assets/images/our-products/doctor-appointment/admin/admin-09.webp',
    '/assets/images/our-products/doctor-appointment/admin/admin-10.webp',
    '/assets/images/our-products/doctor-appointment/admin/admin-11.webp',
    '/assets/images/our-products/doctor-appointment/admin/admin-12.webp',
];
class DoctorAppointmentProduct extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Doctor Appointment with Teleconsultation' pageUrl='/doctor-appointment' metaTitle="doctor-appointment" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription={`This "Doctor Appointment System" is a comprehensive application designed to facilitate the
scheduling of doctor appointments and the purchase of medicines. It serves a diverse range
of users, including patients, doctors, pharmacies, and delivery personnel, offering a wide
array of functionalities to cater to each group's specific needs.`} />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Doctor Appointment with Teleconsultation"
                    // customColor="custom-coursier-color"
                    customColor="theme-gradient"
                    dataBlackOverlay={5}
                    bgImage="bg_image--102"
                    p="Web/Android/iOS Application"
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4> */}
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-coursier-color">Doctor Appointment with Teleconsultation</h3>
                                        <p className="justifypara">This "Doctor Appointment System" is a comprehensive application designed to facilitate the
                                            scheduling of doctor appointments and the purchase of medicines. It serves a diverse range
                                            of users, including patients, doctors, pharmacies, and delivery personnel, offering a wide
                                            array of functionalities to cater to each group's specific needs.</p>
                                        <h3 className="rn-page-title custom-coursier-color">User App Functionalities</h3>
                                        <p className="justifypara">The user app provides a robust platform for managing profiles, making wallet payments,
                                            ordering medicines, searching and selecting doctors, booking appointments, accessing
                                            appointment history, obtaining prescriptions and sharing feedback and reviews.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#2f64af" />Profile Management</li>
                                            <li><FiCheck color="#2f64af" /> Wallet Payment</li>
                                            <li><FiCheck color="#2f64af" /> Orders Medicines</li>
                                            <li><FiCheck color="#2f64af" />Doctor Search and Selection</li>
                                            <li><FiCheck color="#2f64af" />Appointment Booking</li>
                                            <li><FiCheck color="#2f64af" />My Appointments</li>
                                            <li><FiCheck color="#2f64af" />Prescription and Medicines</li>
                                            <li><FiCheck color="#2f64af" />Feedback and Reviews</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-coursier-color">Doctor App Functionalities</h3>
                                        <p className="justifypara">The Doctor App allows seamless registration and profile management, efficient appointment
                                            scheduling, prescription creation and management, feedback and reviews, wallet
                                            management, effective communication with patients, and the convenient management of
                                            availability for doctors.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#2f64af" />Registration and Profile Management</li>
                                            <li><FiCheck color="#2f64af" />Appointment Management</li>
                                            <li><FiCheck color="#2f64af" />Prescription Creation and Management</li>
                                            <li><FiCheck color="#2f64af" />Feedback and Reviews</li>
                                            <li><FiCheck color="#2f64af" />Wallet Management</li>
                                            <li><FiCheck color="#2f64af" />Communication with Patients</li>
                                            <li><FiCheck color="#2f64af" />Manage Availability</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <div className="d-sm-block d-flex justify-content-center align-items-center ptb--30">
                                            <button className="get-a-quote btn-doctor-appointment text-center custom-school-management-product" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                        <h3 className="rn-page-title custom-coursier-color">Pharmacy App Functionalities</h3>
                                        <p className="justifypara">The pharmacy app provides a comprehensive platform with functionalities such as a
                                            dashboard overview, medicine list and management, a seamless cart and ordering process,
                                            prescription handling, real-time order status tracking, efficient delivery services, wallet
                                            management and a user feedback and reviews system.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#2f64af" />Dashboard Overview</li>
                                            <li><FiCheck color="#2f64af" />Medicine List and Management</li>
                                            <li><FiCheck color="#2f64af" />My Cart and Ordering Process</li>
                                            <li><FiCheck color="#2f64af" />Prescription Handling</li>
                                            <li><FiCheck color="#2f64af" />Order Status and Delivery</li>
                                            <li><FiCheck color="#2f64af" />Wallet Management</li>
                                            <li><FiCheck color="#2f64af" />User Feedback and Reviews</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-coursier-color">Delivery Man App Functionalities</h3>
                                        <p className="justifypara">The Delivery Man App enables efficient management of registration, profile details, order
                                            assignment, fulfilment, real-time location tracking, customer communication, order status
                                            updates, seamless payment processing and comprehensive wallet management for delivery
                                            personnel.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#2f64af" />Registration and Profile Management</li>
                                            <li><FiCheck color="#2f64af" />Order Assignment</li>
                                            <li><FiCheck color="#2f64af" />Order Fulfillment</li>
                                            <li><FiCheck color="#2f64af" />Location Tracking</li>
                                            <li><FiCheck color="#2f64af" />Communication with Customers</li>
                                            <li><FiCheck color="#2f64af" />Order Status Updates</li>
                                            <li><FiCheck color="#2f64af" />Payment and Earnings</li>
                                            <li><FiCheck color="#2f64af" />Wallet Management</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-coursier-color">Admin Panel Functionalities</h3>
                                        <p className="justifypara">The admin panel of this application provides a centralized platform with a comprehensive
                                            dashboard overview, user management capabilities and efficient management of doctors,
                                            pharmacies, and delivery personnel.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#2f64af" />Dashboard Overview</li>
                                            <li><FiCheck color="#2f64af" />User Management</li>
                                            <li><FiCheck color="#2f64af" />Doctor Management</li>
                                            <li><FiCheck color="#2f64af" />Pharmacy (Store) Management</li>
                                            <li><FiCheck color="#2f64af" />Delivery Man Management</li>
                                            <li><FiCheck color="#2f64af" />Orders and Appointments</li>
                                            <li><FiCheck color="#2f64af" />Category and Medicine Management</li>
                                            <li><FiCheck color="#2f64af" />Email Communication</li>
                                            <li><FiCheck color="#2f64af" />Withdrawal Request Management</li>
                                        </ul>
                                        <div className="d-sm-block d-flex justify-content-center align-items-center pt--30">
                                            <button className="get-a-quote btn-doctor-appointment text-center custom-school-management-product" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-coursier-color' items={technologies} />
                            <div className="d-flex justify-content-center align-items-center pt--30">
                                <button className="get-a-quote btn-doctor-appointment text-center custom-school-management-product" onClick={this.showPopup}>
                                    <span>Get a quote </span>
                                </button>
                            </div>
                        </React.Suspense>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='The user app provides a robust platform for managing profiles, making wallet payments, ordering medicines, searching and selecting doctors, booking appointments, accessing appointment history, obtaining prescriptions and sharing feedback and reviews.'
                                    buttonNames={userAppButtonNames}
                                    image={userAppImageAddresses}
                                    alt='EMR user app'
                                    color='emr-product-app'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-doctor-appointment text-center custom-school-management-product" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Doctor App'
                                    p='The Doctor App allows seamless registration and profile management, efficient appointment scheduling, prescription creation and management, feedback and reviews, wallet management, effective communication with patients, and the convenient management of availability for doctors.'
                                    buttonNames={doctorButtonNames}
                                    image={doctorImageAddresses}
                                    alt='EMR doctor app'
                                    color='emr-product-app'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-doctor-appointment text-center custom-school-management-product" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Pharmacy App'
                                    p='The pharmacy app provides a comprehensive platform with functionalities such as a dashboard overview, medicine list and management, a seamless cart and ordering process, prescription handling, real-time order status tracking, efficient delivery services, wallet management and a user feedback and reviews system.'
                                    buttonNames={pharmacyButtonNames}
                                    image={pharmacyImageAddress}
                                    alt='EMR pharmacy app'
                                    color='emr-product-app'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-doctor-appointment text-center custom-school-management-product" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Delivery Man App'
                                    p='The Delivery Man App enables efficient management of registration, profile details, order assignment, fulfilment, real-time location tracking, customer communication, order status updates, seamless payment processing and comprehensive wallet management for delivery personnel.'
                                    buttonNames={deliveryButtonNames}
                                    image={deliveryImageAddress}
                                    alt='EMR delivery app'
                                    color='emr-product-app'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-doctor-appointment text-center custom-school-management-product" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='The admin panel of this application provides a centralized platform with a comprehensive dashboard overview, user management capabilities and efficient management of doctors, pharmacies, and delivery personnel.'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='EMR Admin'
                                    color='emr-product-app'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center ptb--30">
                        <button className="get-a-quote btn-doctor-appointment text-center custom-school-management-product" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-coursier-color' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                </div>
                <CommonPortfolioButtons class='btn-coursier' text='View All Our Products' link='/our-products' padding='pb--20' />

                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default DoctorAppointmentProduct;
