import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiCast, FiLayers, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonSliderBanner from "../component/common/CommonSliderBanner";
import ServiceSection from "./serviceSection";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";



const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Our IT Services',
        description: 'Thinking Of New Projects or Redefined Products',
        description1: 'Avail of our global standard expertise at the best prices',
        buttonText: 'Contact Us',
        buttonLink: '/contact-us'
    }
]


const WebsiteDevelopment = [
    {
        icon: <FiCast />,
        title: 'Custom Web Application',
        description: 'We offer full stack custom software development services ensuring scalability and responsiveness at every stage of development.'
    },
    {
        icon: <FiLayers />,
        title: 'CMS-based Web Application',
        description: 'Our expert CMS developers build user-friendly websites that you can update and manage without any technical knowledge.'
    },
    {
        icon: <FiUsers />,
        title: 'API Development',
        description: 'We have API programmers who develop customized applications as per your specific needs that aids you saving 60% of the cost.'
    },
    {
        icon: <FiMonitor />,
        title: 'AI/ML',
        description: 'Our consulting helps companies automate and optimize business processes and make accurate forecasts with AI-powered software.'
    },
    {
        icon: <FiCast />,
        title: 'Responsive Designing',
        description: 'We provide a responsive interface using up-to-date tools and technologies to build remarkable digital experiences for all devices.'
    },
    {
        icon: <FiMonitor />,
        title: 'Security',
        description: 'As an ISO 27001 compliant organization, we implement a comprehensive suite of security protocols and tools to safeguard your data by the best practices available.'
    },
]

const MobileAppDevelopment = [
    {
        icon: <FiCast />,
        title: 'Cross-Platform Applications',
        description: 'Expand your reach and accelerate market acquisition with cost-effective mobile apps built using Flutter, React Native, or Ionic. '
    },
    {
        icon: <FiLayers />,
        title: 'Native Apps',
        description: `Our team of experienced Android and iOS developers create applications that are unique, profitable and targeted to the user's requirements.`
    },
    {
        icon: <FiUsers />,
        title: 'IoT Based',
        description: 'We offer IoT software development services embedded with the systems. Connect, Communicate, Control & Manage your devices efficiently.'
    },
    {
        icon: <FiMonitor />,
        title: 'Augmented Reality (AR)',
        description: 'Get ahead with immersive AR technology solutions. Captivate users with interactive experiences and redefine how you connect with your audience.'
    },
    {
        icon: <FiCast />,
        title: 'Third-Party Integrations',
        description: 'Empower your mobile applications with seamless third-party integrations. From payment gateways to GPS integration, we excel in all aspects.'
    },
    {
        icon: <FiMonitor />,
        title: 'Security',
        description: 'Noorisys adheres to ISO 27001 standards, protecting your digital assets with rigorous security protocols and tools to safeguard your mobile applications.'
    },
]

const ItOutsourcing = [
    {
        icon: <FiCast />,
        title: 'Web Developers',
        description: 'Your website is digital face of your business, so choose wisely and hire a team of top notch professionals to get a result driven website.'
    },
    {
        icon: <FiLayers />,
        title: 'Mobile App Developers',
        description: 'Empower your business with our innovative mobile app developers, who are experienced with the leading technologies and dedicated to delivering unique solutions.'
    },
    {
        icon: <FiUsers />,
        title: 'Designers',
        description: 'A team of creative professionals who make stunning designs to captivate your audience and deliver alluring designs to enhance the user experience.'
    },
    {
        icon: <FiMonitor />,
        title: 'Testers',
        description: 'Ensure flawless performance with comprehensive testing solutions. From manual to automated testing, security to performance testing, we ensure your applications exceed expectations.'
    },
    {
        icon: <FiCast />,
        title: 'Project Managers',
        description: 'Evaluate your development processes with certified project managers. Our team is familiar with tools like Jira, Trello and Open Project accompanied by agile methodology.'
    },
    {
        icon: <FiMonitor />,
        title: 'Maintenance and Support',
        description: 'We always aim for customer satisfaction and long term relationships so we provide post development maintenance and support, free for 2 months.'
    },
]

const Customersupport = [
    {
        icon: <FiCast />,
        title: 'Email Support',
        description: 'Hire professional executives well versed with technologies and have a proper email etiquette in a business context.'
    },
    {
        icon: <FiLayers />,
        title: 'Inbound Call',
        description: 'Our team is equipped with customer handling experience, specialized skill sets, natural warmth, and customer service orientation.'
    },
    {
        icon: <FiUsers />,
        title: 'Outbound Call',
        description: 'We are having on-call support in five different languages viz. English, Arabic, Hindi, Marathi & Urdu.'
    },
    {
        icon: <FiMonitor />,
        title: 'Data Entry',
        description: 'Data is the new gold. We understand how data is sensitive, valuable and necessary. Keep it safe with us.Get data recorded for surveys, and other business needs.'
    },
    {
        icon: <FiCast />,
        title: 'Clinical Coding',
        description: 'Health Data is sensitive. Our offshore computers and hardware are purpose built with security features.'
    },
    {
        icon: <FiMonitor />,
        title: 'Chat Support',
        description: 'Be quick to resolve your customers’ queries. Get experts from us for 24x7 and take your profits and service standards to the next level.'
    },
]

const Training = [
    {
        icon: <FiCast />,
        title: 'Online Training',
        description: 'We believe in peer to peer learning and for the purpose of creating a well trained workforce, we train people.'
    },
    {
        icon: <FiLayers />,
        title: 'On-Campus Training',
        description: 'Noorisys has signed an MOU with  regional engineering colleges to train their students and we are open for more collaborations.'
    },
    {
        icon: <FiUsers />,
        title: 'Crash Courses',
        description: 'We are making industry ready personnels. Our crash courses make candidates ready to hire in the IT industry.'
    },
    {
        icon: <FiMonitor />,
        title: 'Industrial Training',
        description: 'Youths are the greatest asset of any nation and we believe in the power of training them in the right direction by our industry experts.'
    },
    {
        icon: <FiCast />,
        title: 'Private Coaching',
        description: 'Noorisys has a vision of transferring the expertise to the willing people and we are open to guide people in deciding their career path.'
    },
    {
        icon: <FiMonitor />,
        title: 'Business Coaching',
        description: 'We help organizations and individuals to switch over the latest versions and technologies of coding frameworks and languages.'
    },
]

const whyChooseUs = [
    {
        icon: <FiCast />,
        title: 'Expertise and Experience',
        description: 'Our experience spans over 12 years with a team of professionals that can provide you with innovative solutions to every project. We understand the challenges and opportunities faced by businesses in various industries and deliver exceptional results.'
    },
    {
        icon: <FiLayers />,
        title: 'Customised Solutions',
        description: `We don't believe in one-size-fits-all solutions. Instead, we take the time to analyse tour needs and goals, then design customized solutions that fit well within your field as well as the clients you would like to reach out to.`
    },
    {
        icon: <FiUsers />,
        title: 'Leading Innovations',
        description: 'We help you to stay ahead with our advanced technology solutions. For instance, we use top technologies like Artificial Intelligence (AI), Machine Learning (ML), and IoT (Internet of Things) in order to bring about innovation and transformation.'
    },
    {
        icon: <FiMonitor />,
        title: 'Commitment to Excellence',
        description: 'Quality is first for us, and ISO 27001 has certified it. Our products are built with utmost reliability, efficiency, and longevity while following strict quality standards and best practices.'
    },
    {
        icon: <FiCast />,
        title: 'Customer-Oriented Approach',
        description: 'Our top priority is your satisfaction. We take into account your feedbacks, address your concerns, and go above all the way beyond to exceed your expectations. We create positive experience throughout development process and even after that stage.'
    },
    {
        icon: <FiMonitor />,
        title: 'Punctual Results',
        description: 'On time project delivery within budget is a core principal of ours due to the given importance of deadlines in the organization. Efficient project management ensures that you remain on target as far as your project goes, thus allowing you concentrate on what matters most – expanding your business.'
    },
    {
        icon: <FiMonitor />,
        title: 'Transparent Communication',
        description: 'Communication makes a successful partnership. Transparent communication is our belief system; we keep you updated and involved throughout the development process. Your project status will always be clear including possible future developments.'
    },
    {
        icon: <FiMonitor />,
        title: 'Long-Term Partnership ',
        description: 'Once we have finished the project we don’t just disappear from the picture completely but instead aim at building long-term relationship with our clients providing ongoing support & guidance to ensure prosperity of their businesses in the long run. '
    },
]
class OurService extends Component {
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "AW-16650476770", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Affordable IT Services | Noorisys Technologies Pvt. Ltd.' pageUrl='/our-services' metaTitle="Mobile and Web App Development, IT Outsourcing, Customer Support and Training" metaImage="/assets/images/bg/bg-image-18.jpg"
                    metaDescription="Providing top-notch web development, mobile app development, and custom software development services. Discover the experts in IT solutions."
                    metaKeywords="custom web application development services,custom web application development,cms web application development,best cms for web developers,api development services ,responsive web design services ,responsive website design london" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />


                {/* Start Slider Area   */}
                {/* <div className="slider-wrapper">
                    Start Single Slide
                    {SlideList.map((value, index) => (
                        <div className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--18" key={index} data-black-overlay="6">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.description1 ? <p className="description">{value.description1}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    End Single Slide
                </div> */}
                <CommonSliderBanner
                    slideList={SlideList}
                    dataBlackOverlay={6}
                    classes={'slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--18'}
                    titleClass={'theme-gradient'}
                    col={'col-lg-12'}
                />
                {/* End Slider Area   */}
                {/* Start Service Area */}
                {/* <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Website Development</h2>
                                    <p>Matching  development needs of B2B or B2C businesses, <br /> we offer efficient web development solutions that accelerate business profitability.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {WebsiteDevelopment.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>

                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Service Area */}
                {/* <div className="service-area ptb--30 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Mobile App Development</h2>
                                    <p>Our experienced developers create robust and engaging mobile applications that meet your specific requirements and deliver exceptional user experiences.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {MobileAppDevelopment.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>

                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Service Area */}
                {/* <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>IT Outsourcing</h2>
                                    <p>Experience our affordable, fast-paced, and hassle-free hiring at competitive rates! With flexible time zones,  <br /> we start sooner and stay longer.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ItOutsourcing.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>

                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Service Area */}
                {/* <div className="service-area ptb--30 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Data Protection Assessment</h2>
                                    <p>Elevate Your Data Security with Our Comprehensive Assessment. Gain a Proficient and Well-versed Team for Optimal Protection.</p>
                                    <div className="about-button d-flex justify-content-center mt--30">
                                        <Link className="rn-button-style--2 btn-solid" to='/data-protection-assessment' > Explore DPDP Services</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}
                {/* Start Service Area */}
                {/* <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Customer Support</h2>
                                    <p>Our international customer service experience can add a value to your business. <br />Get a well trained and qualified staff.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {Customersupport.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>

                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Service Area */}
                {/* <div className="service-area ptb--30 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Training</h2>
                                    <p>We believe in peer to peer learning process and power of skilled youths for a nation.<br />Join hands with our training programs.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {Training.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>

                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Service Area */}
                {/* <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Why Choose Us? </h2>
                                    <h3>Boost Your Business with Noorisys Technologies</h3>
                                    <p>Noorisys Technologies understands that choosing a reliable partner for your technological requirements is very important to the success and growth of your company. Here’s the highlights why we should be your choice.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {whyChooseUs.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>

                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                <ServiceSection
                    title="Website Development"
                    description="Matching development needs of B2B or B2C businesses,<br /> we offer efficient web development solutions that accelerate business profitability."
                    services={WebsiteDevelopment}
                    bgColor="bg_color--1"
                />
                <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-common-color'} paddingTop={'pt--20'} paddingBottom={'pb--20'} buttonName={'Get In Touch'} />
                <ServiceSection
                    title="Mobile App Development"
                    description="Our experienced developers create robust and engaging mobile applications that meet your specific requirements and deliver exceptional user experiences."
                    services={MobileAppDevelopment}
                    bgColor="bg_color--5"
                />
                <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-common-color'} paddingTop={'pt--20'} paddingBottom={'pb--20'} buttonName={'Get In Touch'} />

                <ServiceSection
                    title="IT Outsourcing"
                    description="Experience our affordable, fast-paced, and hassle-free hiring at competitive rates! With flexible time zones, <br /> we start sooner and stay longer."
                    services={ItOutsourcing}
                    bgColor="bg_color--1"
                />
                <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-common-color'} paddingTop={'pt--20'} paddingBottom={'pb--20'} buttonName={' Hire Now'} />

                <ServiceSection
                    title="Customer Support"
                    description="Our international customer service experience can add a value to your business.<br /> Get a well trained and qualified staff."
                    services={Customersupport}
                    bgColor="bg_color--1"
                />
                <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-common-color'} paddingTop={'pt--20'} paddingBottom={'pb--20'} buttonName={'Get In Touch'} />

                <ServiceSection
                    title="Training"
                    description="We believe in peer to peer learning process and power of skilled youths for a nation.<br /> Join hands with our training programs."
                    services={Training}
                    bgColor="bg_color--5"
                />
                <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-common-color'} paddingTop={'pt--20'} paddingBottom={'pb--20'} buttonName={`Get In Touch`} />

                <ServiceSection
                    title="Why Choose Us?"
                    subtitle="Boost Your Business with Noorisys Technologies"
                    description="Noorisys Technologies understands that choosing a reliable partner for your technological requirements is very important to the success and growth of your company. Here’s the highlights why we should be your choice."
                    services={whyChooseUs}
                    bgColor="bg_color--1"
                />
                <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-common-color'} paddingTop={'pt--20'} paddingBottom={'pb--20'} buttonName={`Let's Collaborate`} />

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default OurService;