import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiMapPin } from "react-icons/fi";
import ContactPage from "./ContactPage";
import CreativeBrand from "./CreativeBrand";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import 'reactjs-popup/dist/index.css';
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
class Contact extends Component {
    state = {
        myParam: null
    };
    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const myParam = params.get('from');
        this.setState({ myParam });
        // console.log('MyParam:', myParam);
    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "AW-16650476770", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Contact || Noorisys Technologies Pvt Ltd' pageUrl='/contact-us' metaTitle="Get in touch with Noorisys" metaImage="/assets/images/bg/bg-image-17.jpg"
                    metaDescription="Get in touch with Noorisys - the leading mobile and web development company in India. We are here to answer any of your questions and help you bring your digital vision to life. Contact us today!" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb title='Contact Us' dataBlackOverlay={6} bgImage='bg_image--17' />
                {/* End Breadcrump Area */}

                {/* <ContactForm/> */}


                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--60 bg_color--1">
                    <ContactPage title='Contact Us.' text='Drop your query and we will respond to you within 24 hours.
' subject={this.state.myParam ? 'Noorisys.com | Mobile App Landing Page Contact' : 'Noorisys.com | Contact us form'} myParam={this.state.myParam} />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--30 bg_color--5">
                    <div className="container">

                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">UK Office</h4>
                                        <p style={{fontSize:'16px'}}>Tree Tops, Billinge End Road, Blackburn,<br /> England, BB2 6PT</p>
                                        {/* <p><a href="tel:+44 75157 88666">+44 75157 88666</a></p> */}
                                        <p style={{fontSize:'16px'}}><a href="tel:+442038850502">+44 20 3885 0502</a></p>
                                        <p style={{fontSize:'16px'}}><a href="mailto:info@noorisys.com">info@noorisys.com</a></p>
                                        {/* <p><br /></p> */}


                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Pune Office</h4>
                                        <p style={{fontSize:'16px'}}>502, Fortune Business Centre, Wakad, Pune <br />  411057, IND</p>
                                        <p style={{fontSize:'16px'}}><a href="tel:+917796614664">+91 77966 14664</a></p>
                                        <p style={{fontSize:'16px'}}><a href="mailto:info@noorisys.com">info@noorisys.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Mumbai Office</h4>
                                        <p style={{fontSize:'16px'}}>10th, Floor Empire Tower,<br />Reliable Tech Park, Airoli, New Mumbai<br /> 400001, IND</p>
                                        <p style={{fontSize:'16px'}}><a href="tel:+917385735373">+91 73857 35373</a></p>
                                        <p style={{fontSize:'16px'}}><a href="mailto:info@noorisys.com">info@noorisys.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Nashik Office</h4>
                                        <p style={{fontSize:'16px'}}>105, Budhwaar Ward <br />Malegaon, Nashik <br /> 423203, IND</p>
                                        <p style={{fontSize:'16px'}}><a href="tel:+917385735373">+91 73857 35373</a></p>
                                        <p style={{fontSize:'16px'}}><a href="mailto:info@noorisys.com">info@noorisys.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}


                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--40">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <CreativeBrand />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Contact