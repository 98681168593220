import React, { Component, useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
// import PortfolioMasonry from './PortfolioMasonry';
import { FiCheck } from "react-icons/fi";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// import CourierPortfolioMasonry from "./CourierMasonry";
// import HospitalMasonry from "./HospitalMasonry";
// import HealthcareMasonry from "./HealthcareMasonry";
// import HotelMasonry from "./HotelMasonry";
// import MultingualPregnancyMasonry from "./MultingualPregnancyMasonry";
// import TabExample from "../component/Verbos/Tabexample";
// import MultilingualAdminDemo from "./MultilingualAdminDemo";
// import Delivrable from "../component/Verbos/Deleverable";
// import PlatformUsed from "../component/Verbos/PlatformUsed";
// import SliderPortfolio from "../component/Verbos/SliderPortfolio";
// import VerbosSimilarApps from "../component/Verbos/VerbosSimilarApps";
// import FooterType from "../component/Verbos/FooterType";
// import Download1 from "../component/Verbos/Download1";

// const SocialShare = [
//     { Social: <FaGlobe />, link: 'https://janamapp.co.uk/ ' }
// ]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
]
const driverAppButtonNames = ['Splash Screen', 'Register', 'Home', 'My Profile', 'History'];
const driverImageAddress = [
    '/assets/images/portfolio/medical-emergency/driver-app/medical-emergency-driver-1.webp',
    '/assets/images/portfolio/medical-emergency/driver-app/medical-emergency-driver-2.webp',
    '/assets/images/portfolio/medical-emergency/driver-app/medical-emergency-driver-3.webp',
    '/assets/images/portfolio/medical-emergency/driver-app/medical-emergency-driver-4.webp',
    '/assets/images/portfolio/medical-emergency/driver-app/medical-emergency-driver-5.webp',
];
const patientAppButtonNames = ['Splash Screen', 'Login', 'Home', 'My Profile', 'Forgot Password'];
const patientImageAddress = [
    '/assets/images/portfolio/medical-emergency/driver-app/medical-emergency-driver-1.webp',
    '/assets/images/portfolio/medical-emergency/driver-app/medical-emergency-driver-2.webp',
    '/assets/images/portfolio/medical-emergency/patient-app/patient-2.webp',
    '/assets/images/portfolio/medical-emergency/patient-app/patient-3.webp',
    '/assets/images/portfolio/medical-emergency/patient-app/patient-4.webp',
];
const samuWebButtonNames = ['login', 'Dashboard', 'Completed Calls', 'Zoom Calls', 'Patient Details', 'Patient Location', 'Patient Chat', 'Health Center Chat System', 'Manage Driver', 'Driver Avalability', 'Active Doctors', 'Emergency History', 'Emergency Details']
const samuImaageAddress = [
    '/assets/images/portfolio/medical-emergency/samu/samu-1.webp',
    '/assets/images/portfolio/medical-emergency/samu/samu-2.webp',
    '/assets/images/portfolio/medical-emergency/samu/samu-3.webp',
    '/assets/images/portfolio/medical-emergency/samu/samu-4.webp',
    '/assets/images/portfolio/medical-emergency/samu/samu-5.webp',
    '/assets/images/portfolio/medical-emergency/samu/samu-6.webp',
    '/assets/images/portfolio/medical-emergency/samu/samu-7.webp',
    '/assets/images/portfolio/medical-emergency/samu/samu-8.webp',
    '/assets/images/portfolio/medical-emergency/samu/samu-9.webp',
    '/assets/images/portfolio/medical-emergency/samu/samu-10.webp',
    '/assets/images/portfolio/medical-emergency/samu/samu-11.webp',
    '/assets/images/portfolio/medical-emergency/samu/samu-12.webp',
    '/assets/images/portfolio/medical-emergency/samu/samu-13.webp',
];
const healthCareCenterButtonNames = [
    'Login', 'Current Emergency', 'Samu Chat', 'Manage Bed', 'Manage Wards', 'Manage Departments', 'Emergency History', 'Emergency Details'
];
const healthCareCenterImageAddress = [
    '/assets/images/portfolio/medical-emergency/health-center/health-1.webp',
    '/assets/images/portfolio/medical-emergency/health-center/health-2.webp',
    '/assets/images/portfolio/medical-emergency/health-center/health-3.webp',
    '/assets/images/portfolio/medical-emergency/health-center/health-4.webp',
    '/assets/images/portfolio/medical-emergency/health-center/health-5.webp',
    '/assets/images/portfolio/medical-emergency/health-center/health-6.webp',
    '/assets/images/portfolio/medical-emergency/health-center/health-7.webp',
    '/assets/images/portfolio/medical-emergency/health-center/health-8.webp',
];
const doctorWebButtonNames = ['Login', 'Dashboard', 'Emergency Calls', 'My Patients', 'My Profile', 'Patient Locations'];
const doctorImageAddress = [
    '/assets/images/portfolio/medical-emergency/doctor-web/doctor-1.webp',
    '/assets/images/portfolio/medical-emergency/doctor-web/doctor-2.webp',
    '/assets/images/portfolio/medical-emergency/doctor-web/doctor-3.webp',
    '/assets/images/portfolio/medical-emergency/doctor-web/doctor-4.webp',
    '/assets/images/portfolio/medical-emergency/doctor-web/doctor-5.webp',
    '/assets/images/portfolio/medical-emergency/doctor-web/doctor-6.webp',
]
const adminAppButtonNames = ['Login', 'Dashboard', 'Manage SAMU', 'Manage Patient', 'Manage Health Center', 'Manage Doctor', 'Subscription Plans', 'Emergency History', 'Change Password', 'Emergency Details', 'Active Drivers', 'InActive Drivers', 'SAMU Emergency History'];
const adminImaageAddress = [
    '/assets/images/portfolio/medical-emergency/admin/admin-1.webp',
    '/assets/images/portfolio/medical-emergency/admin/admin-2.webp',
    '/assets/images/portfolio/medical-emergency/admin/admin-3.webp',
    '/assets/images/portfolio/medical-emergency/admin/admin-4.webp',
    '/assets/images/portfolio/medical-emergency/admin/admin-5.webp',
    '/assets/images/portfolio/medical-emergency/admin/admin-6.webp',
    '/assets/images/portfolio/medical-emergency/admin/admin-7.webp',
    '/assets/images/portfolio/medical-emergency/admin/admin-8.webp',
    '/assets/images/portfolio/medical-emergency/admin/admin-9.webp',
    '/assets/images/portfolio/medical-emergency/admin/admin-10.webp',
    '/assets/images/portfolio/medical-emergency/admin/admin-11.webp',
    '/assets/images/portfolio/medical-emergency/admin/admin-12.webp',
    '/assets/images/portfolio/medical-emergency/admin/admin-13.webp',
];
function usePageViewTracking(location) {
    useEffect(() => {
        window.gtag("config", "AW-16650476770", {
            page_title: location.pathname,
            page_path: location.pathname,
        });
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', location.pathname]);
        _hsq.push(['trackPageView']);
    }, [location]);
}
function MedicalEmergencyCallManagementPortfolio(props) {
    usePageViewTracking(props.location);
    return (
        <React.Fragment>
            <PageHelmet pageTitle='Medical Emergency Call Management' pageUrl='/medical-emergency-call-management' metaTitle="Medical Emergency Call Management" metaImage="/assets/images/portfolio/dp-portfolio-09.jpg"
                metaDescription="platform connects users/patients, SAMU (Emergency Medical Aid Provider) and health centres seamlessly." />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <CommonBreadcrumb
                title="Medical Emergency Call Management"
                customColor="theme-gradient"
                dataBlackOverlay={7}
                bgImage="bg_image--143"
                p="Android / iOS Application"
                pb='pb--120'
            />
            {/* End Breadcrump Area */}

            {/* Start Portfolio Details */}

            <div className="rn-portfolio-details ptb--40 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-details">
                                <div className="inner">
                                    <h4><span>TECHNICAL DETAILS</span></h4>
                                    <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        <div className="port-view">
                                            <span>Category</span>
                                            <h6>Android / iOS Application</h6>
                                        </div>

                                        <div className="port-view">
                                            <span>Project Type</span>
                                            <h6>Healthcare Informational App</h6>
                                        </div>

                                        <div className="port-view">
                                            <span>Technology Used</span>
                                            <h6>PHP, CodeIgniter, Flutter</h6>
                                        </div>
                                    </div>
                                    <h3 className="rn-page-title custom-water-donation-color">Medical Emergency Call Management</h3>
                                    <p className="justifypara">This platform connects users/patients, SAMU (Emergency Medical Aid Provider) and health centres seamlessly. Through this application, patients will quickly access emergency services, receive real-time assistance and navigate to nearby healthcare facilities.</p>
                                    <h4 className="title">Patient App Functionality</h4>
                                    <ul className="list-style--1">
                                        <li><FiCheck style={{ color: '#245456' }} />Registration and Login</li>
                                        <li><FiCheck style={{ color: '#245456' }} />User Profile</li>
                                        <li><FiCheck style={{ color: '#245456' }} />My Food Preferences</li>
                                        <li><FiCheck style={{ color: '#245456' }} />My Medical History</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Geolocation</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Communication with SAMU</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Map of Health Establishments</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Order Water for Mosques</li>
                                        <li><FiCheck style={{ color: '#245456' }} />My Subscription</li>
                                    </ul>

                                    <h4 className="title">SAMU Admin Functionality</h4>
                                    <ul className="list-style--1">
                                        <li><FiCheck style={{ color: '#245456' }} />Login</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Dashboard</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Ongoing Emergencies</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Access to Medical Records</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Real-Time Chat and Video Communication</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Assign Driver</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Interactive Map</li>
                                        <li><FiCheck style={{ color: '#245456' }} />History of Interventions</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Management of Drivers</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Alerts and Notifications</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Subadmins</li>
                                    </ul>
                                    <h4 className="title">Doctor Functionality</h4>
                                    <ul className="list-style--1">
                                        <li><FiCheck style={{ color: '#245456' }} />Login</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Dashboard</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Patients</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Notifications</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Ongoing Emergencies</li>
                                    </ul>
                                    <h4 className="title">Driver App Functionality</h4>
                                    <ul className="list-style--1">
                                        <li><FiCheck style={{ color: '#245456' }} />Login</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Profile</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Availability</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Status</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Current Emergency</li>
                                        <li><FiCheck style={{ color: '#245456' }} />View Route</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Health Centre Chosen</li>
                                        <li><FiCheck style={{ color: '#245456' }} />History</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Alerts and Notifications</li>
                                    </ul>
                                    <h4 className="title">Healthcare Centres Functionality</h4>
                                    <ul className="list-style--1">
                                        <li><FiCheck style={{ color: '#245456' }} />Login</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Voice/Chat Communication</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Bed Availability</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Current Emergency</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Access to Medical Records</li>
                                        <li><FiCheck style={{ color: '#245456' }} />History</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Subadmins</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Department</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Ward</li>
                                    </ul>
                                    <h4 className="title">Admin Functionality</h4>
                                    <ul className="list-style--1">
                                        <li><FiCheck style={{ color: '#245456' }} />Dashboard</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Patients</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage SAMU</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Health Centres</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Subadmins</li>
                                        <li><FiCheck style={{ color: '#245456' }} />History of Emergency</li>
                                    </ul>

                                    {/* <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Details */}

            {/* <FooterType /> */}

            {/* Start portfolio Area  */}
            <div className="rn-portfolio-area bg_color--1 ptb--30 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center service-style--3 mb--30">
                                {/* <p></p> */}
                                {/* <h3 className="title">Screenshots</h3> */}
                                {/* <p></p> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="ptb--60">
                <SliderPortfolio />
                </div> */}

                {/* <Delivrable /> */}

                {/* <PlatformUsed /> */}
                <div className="">
                    {/* <FooterType /> */}
                </div>


                <div className="wrapper bg_color--3">
                    <div className="container" >
                        <div className="row ">
                            {/* <MultingualPregnancyMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" /> */}
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Patient App'
                                    p='Mobile app users seeking emergency healthcare services.'
                                    buttonNames={patientAppButtonNames}
                                    image={patientImageAddress}
                                    alt='mommycare user'
                                    color='water-donation-app'
                                />
                                <ProductCommonApp
                                    h3='Driver App'
                                    p='Mobile app for personnel responsible for transporting SAMU teams to emergency locations.'
                                    buttonNames={driverAppButtonNames}
                                    image={driverImageAddress}
                                    alt='mommycare user'
                                    color='water-donation-app'
                                />
                                <CommonProductWeb
                                    h3='SAMU (Emergency Medical Aid Provider)'
                                    p='Web app SAMU administrators managing emergency requests and coordinating responses.'
                                    buttonNames={samuWebButtonNames}
                                    image={samuImaageAddress}
                                    alt='mommycare admin'
                                    color='water-donation-admin'
                                />
                                <CommonProductWeb
                                    h3='Doctor Panel'
                                    p='SAMU can forward emergency calls to doctors if needed and doctor can manage patients and their emergency calls.'
                                    buttonNames={doctorWebButtonNames}
                                    image={doctorImageAddress}
                                    alt='mommycare admin'
                                    color='water-donation-admin'
                                />
                                <CommonProductWeb
                                    h3='Health Center Panel'
                                    p='Web app for health centre administrators managing healthcare facilities and receiving emergency patients'
                                    buttonNames={healthCareCenterButtonNames}
                                    image={healthCareCenterImageAddress}
                                    alt='mommycare admin'
                                    color='water-donation-admin'
                                />
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='Web app for administrators to manage the overall operation and functionality of the application.'
                                    buttonNames={adminAppButtonNames}
                                    image={adminImaageAddress}
                                    alt='mommycare admin'
                                    color='water-donation-admin'
                                />
                                {/* <MultilingualAdminDemo /> */}
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="ptb--60">

                    </div>

                </div>
                <React.Suspense fallback={<div>loading...</div>} >
                    <VerbosSimilarApps color='custom-water-donation-color' items={similarapps} title={'Apps'} slidestoshow={[3, 3]} />
                </React.Suspense>
                <CommonPortfolioButtons class='btn-water-donation' text='View All Our Projects' />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <VerbosSimilarApps /> */}
                            <div className="custom_download_app">
                                {/* <Download1 /> */}
                            </div>
                            <div className="section-title text-center service-style--3 mb--10">
                                {/* <h3 className="title">Screenshots</h3> */}

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* End portfolio Area  */}



            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />


        </React.Fragment>
    )
}
export default MedicalEmergencyCallManagementPortfolio;
