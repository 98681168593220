import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

// const SocialShare =[
//     {Social:<FaGlobe />,link:' https://annachi.fr/ '}
// ]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'Python', p: 'Backend Development', img: 'python.png' },

    { h5: 'Native Android', p: 'Android & IOS Development', img: 'androidios.png' },
]

const adminButtonNames = ['Login', 'Dashboard', 'Categories', 'Jobs', 'Users'];
const adminImageAddress = [
    '/assets/images/portfolio/matness/admin/admin-01.webp',
    '/assets/images/portfolio/matness/admin/admin-02.webp',
    '/assets/images/portfolio/matness/admin/admin-03.webp',
    '/assets/images/portfolio/matness/admin/admin-04.webp',
    '/assets/images/portfolio/matness/admin/admin-05.webp',
];

const userButtonNames = ['Splash Screen', 'Login Or Register', 'Registration', 'Registration-2', 'Registration-3', 'Forgot Password', 'OTP Verification', 'Login', 'Home', 'Create Exercise Challange 1', 'Create Exercise Challange 2', 'View Exercise Challange', 'Messages', 'My Profile', 'Edit Profile'];
const userImageAddress = [
    '/assets/images/portfolio/matness/user/user-01.webp',
    '/assets/images/portfolio/matness/user/user-02.webp',
    '/assets/images/portfolio/matness/user/user-03.webp',
    '/assets/images/portfolio/matness/user/user-04.webp',
    '/assets/images/portfolio/matness/user/user-05.webp',
    '/assets/images/portfolio/matness/user/user-06.webp',
    '/assets/images/portfolio/matness/user/user-07.webp',
    '/assets/images/portfolio/matness/user/user-08.webp',
    '/assets/images/portfolio/matness/user/user-09.webp',
    '/assets/images/portfolio/matness/user/user-10.webp',
    '/assets/images/portfolio/matness/user/user-11.webp',
    '/assets/images/portfolio/matness/user/user-12.webp',
    '/assets/images/portfolio/matness/user/user-13.webp',
    '/assets/images/portfolio/matness/user/user-14.webp',
    '/assets/images/portfolio/matness/user/user-15.webp',
];
class MatnessAppPortfolio extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Fitness Application' pageUrl='/fitness' metaTitle="Digital Solution For Civil Documents" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription="A comprehensive solution that seamlessly incorporates all the essential features for managing a successful food delivery business. From multi-language support and precise coverage area mapping to streamlined order management and versatile payment gateways,our app has it all" />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Fitness Application"
                    customColor="custom-matness-portfolio"
                    dataBlackOverlay={5}
                    bgImage="bg_image--111"
                    p="Web/Android/iOS Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4> */}
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-matness-portfolio">Fitness Application</h3>
                                        <p className="justifypara">The Fitness App aims to facilitate users in creating and participating in exercise challenges. Users can register, create challenges, and view others' challenges along with rankings. </p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#F07317" />Login and Register</li>
                                            <li><FiCheck color="#F07317" />Profile Management</li>
                                            <li><FiCheck color="#F07317" />Weekly workout graph display</li>
                                            <li><FiCheck color="#F07317" />List of other users' publications</li>
                                            <li><FiCheck color="#F07317" />Profile viewing and chat initiation</li>
                                            <li><FiCheck color="#F07317" />Text chat functionality with other users</li>
                                            <li><FiCheck color="#F07317" />Exercise Challenge Creation</li>
                                            <li><FiCheck color="#F07317" />Multiple challenge models</li>
                                            <li><FiCheck color="#F07317" />Multilingual Support</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-matness'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-matness-portfolio' items={technologies} />
                        </React.Suspense>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-matness'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User'
                                    p={`Users of the Matness App can create and engage in exercise challenges, view others' workouts, and communicate through text chat, fostering a community-oriented fitness experience.`}
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt="matness user"
                                    color='matness-user'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-matness'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin'
                                    p={`Admin manages and oversees the platform, with responsibilities including user management, challenge oversight, and exercise category control through a centralized dashboard.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt="Service Providers"
                                    color='matness-admin'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-matness'} paddingTop={'pt--30'} paddingBottom={`pb--30`} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        {/* <VerbosSimilarApps color='custom-food-delivery-product' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} /> */}
                        <VerbosSimilarApps color='custom-matness-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-matness' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default MatnessAppPortfolio;
