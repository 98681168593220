import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonBlogBanner from "../component/common/CommonBlogBanner";
import { Link } from "react-router-dom/cjs/react-router-dom";

class WorldHeritageDay extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='The Past and the Future – World Heritage Day 2024.' pageUrl='/world-heritage-day' metaTitle="The Past and the Future – World Heritage Day 2024." metaImage="/assets/images/blog/blog-01.webp"
                    metaDescription="Every Year 18th April is celebrated as International Day or World Heritage Day, which is a global celebration of unique and exceptional cultural and natural sites recognised by UNESCO. " />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBlogBanner date={'April 19, 2024'} writer={'ANSARI ALTAMASH'} title={'The Past and the Future – World Heritage Day 2024.'} />
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <div className="">
                                            <p className="justifypara">Every Year 18th April is celebrated as International Day or World Heritage Day, which is a global celebration of unique and exceptional cultural and natural sites recognised by UNESCO. These sites come under the World Heritage list that have irreplaceable value as cultural and natural landmarks. </p>
                                        </div>
                                        <h5 className="title">The theme for World Heritage Day 2024 </h5>
                                        <p className="justifypara">The theme for World Heritage Day 2024 is “Discover and experience diversity”, which captures the essence of World Heritage sites. </p>
                                        <h5 className="title">List of top World Heritage sites </h5>
                                        <ul className="list-style">
                                            <li className="justifypara">Taj Mahal, 1648, India</li>
                                            <li className="justifypara">The Pyramids of Giza, Egypt</li>
                                            <li className="justifypara">Archaeological Areas of Pompeii, 79 CE, Italy</li>
                                            <li className="justifypara">Borobudur Temple, 9th century CE, Indonesia</li>
                                            <li className="justifypara">The Great Wall of China, 7th century BC, China</li>
                                            <li className="justifypara">Angkor Wat, 12th century CE, Siem Reap, Cambodia</li>
                                            <li className="justifypara">Palace and Gardens of Versailles, 1682, France</li>
                                            <li className="justifypara">Statue of Liberty, 1886, New York, United States</li>
                                            <li className="justifypara">Machu Picchu, 1450, Peru</li>
                                        </ul>
                                        <br />
                                        <div className="blog-single-list-wrapper d-flex flex-wrap justify-content-center">
                                            <div className="thumbnail d-flex align-items-center pb--20">
                                                {/* <img className="w-100" src="/assets/images/blog/blog-single-15.webp" alt="hyperautomation bLog" loading="lazy" /> */}
                                                <img className="w-100" src="/assets/images/blog/taj.webp" alt="hyperautomation bLog" loading="lazy" />
                                            </div>
                                            <div className="content">
                                                <p className="justifypara">These diverse locations of the World Heritage, from the Taj Mahal to the Great Wall of China to the majestic ruins of Machu Picchu offer a glimpse into different eras, cultures and ecosystems. And We should not forget that the most important perspective of this day is to raise awareness about the importance of these historical sites and their preservation.</p>
                                                <h5 className="title">History of World Heritage Day</h5>
                                                <p className="justifypara">It all began in 1982. The International Council on Monuments and Sites (ICOMOS) proposed a dedicated day to raise awareness about the importance and to preserve these cultural and natural heritage. the United Nations Educational Scientific and Cultural Organization (UNESCO), adopted this idea, and because of that, the first World Heritage Day was celebrated in 1983.</p>
                                                <p className="justifypara">Since then, World Heritage Day has become a global platform. It brings together governments, NGOs, international organizations, and individuals who have a mission to protect these invaluable historical and cultural sites.</p>
                                            </div>
                                        </div>
                                        <div className="content ptb--20">

                                            <h5 className="title">Important dates: </h5>
                                            <p className="justifypara">In 1984, The first sites received the prestigious World Heritage designation, which highlighted their global importance and prompted national efforts for their protection.</p>
                                            <p className="justifypara">In 2002, The Day’s official name changed to World Heritage Day. Which solidified its link to the World Heritage List and broadened its focus to encompass both natural and cultural sites.</p>
                                            <p className="justifypara">In 2023, Over 1,150 sites embellished the World Heritage List. Each of these sites represents the diversity of human cultures and the wonders of the natural world.</p>
                                            <br />

                                            <h5 className="title">What is the Significance of World Heritage Sites?</h5>
                                            <p className="justifypara">World Heritage sites aren't just tourist destinations; they represent the very essence of who we are and where we come from.  Let's explore why these sites hold such significance:</p>
                                            <ul className="list-style">
                                                <li className="justifypara"><span style={{ fontWeight: "bold", color: 'black' }}>Cultural Sites:</span>These sites showcase the architectural, artistic, and technological achievements of past civilizations. From the ancient pyramids of Giza to the medieval castles of Europe to the Taj Mahal, they show the richness of our planet’s history, beauty, and knowledge.</li>
                                                <li className="justifypara"><span style={{ fontWeight: "bold", color: 'black' }}>Natural Marvels:</span>Natural World Heritage sites such as the Galapagos Islands and the Great Barrier Reef are our planet's incredible biodiversity and they serve as crucial ecological sites of the world.</li>
                                                <li className="justifypara"><span style={{ fontWeight: "bold", color: 'black' }}>Economic Benefits:</span>Cultural and natural heritage sites are one of the most significant contributors to the global economy, as tourism generates huge revenue for local communities and also fosters cultural exchange.</li>
                                                <li className="justifypara"><span style={{ fontWeight: "bold", color: 'black' }}>Sense of Identity:</span>World Heritage sites serve as powerful symbols of national and regional identity. They provide a sense of belonging and connection to the past for communities around the globe.</li>
                                                <li className="justifypara"><span style={{ fontWeight: "bold", color: 'black' }}>Educating Young People:</span>This day is an opportunity to educate young people about the importance of these irreplaceable heritage sites and inspire them to protect these sites.</li>
                                            </ul>
                                            <br />
                                            <p className="justifypara">Ultimately, we can say that this day is a call to action. It makes us go beyond mere celebration and take steps to protect these irreplaceable treasures of the world. Whether it’s visiting a site, supporting conservation efforts, volunteering, or advocating for policy changes, every action counts in safeguarding these sites for generations to come.</p>
                                            <h5 className="title">Technology: </h5>
                                            <p className="justifypara">Now, technological advancements are emerging as powerful tools for preserving these irreplaceable places. Such as</p>

                                            <ul className="list-style">
                                                <li className="justifypara">Advanced 3D modelling and scanning technologies are used to create detailed digital replicas of World Heritage sites.</li>
                                                <li className="justifypara">Sensors and satellite imagery are being used for real-time monitoring of these sites.</li>
                                                <li className="justifypara">Big data and advanced analytics tools are being used to analyse tourist trends, historical data, as well as environmental conditions.</li>
                                                <li className="justifypara">VR is used to virtually explore World Heritage sites from anywhere in the world, while AR overlays historical information on real-world locations. It enhances visitor experiences and promotes a deeper engagement with these sites.</li>
                                            </ul>

                                            <h4 className="title pt--20">How Noorisys is helping with Innovative Travel Technology Solutions</h4>
                                            <p className="justifypara">In today's era, we know accessibility and ease of use are crucial factors. This is where Noorisys steps in. Noorisys Technologies Pvt. Ltd. has developed some user-friendly applications, which are designed to enhance the travel experience and, in turn, contribute to the World Heritage preservation efforts. </p>
                                            <p className="justifypara">Now, let's explore how Noorisys' solutions are impacting World Heritage Day:</p>
                                            <p className="justifypara"></p>
                                            <h4 className="title">1.	Online Ticket Booking:</h4>
                                            <p className="justifypara">Noorisys' Online Ticket Booking system simplifies travel logistics and makes it easier for people to visit different places. The platform allows users to book flights, trains, buses, and taxis. This removes logistical hurdles.</p>
                                            <h4 className="title">2.	BTK:</h4>
                                            <p className="justifypara">This is a comprehensive platform designed to facilitate seamless interactions between users, service providers and artists. Offering a user-friendly experience, it features many modules, including a marketplace for tour packages, artists, music and local listings.</p>
                                            <h4 className="title">3.	Online Travel Agency:</h4>
                                            <p className="justifypara">Noorisys' Online Travel Agency platform offers a one-stop solution for travel planning, including booking Umrah and Ziyarat packages, searching for visa requirements, and booking local cabs etc.</p>
                                            <p> <Link to='/portfolio' className="btn-transparent rn-btn-dark">For more solutions, please visit the Noorisys Technologies Pvt. Ltd. Website.</Link></p>
                                            <h4 className="title">Conclusion: </h4>
                                            <p className="justifypara">World Heritage Day is a global event being celebrated all over the world. It is a powerful reminder of our responsibility to protect and safeguard these invaluable treasures and to preserve them for future generations. It also helps us foster appreciation for their historical importance, beauty, and significance.
                                                Technologies, like the solutions offered by Noorisys, play a crucial role in making travel to these sites easier and more accessible.
                                                However, it requires more than just technology. It requires efforts and cooperation between different organisations such as governments, international organizations, and NGOs. It also requires community engagement and public awareness about the importance of these treasures.
                                                Hence, we should celebrate World Heritage Day not just for a day, but every day, by taking action to safeguard these precious treasures and contribute to their preservation.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default WorldHeritageDay;