import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import { FaApple, FaGlobe, FaGooglePlay } from "react-icons/fa";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// const CommonMasonry = React.lazy(()=>import ('./CommonMasonry'))
// const PortfolioList = [
//     {
//         image: 'online-shop-1',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'online-shop-2',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'online-shop-3',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },


// ]
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
// const technologies = [

//     { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
//     { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
//     { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
//     { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

// ]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #8ab2ff 0%, #4636fc 100%)', link: '/online-grocery', img: 'grocery.webp', h2: 'Malegaon Basket', p: 'Online Grocery' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #c37857 0%, #734444 100%)', link: '/online-food-delivery', img: 'food.webp', h2: 'Food', p: 'Online Food Delivery ' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #8ab2ff 0%, #4636fc 100%)', link: '/online-grocery', img: 'grocery.webp', h2: 'Malegaon Basket', p: 'Online Grocery' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #c37857 0%, #734444 100%)', link: '/online-food-delivery', img: 'food.webp', h2: 'Food', p: 'Online Food Delivery ' },
    // { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.png', h2: 'Biblo', p: 'Nursing Platform' },
    // { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.png', h2: 'Allodocta', p: 'Pharmacist Marketplace' },
]


const SocialShare = [
    { Social: <FaGlobe />, link: ' https://www.kapruka.com/ ' },
    { Social: <FaApple />, link: 'https://apps.apple.com/sc/app/kapruka/id1367410203 ' },
    { Social: <FaGooglePlay />, link: 'https://play.google.com/store/apps/details?id=com.kapruka.kaprukamobile&hl=en_IN&gl=US ' }
]

const userButtonNames = ['Splash Screen', 'Signup', 'Login', 'Home', 'Search Product', 'My Cart', 'Categories', 'Gift Vouchers', 'All Food', 'Food Products', 'Inside Product', 'Flowers'];
const userImageAddress = [
    '/assets/images/portfolio/online-shop/user-1.webp',
    '/assets/images/portfolio/online-shop/user-2.webp',
    '/assets/images/portfolio/online-shop/user-3.webp',
    '/assets/images/portfolio/online-shop/user-4.webp',
    '/assets/images/portfolio/online-shop/user-5.webp',
    '/assets/images/portfolio/online-shop/user-6.webp',
    '/assets/images/portfolio/online-shop/user-7.webp',
    '/assets/images/portfolio/online-shop/user-8.webp',
    '/assets/images/portfolio/online-shop/user-9.webp',
    '/assets/images/portfolio/online-shop/user-10.webp',
    '/assets/images/portfolio/online-shop/user-11.webp',
    '/assets/images/portfolio/online-shop/user-13.webp',
    '/assets/images/portfolio/online-shop/user-12.webp',
];

class OnlineShopPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Online Shop || Noorisys Technologies Pvt Ltd' pageUrl='/online-shop' metaTitle="Online Shop" metaImage="/assets/images/portfolio/dp-portfolio-14.jpg"
                    metaDescription="Have a look at our eCommerce mobile application and website. Users can browse a wide range of products from various categories, view product details, add items to cart and make purchases with just a few simple clicks." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Online Shop"
                    customColor="custom-online-shop-color"
                    dataBlackOverlay={7}
                    bgImage="bg_image--55"
                    p="Web / Android / iOS Application"
                    socialShare={SocialShare}
                    pb="pb--120"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>WEB / Android / iOS Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>eCommerce</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>Php, Android, Java, iOS, Swift</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title custom-online-shop-color">Online shop</h3>
                                        <p className="justifypara">Sending gifts to your loved ones is only a few swipes away, order from a wide range of categories ranging from Cake shop, Chocolates, Electronics, Flower Shop,Food, Restaurants, Fruits, Veg baskets,etc</p>
                                        <p className="justifypara">The system organize the products record and the other information about the customers. The project shows the product category and then product details. From the product details, the product can be added to cart and can be bought. The system can be used by multiple users and may grow as time passes. Necessary measures has taken to make the system as fast as possible.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#233e99'} />Clean navigational menu with an In-Site Search and product categories</li>
                                            <li className="justifypara"><FiCheck color={'#233e99'} />Detailed product descriptions</li>
                                            <li className="justifypara"><FiCheck color={'#233e99'} />Good quality product photos with image zoom and thumbnails</li>
                                            <li className="justifypara"><FiCheck color={'#233e99'} />Customer rating</li>
                                            <li className="justifypara"><FiCheck color={'#233e99'} />Engaging and informative About Us page with contact details</li>
                                            <li className="justifypara"><FiCheck color={'#233e99'} />FAQ </li>
                                            <li className="justifypara"><FiCheck color={'#233e99'} />Mobile responsiveness</li>
                                            <li className="justifypara"><FiCheck color={'#233e99'} />Various payment methods</li>
                                            <li className="justifypara"><FiCheck color={'#233e99'} />Tracking option for orders</li>
                                            <li className="justifypara"><FiCheck color={'#233e99'} />Multi-Language</li>
                                            <li className="justifypara"><FiCheck color={'#233e99'} />Greeting Message option on product</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-online-shop'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 pt--30 pb--20">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='Users can buy and track a variety of products online.'
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt='online shop user'
                                    color='online-shop'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-online-shop'} paddingTop={'pt--30'} paddingBottom={`pb--30`} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-online-shop-color' title={'Similar Apps'} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-online-shop' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default OnlineShopPortfolioDetails;
