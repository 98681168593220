import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiCheck } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Hajj and Umrah',
        description: 'Get the insights of our enriched experience in Hajj and Umrah sector. The Hajj and Umrah sector is a multibillion industry and we are connecting millions of travelers via Online Travel Agencies delivering memorable experiences. We have added a variety of transportation options, incredible places to stay – from homes to hotels, and much more. Our expertise in GDS integration aided by international payment gateways helps online travel agencies reach a global audience and grow their businesses with our techie solutions..'
    },
    {
        icon: <FiLayers />,
        title: 'Hotel Booking',
        description: 'By investing in technology that takes the friction out of travel, Our hotel management system enables properties around the world to reach a global audience and grow their businesses. The key advantage of using a booking engine for hotels is that it allows them to capture new reservations without paying fees from third-party distribution networks such as OTAs, GDSs, travel agents, or online marketplaces. Guests can book online at any time and from any place, and if a payment gateway is integrated, their credit cards will be charged immediately.'
    },
    {
        icon: <FiUsers />,
        title: 'Ticket Booking',
        description: 'Tourism industry is shifting towards a more service oriented architecture, ticketing and booking APIs have taken the industry to the next level from the traditional booking process to one of the most sophisticated OTA processes, thus increasing its efficiency and customer service. Our reliable and robust API solution helps you increase revenue while automating processing and reducing the time required completing your ticket booking with airlines. Our cost effective solution will no longer let you lock into expensive applications.'
    },

]


class TourismService extends Component {
    render() {
        const { column } = this.props;
        const ServiceContent = ServiceList.slice(0, this.props.item);

        return (
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map((val, i) => (
                        <div className={`${column}`} key={i}>
                            <a >
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p className="justifypara">{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>

                <div className="row">

                    <div className={`${column}`}>
                        <a>
                            <div className="service service__style--2">
                                <div className="content">
                                    <h4 className="title">Why us in this Segment</h4>
                                    <ul className="list-style--7">
                                        <li><FiCheck />Maqam, Galileo, Amadeus  Integration Expert.</li>
                                        <li><FiCheck />Saudi Govt. approved OTAs are working with us.</li>
                                        <li><FiCheck />In depth Market Knowledge.</li>
                                        <li><FiCheck />We Know how this industry works.</li>
                                        <li><FiCheck />Aware of the Government Policies.</li>
                                        <li><FiCheck />ISO 27001 Certified Organization.</li>
                                        <li><FiCheck />Succeeded in GDPR Assessment.</li>
                                        <li><FiCheck />Equipped with Latest Technology.</li>
                                        <li><FiCheck />We are among the best software development companies.</li>
                                        <li><FiCheck />Unmatched designing experience.</li>
                                    </ul>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className={`${column}`}>
                        <a>
                            <div className="service service__style--2">
                                <div className="content">
                                    <h4 className="title">Explore our expertise</h4>
                                    <ul className="list-style--7">
                                        <li><FiCheck />Webbeds and Expedia Integration Expert.</li>
                                        <li><FiCheck />Go direct, skip the middleman.</li>
                                        <li><FiCheck />White label travel solution.</li>
                                        <li><FiCheck />Integrate APIs with any travel portal.</li>
                                        <li><FiCheck />Global experience in travel technology.</li>
                                        <li><FiCheck />World is moving towards a fully digital future.</li>
                                        <li><FiCheck />Broad set of solution ready for you.</li>
                                        <li><FiCheck />No language barrier.</li>
                                        <li><FiCheck />Continually optimized product solution.</li>

                                    </ul>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className={`${column}`}>
                        <a>
                            <div className="service service__style--2">
                                <div className="content">
                                    <h4 className="title">Our Tech Features</h4>
                                    <ul className="list-style--7">
                                        <li><FiCheck />More than 11 years of experience.</li>
                                        <li><FiCheck />Providing services to DMCs, flight booking system.</li>
                                        <li><FiCheck />Complete solution with inventory and content.</li>
                                        <li><FiCheck />Easy setup and error free online reservation facility.</li>
                                        <li><FiCheck />Search, Book, Confirm - Through a single API integration.</li>

                                        <li><FiCheck />We are committed to delivering the best at the best rates.</li>
                                        <li><FiCheck />Diverse experience</li>
                                        <li><FiCheck />Middle East, North America, Africa and Europe.</li>

                                        {/* <li><FiCheck />Continually optimized product solution.</li> */}

                                    </ul>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}
export default TourismService;
