import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'PHP', p: 'User Panel', img: 'php.png' },
];

const userButtonNames = ['Home', 'Result', 'Search Result', 'Property Detail', 'Agents', 'Add Property Listing'];
const userImageAddress = [
    '/assets/images/portfolio/real-state/user-01.webp',
    '/assets/images/portfolio/real-state/user-02.webp',
    '/assets/images/portfolio/real-state/user-03.webp',
    '/assets/images/portfolio/real-state/user-04.webp',
    '/assets/images/portfolio/real-state/user-05.webp',
    '/assets/images/portfolio/real-state/user-06.webp',
];

class RealStatePortfolio extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Real Estate Portal' pageUrl='/digital-business-card' metaTitle="The NFC Card project aims to develop a smart visiting card using Near Field Communication (NFC) technology. This card will allow users to easily share their contact details with others by tapping the card on a compatible mobile device." />
                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Real Estate Portal"
                    customColor="theme-gradient"
                    dataBlackOverlay={5}
                    bgImage="bg_image--122"
                    p="Web Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-real-state-portfolio">Real Estate Portal</h3>
                                        <p className="justifypara">This is a real estate website that empowers users to find their dream homes or investment properties from anywhere in the world. With a sleek and modern interface, it invites users to browse through a diverse collection of listings spanning homes, commercial spaces, and trending properties. Users can seamlessly search by location, price, and other criteria to pinpoint their ideal options and discover exactly what they're seeking. Each listing presents detailed information, and captivating photos, granting users a comprehensive understanding of each property before stepping foot inside.</p>
                                        <h3 className="custom-real-state-portfolio">Features</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#080e41" />Global Listings: Explore homes, commercial spaces, and trending properties from diverse locations.</li>
                                            <li><FiCheck color="#080e41" />Seamless Search: Refine your options by location, price, and other criteria to discover your ideal match.</li>
                                            <li><FiCheck color="#080e41" />Immersive Property Previews: Gain a comprehensive understanding with detailed information and captivating photos.</li>
                                            <li><FiCheck color="#080e41" />Quick Listing of Properties</li>
                                            <li><FiCheck color="#080e41" />Add to Favourites</li>
                                            <li><FiCheck color="#080e41" />Responsive Design</li>
                                            <li><FiCheck color="#080e41" />Multilingual Support</li>
                                            <li><FiCheck color="#080e41" />RTL Support</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-real-state'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-real-state-portfolio' items={technologies} />
                        </React.Suspense>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-real-state'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 pb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='User Website '
                                    p={`Users can explore a world of properties on PrimeEstate - from cozy homes to sleek commercial spaces, all just a click away. `}
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt="real state"
                                    color='real-state'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-real-state'} paddingTop={'pt--30'} paddingBottom={'pb--30'} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-real-state-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-real-state' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default RealStatePortfolio;
