import React, { Component, useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
// import PortfolioMasonry from './PortfolioMasonry';
import { FiCheck } from "react-icons/fi";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// import CourierPortfolioMasonry from "./CourierMasonry";
// import HospitalMasonry from "./HospitalMasonry";
// import HealthcareMasonry from "./HealthcareMasonry";
// import HotelMasonry from "./HotelMasonry";
// import MultingualPregnancyMasonry from "./MultingualPregnancyMasonry";
// import TabExample from "../component/Verbos/Tabexample";
// import MultilingualAdminDemo from "./MultilingualAdminDemo";
// import Delivrable from "../component/Verbos/Deleverable";
// import PlatformUsed from "../component/Verbos/PlatformUsed";
// import SliderPortfolio from "../component/Verbos/SliderPortfolio";
// import VerbosSimilarApps from "../component/Verbos/VerbosSimilarApps";
// import FooterType from "../component/Verbos/FooterType";
// import Download1 from "../component/Verbos/Download1";

// const SocialShare = [
//     { Social: <FaGlobe />, link: 'https://janamapp.co.uk/ ' }
// ]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
]
const userAppButtonNames = ['Splash Screen', 'Home', 'Store List', 'My Requested Events', 'Notification', 'Profile'];
const userImageAddress = [
    '/assets/images/portfolio/event-management-platform/user-app/1.webp',
    '/assets/images/portfolio/event-management-platform/user-app/7.webp',
    '/assets/images/portfolio/event-management-platform/user-app/10.webp',
    '/assets/images/portfolio/event-management-platform/user-app/12.webp',
    '/assets/images/portfolio/event-management-platform/user-app/15.webp',
    '/assets/images/portfolio/event-management-platform/user-app/23.webp',
];
const providerWebButtonNames = ['Provider Registration', 'Login', 'Forgot Password', 'Reset Password', 'Dashboard', 'My Events', 'View Event Request', 'Send Proposal', 'Add New Event Expert', 'Edit Services', 'Profile Settings', 'Company Profile Settings', 'Change Password']
const providerImaageAddress = [
    '/assets/images/portfolio/event-management-platform/provider-panel/provider-1.webp',
    '/assets/images/portfolio/event-management-platform/provider-panel/provider-2.webp',
    '/assets/images/portfolio/event-management-platform/provider-panel/provider-3.webp',
    '/assets/images/portfolio/event-management-platform/provider-panel/provider-4.webp',
    '/assets/images/portfolio/event-management-platform/provider-panel/provider-5.webp',
    '/assets/images/portfolio/event-management-platform/provider-panel/provider-6.webp',
    '/assets/images/portfolio/event-management-platform/provider-panel/provider-7.webp',
    '/assets/images/portfolio/event-management-platform/provider-panel/provider-8.webp',
    '/assets/images/portfolio/event-management-platform/provider-panel/provider-9.webp',
    '/assets/images/portfolio/event-management-platform/provider-panel/provider-10.webp',
    '/assets/images/portfolio/event-management-platform/provider-panel/provider-11.webp',
    '/assets/images/portfolio/event-management-platform/provider-panel/provider-12.webp',
    '/assets/images/portfolio/event-management-platform/provider-panel/provider-13.webp',
];

const adminAppButtonNames = ['Login', 'Dashboard', 'Categories', 'Add Category', 'Edit Category', 'Users', 'Users Details', 'Providers', 'Provider Details', 'Manage Stores', 'Event Requests', 'View Event Proposals'];
const adminImaageAddress = [
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-1.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-4.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-5.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-8.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-9.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-10.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-11.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-12.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-14.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-16.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-22.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-23.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-7.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-15.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-6.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-13.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-17.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-18.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-19.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-20.webp',
    '/assets/images/portfolio/event-management-platform/admin-panel/admin-21.webp',

];
function usePageViewTracking(location) {
    useEffect(() => {
        window.gtag("config", "AW-16650476770", {
            page_title: location.pathname,
            page_path: location.pathname,
        });
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', location.pathname]);
        _hsq.push(['trackPageView']);
    }, [location]);
}
function EventManegmentPlatformPortfolio(props) {
    usePageViewTracking(props.location);
    return (
        <React.Fragment>
            <PageHelmet pageTitle='Event Management Platform' pageUrl='/event-management-platform' metaTitle="Event Management Platform" metaImage="/assets/images/portfolio/dp-portfolio-09.jpg"
                metaDescription=" connects customers who need services for their events (such as birthdays, weddings, baptisms, gender reveal, baby showers, Aid or corporate events) with service providers" />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <CommonBreadcrumb
                title="Event Management Platform"
                customColor="theme-gradient"
                dataBlackOverlay={7}
                bgImage="bg_image--144"
                p="Android / iOS / Web Application"
                pb='pb--120'
            />
            {/* End Breadcrump Area */}

            {/* Start Portfolio Details */}

            <div className="rn-portfolio-details ptb--40 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-details">
                                <div className="inner">
                                    <h3 className="rn-page-title custom-event-expert-color">Event Management Platform</h3>
                                    <p className="justifypara">This system connects customers who need services for their events (such as birthdays, weddings, baptisms, gender reveal, baby showers, Aid or corporate events) with service providers who may offer these services. Customers can publish​ Advertisements(Event Request) showing their needs and service providers can browse these offers and submit proposals. The customer can browse the professional's website to see the photos and buffets presented and make their choice or get inspired.</p>
                                    <p className="justifypara"></p>
                                    <h4 className="title">Customer Mobile App Functionalities :</h4>
                                    <ul className="list-style--1">
                                        <li><FiCheck style={{ color: '#808A6D' }} />Registration and Login</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />Profile Management</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />Settings</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />Publish Event Requests</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />My Event Requests</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />View Event Buffets</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />View Stores</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />Notifications</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />Delete Account</li>
                                    </ul>

                                    <h4 className="title">Service Provider Web Panel Functionalities :</h4>
                                    <ul className="list-style--1">
                                        <li><FiCheck style={{ color: '#808A6D' }} />Supplier Registration</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />Login</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />Profile Management</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />Settings</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />View Event Requests</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />Manage Buffets</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />Submit Proposals</li>
                                    </ul>
                                    <h4 className="title">Administration Panel Functionality :</h4>
                                    <ul className="list-style--1">
                                        <li><FiCheck style={{ color: '#808A6D' }} />Dashboard Overview</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />Manage Customers</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />Manage Service Providers</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />Manage Stores</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />Manage Event Categories</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />View Event Requests</li>
                                        <li><FiCheck style={{ color: '#808A6D' }} />Multilingual Support </li>
                                    </ul>
                                    {/* <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Details */}

            {/* <FooterType /> */}

            {/* Start portfolio Area  */}
            <div className="rn-portfolio-area bg_color--1 ptb--30 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center service-style--3 mb--30">
                                {/* <p></p> */}
                                {/* <h3 className="title">Screenshots</h3> */}
                                {/* <p></p> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="ptb--60">
                <SliderPortfolio />
                </div> */}

                {/* <Delivrable /> */}

                {/* <PlatformUsed /> */}
                <div className="">
                    {/* <FooterType /> */}
                </div>


                <div className="wrapper bg_color--3">
                    <div className="container" >
                        <div className="row ">
                            {/* <MultingualPregnancyMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" /> */}
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Customers App'
                                    p='Customers can find inspiration to create buffets or contact professionals​ of events, manage their profiles and receive proposals from service providers.'
                                    buttonNames={userAppButtonNames}
                                    image={userImageAddress}
                                    alt='event expert customer'
                                    color='event-expert-app'
                                />
                                <CommonProductWeb
                                    h3='Service providers'
                                    p='Service providers can save their services view customer needs related to events and submit proposals​ buffet.'
                                    buttonNames={providerWebButtonNames}
                                    image={providerImaageAddress}
                                    alt='event expert provider'
                                    color='event-expert'
                                />

                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='Web app for administrators to manage the overall operation and functionality of the application.'
                                    buttonNames={adminAppButtonNames}
                                    image={adminImaageAddress}
                                    alt='mommycare admin'
                                    color='event-expert'
                                />
                                {/* <MultilingualAdminDemo /> */}
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="ptb--60">

                    </div>

                </div>
                <React.Suspense fallback={<div>loading...</div>} >
                    <VerbosSimilarApps color='custom-event-expert-color' items={similarapps} title={'Apps'} slidestoshow={[3, 3]} />
                </React.Suspense>
                <CommonPortfolioButtons class='btn-water-donation' text='View All Our Projects' />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <VerbosSimilarApps /> */}
                            <div className="custom_download_app">
                                {/* <Download1 /> */}
                            </div>
                            <div className="section-title text-center service-style--3 mb--10">
                                {/* <h3 className="title">Screenshots</h3> */}

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* End portfolio Area  */}



            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />


        </React.Fragment>
    )
}
export default EventManegmentPlatformPortfolio;
