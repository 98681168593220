import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'Code Igniter', p: 'Admin Panel', img: 'ci4.png' },
    { h5: 'PHP', p: 'Backend Development', img: 'php.png' },
    { h5: 'Angular', p: 'User Panel', img: 'angular.png' },
]

const adminButtonNames = ['Login', 'Dashboard', 'Add Worksite', 'Add Schedule', 'Add Salary Slip', 'Add Quality Control', 'Add Cleaner', 'Manage Worksites', 'Manage Messages', 'Manage Cleaner Schedule', 'Manage Cleaner', 'Send Message', 'Latest Quality Control', 'Feedback', 'Change Password'];
const adminImageAddress = [
    '/assets/images/portfolio/home-cleaning-services/admin/admin-01.webp',
    '/assets/images/portfolio/home-cleaning-services/admin/admin-02.webp',
    '/assets/images/portfolio/home-cleaning-services/admin/admin-03.webp',
    '/assets/images/portfolio/home-cleaning-services/admin/admin-04.webp',
    '/assets/images/portfolio/home-cleaning-services/admin/admin-05.webp',
    '/assets/images/portfolio/home-cleaning-services/admin/admin-06.webp',
    '/assets/images/portfolio/home-cleaning-services/admin/admin-07.webp',
    '/assets/images/portfolio/home-cleaning-services/admin/admin-08.webp',
    '/assets/images/portfolio/home-cleaning-services/admin/admin-09.webp',
    '/assets/images/portfolio/home-cleaning-services/admin/admin-10.webp',
    '/assets/images/portfolio/home-cleaning-services/admin/admin-11.webp',
    '/assets/images/portfolio/home-cleaning-services/admin/admin-12.webp',
    '/assets/images/portfolio/home-cleaning-services/admin/admin-13.webp',
    '/assets/images/portfolio/home-cleaning-services/admin/admin-14.webp',
    '/assets/images/portfolio/home-cleaning-services/admin/admin-15.webp',
];

const cleanerButtonNames = ['Login', 'Dashboard', 'Work Sites', 'Week Schedule', 'Day Schedule', 'Quality Control', 'Translation', 'Message History', 'Residence Wise Form', 'Send Document', 'Send Photo', 'Personal Space'];
const cleanerImageAddress = [
    '/assets/images/portfolio/home-cleaning-services/cleaner/cleaner-01.webp',
    '/assets/images/portfolio/home-cleaning-services/cleaner/cleaner-02.webp',
    '/assets/images/portfolio/home-cleaning-services/cleaner/cleaner-03.webp',
    '/assets/images/portfolio/home-cleaning-services/cleaner/cleaner-04.webp',
    '/assets/images/portfolio/home-cleaning-services/cleaner/cleaner-07.webp',
    '/assets/images/portfolio/home-cleaning-services/cleaner/cleaner-05.webp',
    '/assets/images/portfolio/home-cleaning-services/cleaner/cleaner-06.webp',
    '/assets/images/portfolio/home-cleaning-services/cleaner/cleaner-08.webp',
    '/assets/images/portfolio/home-cleaning-services/cleaner/cleaner-09.webp',
    '/assets/images/portfolio/home-cleaning-services/cleaner/cleaner-10.webp',
    '/assets/images/portfolio/home-cleaning-services/cleaner/cleaner-11.webp',
    '/assets/images/portfolio/home-cleaning-services/cleaner/cleaner-12.webp',
];

class HomeCleaningPortfolio extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Home Cleaning Services' pageUrl='/home-cleaning-services' metaTitle="This Platform is a comprehensive online application facilitating home cleaning services, empowering cleaners with task assignments and providing admins with robust management tools." />
                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Home Cleaning Services"
                    customColor="custom-home-cleaning-portfolio"
                    dataBlackOverlay={5}
                    bgImage="bg_image--117"
                    p="Web Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-home-cleaning-portfolio">Home Cleaning Services</h3>
                                        The Home Cleaning Services Platform is an online application designed to streamline home cleaning services. It empowers cleaners by assigning tasks and provides administrators with advanced management tools for efficient operations. The platform ensures a smooth and organized workflow for both cleaners and admins and enhances the overall efficiency of home cleaning services.
                                        <h3 className="custom-home-cleaning-portfolio mt-2">Features</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#A48A43" />View Personal Space</li>
                                            <li><FiCheck color="#A48A43" />View Apartment Incharge</li>
                                            <li><FiCheck color="#A48A43" />View Urgent Info</li>
                                            <li><FiCheck color="#A48A43" />View Latest News</li>
                                            <li><FiCheck color="#A48A43" />Send Documents</li>
                                            <li><FiCheck color="#A48A43" />View Salary Slip</li>
                                            <li><FiCheck color="#A48A43" />Manage Cleaners</li>
                                            <li><FiCheck color="#A48A43" />Manage Cleaner Schedule</li>
                                            <li><FiCheck color="#A48A43" />Manage Salary Slips</li>
                                            <li><FiCheck color="#A48A43" />Manage Messages</li>
                                            <li><FiCheck color="#A48A43" />Manage Residences</li>
                                            <li><FiCheck color="#A48A43" />Manage Apartments</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-home-cleaning'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-home-cleaning-portfolio' items={technologies} />
                        </React.Suspense>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-home-cleaning'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 pb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Cleaner'
                                    p={`Cleaners utilize the platform for efficient task management, accessing schedules, submitting detailed cleaning forms, reviewing quality control history, and engaging in seamless communication with the admin.`}
                                    buttonNames={cleanerButtonNames}
                                    image={cleanerImageAddress}
                                    alt="home service cleaner"
                                    color='home-cleaning'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-home-cleaning'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin'
                                    p={` Admins efficiently manage cleaners, schedules, salary slips, messages, residences, and apartments on the Platform, ensuring smooth operation and effective communication.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt="collection admin"
                                    color='home-cleaning'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-home-cleaning'} paddingTop={'pt--30'} paddingBottom={'pb--30'} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-home-cleaning-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-home-cleaning' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default HomeCleaningPortfolio;
