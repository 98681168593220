import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
const BlogList = React.lazy(() => import("./BlogList"));

class Blog extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Blogs || Noorisys Technologies Pvt Ltd' pageUrl='/blogs' metaTitle=" Latest Trends in Industry" metaImage="/assets/images/bg/bg-image-1.webp"
                    metaDescription="Stay informed with the latest trends in the industry by reading our blogs. Noorisys provides expert insights and knowledge to help you stay ahead of the curve in the ever-evolving digital landscape." />

                <Header />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Blog List'} />
                {/* End Breadcrump Area */}


                {/* Start Blog Area */}
                <div className="rn-blog-area pt--120 pb--60 bg_color--1">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <BlogList />
                        </React.Suspense>
                    </div>
                </div>
                {/* End Blog Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Blog;
