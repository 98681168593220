import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaQuoteButton from "../component/GetaQuoteButton";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const GetaquotePopup = React.lazy(() => import("../component/header/getaquotePopup"));

// const technologies = [

//     { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
//     { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
//     { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
//     { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

// ]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #8ab2ff 0%, #4636fc 100%)', link: '/healthcare-insurance-marketplace', img: 'healthcare.webp', h2: 'DocFinder', p: 'Healthcare Insurance Marketplace' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/primary-care-management-system', img: 'federate.webp', h2: 'Federate', p: 'Primry Care Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]
// const SocialShare = [
//     { Social: <FaGlobe />, link: ' http://www.nmdfc.org/ ' },
//     { Social: <FaGooglePlay />, link: 'https://play.google.com/store/apps/details?id=com.noorisys.nmdfc' }
// ]

const userButtonNames = ['Splash Screen', 'Select Role', 'Add Devices', 'Add Sleep Monitor', 'Device Type', 'Profile', 'List Of Seniors', 'Senior', 'Medications', 'Alerts Configuration', 'Insights Alerts', 'Insights Report Weekly', 'Daily Report', 'Monitoring', 'Summary'];
const userImageAddress = [
    '/assets/images/portfolio/senior-citizen-care/senior-app-1.webp',
    '/assets/images/portfolio/senior-citizen-care/senior-app-2.webp',
    '/assets/images/portfolio/senior-citizen-care/senior-app-3.webp',
    '/assets/images/portfolio/senior-citizen-care/senior-app-4.webp',
    '/assets/images/portfolio/senior-citizen-care/senior-app-5.webp',
    '/assets/images/portfolio/senior-citizen-care/senior-app-6.webp',
    '/assets/images/portfolio/senior-citizen-care/senior-app-7.webp',
    '/assets/images/portfolio/senior-citizen-care/senior-app-8.webp',
    '/assets/images/portfolio/senior-citizen-care/senior-app-9.webp',
    '/assets/images/portfolio/senior-citizen-care/senior-app-10.webp',
    '/assets/images/portfolio/senior-citizen-care/senior-app-11.webp',
    '/assets/images/portfolio/senior-citizen-care/senior-app-12.webp',
    '/assets/images/portfolio/senior-citizen-care/senior-app-13.webp',
    '/assets/images/portfolio/senior-citizen-care/senior-app-14.webp',
    '/assets/images/portfolio/senior-citizen-care/senior-app-15.webp',
];
function usePageViewTracking(location) {
    useEffect(() => {
        window.gtag("config", "AW-16650476770", {
            page_title: location.pathname,
            page_path: location.pathname,
        });
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', location.pathname]);
        _hsq.push(['trackPageView']);
    }, [location]);
}
function SeniorCarePortfolio(props) {
    const [isVisible, setIsVisible] = useState(false);
    usePageViewTracking(props.location);
    function showPopup() {
        setIsVisible(true);
        window.lintrk('track', { conversion_id: 18604321 });
    }
    function closePopup() {
        setIsVisible(false);
    }
    return (
        <React.Fragment>
            <PageHelmet pageTitle='Senior Citizen Monitoring App' pageUrl='/senior-citizen-monitoring-app' metaTitle="Senior Citizen Monitoring App" metaImage="/assets/images/portfolio/dp-portfolio-.19jpg"
                metaDescription="senior citizen monitoring app is a comprehensive health and wellness platform tailored to the unique needs of older adults." />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <CommonBreadcrumb
                title="Senior Citizen Monitoring App"
                customColor="custom-government-color"
                dataBlackOverlay={7}
                bgImage="bg_image--141"
                p="Android Application"
                pb="pb--120"
            />
            {/* End Breadcrump Area */}

            {/* Start Portfolio Details */}

            <div className="rn-portfolio-details ptb--40 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-details">
                                <div className="inner">

                                    <h3 className="rn-page-title custom-government-color">Senior Citizen Monitoring App</h3>
                                    <p className="justifypara">This senior citizen monitoring app is a comprehensive health and wellness platform tailored to the unique needs of older adults. It allows caretakers or family members to monitor multiple senior citizens through a single app by connecting various Health IoT devices.</p>

                                    <h4 className="title">Health Monitoring</h4>
                                    <ul className="list-style--1">
                                        <li><span className="font--18 textBlack">Medication Details : </span>Stores comprehensive information about medications, including dosage, frequency, and schedules.</li>
                                        <li><span className="font--18 textBlack">Vitals Tracking : </span>Tracks essential health parameters such as heart rate, blood pressure, and blood oxygen levels.</li>
                                        <li><span className="font--18 textBlack">Sleep Tracking : </span>Monitors sleep patterns, including duration, quality, and stages.</li>
                                        <li><span className="font--18 textBlack">Physical Health Advice : </span>Offers personalised recommendations for physical activity and overall wellness.</li>
                                        <li><span className="font--18 textBlack">Mental Health Assessment : </span>Monitors and evaluates mental health indicators for early detection of concerns.</li>
                                        <h4 className="title">Home Security and Wellness</h4>
                                        <li><span className="font--18 textBlack">Home Security System : </span>Supports the setup and management of security cameras to ensure safety at home.</li>
                                        <li><span className="font--18 textBlack">Sleep Aid Integration : </span>Enables integration with devices designed to promote better sleep.</li>
                                        <li><span className="font--18 textBlack">Family Member Access : </span> Provides remote access for family members to track the senior citizen’s health and security.</li>
                                        <h4 className="title">Medication Management</h4>
                                        <li><span className="font--18 textBlack">Medication Reminders : </span> Sends timely alerts for medication intake.</li>
                                        <li><span className="font--18 textBlack">Medication Details : </span>Stores comprehensive information about medications, including dosage, frequency, and schedules.</li>
                                    </ul>
                                    <p className="justifypara">This app is a vital tool to promote senior citizens' health, safety, and independence while giving peace of mind to their caregivers and family members.</p>
                                    <React.Suspense fallback={<div>loading...</div>} >
                                        <GetaquotePopup isVisible={isVisible} closePopup={closePopup} />
                                    </React.Suspense>
                                    <GetaQuoteButton showPopup={showPopup} pageName={'btn-ndmfc'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Details */}

            {/* Start portfolio Area  */}
            <div className="rn-portfolio-area bg_color--1 pt--20 pb--40">
                <div className="container">
                    <div className="row">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <ProductCommonApp
                                h3='User App'
                                p='This app allows caretakers or family members to monitor multiple senior citizens through a single app by connecting various Health IoT devices.'
                                buttonNames={userButtonNames}
                                image={userImageAddress}
                                alt='senior-care'
                                color='ndmfc'
                            />
                        </React.Suspense>
                    </div>
                </div>
                <GetaQuoteButton showPopup={showPopup} pageName={'btn-ndmfc'} paddingTop={'pt--30'} paddingBottom={`pb--30`} />
                <React.Suspense fallback={<div>loading...</div>} >
                    <VerbosSimilarApps color='custom-coursier-color' items={similarapps} title={'Apps'} slidestoshow={[3, 3]} />
                </React.Suspense>
                <CommonPortfolioButtons class='btn-ndmfc' text='View All Our Projects' />
            </div>
            {/* End portfolio Area  */}



            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />


        </React.Fragment>
    )
}
export default SeniorCarePortfolio;
