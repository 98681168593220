import React, { Component } from 'react'
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
const ProductCommonApp = React.lazy(() => import("../portfolio/ProductCommonAppdemo"))


const mobileDrButtonName = ['Choose Home', 'Home Page', 'Favorite Store', 'Store Detail', 'Favorite Item', 'My Cart', 'Track Order', 'Check Out', 'Payment Successful Page']
const mobileDrImageAddress = [
    '/assets/images/our-products/grocery/user/choosehomepage.webp',
    '/assets/images/our-products/grocery/user/homepage.webp',
    '/assets/images/our-products/grocery/user/favstore.webp',
    '/assets/images/our-products/grocery/user/storedetail.webp',
    '/assets/images/our-products/grocery/user/favitem.webp',
    '/assets/images/our-products/grocery/user/mycart.webp',
    '/assets/images/our-products/grocery/user/trackorder.webp',
    '/assets/images/our-products/grocery/user/checkout.webp',
    '/assets/images/our-products/grocery/user/sucessfulpayment.webp',
]

const mobileHosButtonName = ['Home Page', 'My Order', 'Order Request', 'Profile']
const mobileHosImageAddress = [
    '/assets/images/our-products/grocery/delivery/homescreen.webp',
    '/assets/images/our-products/grocery/delivery/myorders.webp',
    '/assets/images/our-products/grocery/delivery/orderrequest.webp',
    '/assets/images/our-products/grocery/delivery/profile.webp',
]


export default class OnlineGrocery extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Multi Vendor eCommerce with Delivery' pageUrl='/grocery' metaTitle="The grocery management panel provides a complete overview and control of all grocery-related operations" />
                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Multi Vendor eCommerce with Delivery"
                    customColor="custom-grocery-management"
                    // customColor="theme-gradient"
                    dataBlackOverlay={5}
                    bgImage="bg_image--138"
                    p="Web, App"
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="custom-grocery-management">Multi Vendor eCommerce with Delivery </h3>
                                        <p className="justifypara">
                                            Our Multi-Vendor eCommerce platform is designed to empower businesses across multiple sectors. It offers dedicated modules for span <span className='fontWeight600'> eCommerce, Grocery, Pharmacy, Food Delivery </span>, and <span className='fontWeight600'>Parcel Services </span>, providing a seamless experience for both vendors and customers. With robust multi-vendor capabilities, sellers can manage their products, orders, and deliveries efficiently, while customers enjoy a streamlined shopping experience across diverse categories. This all-in-one platform is ideal for businesses looking to expand their digital presence and deliver exceptional service across various industries.
                                        </p>
                                    </div>
                                </div>

                                {/* <div className="ptb--40">
                                    <div className="container">
                                        <React.Suspense fallback={<div>loading...</div>} >
                                            <PlatformUsed color='custom-grocery-management' items={technologies} />
                                            <div className="d-flex justify-content-center align-items-center pt--30">
                                                <button className="get-a-quote btn-hospital-management text-center" onClick={this.showPopup}>
                                                    <span>Get a quote </span>
                                                </button>
                                            </div>
                                        </React.Suspense>
                                    </div>
                                </div> */}
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="custom-grocery-management">Super admin functionalities</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#b40681" />Dashboard</li>
                                            <li><FiCheck color="#b40681" />New Sales</li>
                                            <li><FiCheck color="#b40681" />Order</li>
                                            <li><FiCheck color="#b40681" />Order refunds</li>
                                            <li><FiCheck color="#b40681" />Flash sales </li>
                                            <li><FiCheck color="#b40681" />Campaign</li>
                                            <li><FiCheck color="#b40681" />Banners</li>
                                            <li><FiCheck color="#b40681" />Other banners</li>
                                            <li><FiCheck color="#b40681" />Coupons</li>
                                            <li><FiCheck color="#b40681" />Cash back</li>
                                            <li><FiCheck color="#b40681" />Push notification</li>
                                            <li><FiCheck color="#b40681" />Categories</li>
                                            <li><FiCheck color="#b40681" />Attributes</li>
                                            <li><FiCheck color="#b40681" />Units</li>
                                            <li><FiCheck color="#b40681" />Product setup</li>
                                            <li><FiCheck color="#b40681" />New store</li>
                                            <li><FiCheck color="#b40681" />Add store</li>
                                            <li><FiCheck color="#b40681" />Store list</li>
                                            <li><FiCheck color="#b40681" />Recommended store</li>
                                            <li><FiCheck color="#b40681" />Bulk import</li>
                                            <li><FiCheck color="#b40681" />Bulk export
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="inner">
                                        <h3 className="custom-grocery-management">Vendor Functionalities</h3>
                                        <p className='justifypara'>
                                            The vendor dashboard offers a streamlined interface for managing all aspects of grocery sales. Vendors can oversee all orders and specific statuses, including scheduled, pending, accepted, processing, on the way, delivered, canceled, payment failed, refunded, and offline payments. Vendors can also participate in flash sales, manage their product inventory, and organize items into categories and subcategories, with options for bulk import and export.
                                        </p>
                                        <p className='justifypara'>
                                            Campaign management tools enable vendors to create both basic and item-specific promotions. They can also generate and manage coupons and banners to effectively market their products. The store configuration features allow vendors to customize their shop settings, manage business plans, monitor their wallet balance, and set up disbursement methods for payments.
                                        </p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#b40681" />Dashboard</li>
                                            <li><FiCheck color="#b40681" />Orders</li>
                                            <li><FiCheck color="#b40681" />Flash sales</li>
                                            <li><FiCheck color="#b40681" />Items</li>
                                            <li><FiCheck color="#b40681" />Categories </li>
                                            <li><FiCheck color="#b40681" />Campaign</li>
                                            <li><FiCheck color="#b40681" />Coupons</li>
                                            <li><FiCheck color="#b40681" />Banners</li>
                                            <li><FiCheck color="#b40681" />Store Config</li>
                                            <li><FiCheck color="#b40681" />My shop</li>
                                            <li><FiCheck color="#b40681" />My business plan</li>
                                            <li><FiCheck color="#b40681" />My wallet</li>
                                            <li><FiCheck color="#b40681" />Disbursement Method</li>
                                            <li><FiCheck color="#b40681" />Reviews</li>
                                            <li><FiCheck color="#b40681" />Chat </li>
                                            <li><FiCheck color="#b40681" />Expense report</li>
                                            <li><FiCheck color="#b40681" />Disbursement Report</li>
                                            <li><FiCheck color="#b40681" />Employee Role</li>
                                            <li><FiCheck color="#b40681" />Employees</li>
                                        </ul>
                                    </div>
                                    <div className="inner">
                                        <h3 className="custom-grocery-management">User App Functionalities</h3>
                                        <p className='justifypara'>
                                            Transform your grocery delivery venture with our comprehensive user app, featuring seamless registration, multi-language support, real-time delivery tracking, and a user-friendly interface for effortless order management, restaurant exploration and personalized profiles.
                                        </p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#b40681" />Registration and Login</li>
                                            <li><FiCheck color="#b40681" />View List of food products</li>
                                            <li><FiCheck color="#b40681" />View All Nearby Restaurants</li>
                                            <li><FiCheck color="#b40681" />Add to Cart and Order</li>
                                            <li><FiCheck color="#b40681" />Rating and Reviews </li>
                                            <li><FiCheck color="#b40681" />Add to Favorites</li>
                                            <li><FiCheck color="#b40681" />Profile Management</li>
                                            <li><FiCheck color="#b40681" />Loyalty points</li>
                                            <li><FiCheck color="#b40681" />My wallet</li>
                                            <li><FiCheck color="#b40681" />Join as delivery man</li>
                                            <li><FiCheck color="#b40681" />Open store</li>
                                            <li><FiCheck color="#b40681" />Live chat</li>
                                            <li><FiCheck color="#b40681" />Refund policy</li>
                                            <li><FiCheck color="#b40681" />Multi-language support</li>
                                        </ul>
                                    </div>
                                    <div className="inner">
                                        <h3 className="custom-grocery-management">Delivery Boy App Functionalities</h3>
                                        <p className='justifypara'>
                                            Effortlessly register and log in, seamlessly manage order requests, accept or reject orders, review order history, engage in real-time chat with users and efficiently manage your profile with our delivery boy app.
                                        </p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#b40681" />Registration and Login</li>
                                            <li><FiCheck color="#b40681" />View Order Request</li>
                                            <li><FiCheck color="#b40681" />Accept / Reject Orders</li>
                                            <li><FiCheck color="#b40681" />Order History</li>
                                            <li><FiCheck color="#b40681" />Chat with Users</li>
                                            <li><FiCheck color="#b40681" />Profile Management</li>
                                            <li><FiCheck color="#b40681" />Multi-language support</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p={``}
                                    buttonNames={mobileDrButtonName}
                                    image={mobileDrImageAddress}
                                    alt="BTK Mobile"
                                    color='grocery'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Delivery App'
                                    p={``}
                                    buttonNames={mobileHosButtonName}
                                    image={mobileHosImageAddress}
                                    alt="BTK Mobile"
                                    color='grocery'
                                />
                            </React.Suspense>
                        </div>
                    </div>

                </div>
                {/* <div className="d-flex justify-content-center align-items-center pt--30 bg_color--3 ">
                    <button className="get-a-quote btn-hospital-management text-center" onClick={this.showPopup}>
                        <span>Get a quote </span>
                    </button>
                </div> */}

                <CommonPortfolioButtons class='btn-hospital-management-cards' text='View All Our Products' link='/our-products' padding='pb--20' />
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
