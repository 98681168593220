import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// const CommonMasonry = React.lazy(() => import("./CommonMasonry"))

// const PortfolioList = [
//     {
//         image: 'delivery-management-1',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'delivery-management-2',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
// ]
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
// const technologies = [

//     { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
//     { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
//     { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
//     { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

// ]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #8ab2ff 0%, #4636fc 100%)', link: '/online-grocery', img: 'grocery.webp', h2: 'Malegaon Basket', p: 'Online Grocery' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #c37857 0%, #734444 100%)', link: '/online-food-delivery', img: 'food.webp', h2: 'Food', p: 'Online Food Delivery ' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #8ab2ff 0%, #4636fc 100%)', link: '/online-grocery', img: 'grocery.webp', h2: 'Malegaon Basket', p: 'Online Grocery' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    // { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.png', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

const userButtonNames = ['Splash Screen', 'Side Menu', 'Product', 'Tracking'];
const userImageAddress = [
    '/assets/images/portfolio/delivery-management/user-1.webp',
    '/assets/images/portfolio/delivery-management/user-2.webp',
    '/assets/images/portfolio/delivery-management/user-3.webp',
    '/assets/images/portfolio/delivery-management/user-4.webp',
];

class DeliveryManagmentPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Delivery management || Noorisys Technologies Pvt Ltd' pageUrl='/delivery-management' metaTitle="Delivery Management System" metaImage="/assets/images/portfolio/dp-portfolio-18.jpg"
                    metaDescription="Have a look at our Delivery Management System, an innovative solution that streamlines the way businesses handle their delivery operations. Users can find and purchase physical items, track orders, share location and more." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Delivery Management System"
                    customColor="custom-delivery-management-color"
                    dataBlackOverlay={7}
                    bgImage="bg_image--60"
                    p="Web / Android / iOS Application"
                />

                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Web / Android / iOS Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>eCommerce</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>Php, Android, Java, iOS, Swift</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title custom-delivery-management-color">Delivery Management System</h3>
                                        <p className="justifypara">Delivery management system is unique delivery fulfillment arm with the promise of offering the cheapest and the quickest service in door-to-door delivery. This system intends to revitalize the courier network and to be the chosen partner for various leading brands, local industrial experts and rising local and global businesses. Each delivery is a mission to build a long-term trusted relationship with stakeholders. This encouraged to create innovative systems and time definite delivery schedules</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color={'#5DB35C'} />Find and purchase physical items</li>
                                            <li><FiCheck color={'#5DB35C'} />Manage your account information</li>
                                            <li><FiCheck color={'#5DB35C'} />Order tracking</li>
                                            <li><FiCheck color={'#5DB35C'} />Location Sharing</li>
                                            <li><FiCheck color={'#5DB35C'} />Add items to cart</li>
                                            <li><FiCheck color={'#5DB35C'} />View and remove items in cart</li>
                                            <li><FiCheck color={'#5DB35C'} />View items by category</li>
                                            <li><FiCheck color={'#5DB35C'} />Responsive and easy to view item list</li>
                                            <li><FiCheck color={'#5DB35C'} />More details page for every items</li>
                                            <li><FiCheck color={'#5DB35C'} />Easy Registration process to become a hub</li>
                                            <li><FiCheck color={'#5DB35C'} />Easy Registration process for customer</li>
                                            <li><FiCheck color={'#5DB35C'} />And many more...</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-online-shop'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='Users can track and see the status of their orders online.'
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt='delivery management'
                                    color='online-shop'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-online-shop'} paddingTop={'pt--30'} paddingBottom={`pb--30`} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-online-shop-color' title={'Similar Apps'} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-online-shop' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default DeliveryManagmentPortfolioDetails;
