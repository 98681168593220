import React, { Component } from "react";
import { FiCheck } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PageHelmet from "../common/Helmet";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import WebDevelopmentContactPage from "./Web_Development_Contact_Page";
import CommonBreadcrumb from "../common/CommonBreadcrumb";

class Career_Mobile_App extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='career mobile app development ' pageUrl='/mobile-app-career' metaTitle="Get in touch with Noorisys" metaImage="/assets/images/bg/bg-image-17.jpg"
                    metaDescription="Get in touch with Noorisys - the leading mobile and web development company in India. We are here to answer any of your questions and help you bring your digital vision to life. Contact us today!" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb title='Join Our Team' dataBlackOverlay={6} bgImage='bg_image--92' />
                {/* End Breadcrump Area */}

                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area pt--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        <p>We are seeking a talented Flutter developer to join our dynamic team. The ideal candidate will have a passion for coding and a proven track record of building high-quality, scalable mobile applications.</p>
                                        <h4 className="title">Key Responsibilities:</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Design, develop, and maintain Flutter-based mobile applications</li>
                                            <li><FiCheck />Write clean, efficient, and well-documented code</li>
                                            <li><FiCheck />Debug and troubleshoot existing code</li>
                                            <li><FiCheck />Collaborate with cross-functional teams to identify and solve complex technical problems</li>
                                            <li><FiCheck />Stay up-to-date with the latest developments in Flutter and related technologies</li>
                                        </ul><br />
                                        <h4 className="title">Qualifications:</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Bachelor's degree in Computer Science or related field</li>
                                            <li><FiCheck />Strong proficiency in Dart and Flutter framework</li>
                                            <li><FiCheck />Experience with REST APIs and mobile app architecture</li>
                                            <li><FiCheck />Familiarity with front-end technologies such as HTML, CSS, and JavaScript</li>
                                            <li><FiCheck />Excellent problem-solving and critical thinking skills</li>
                                            <li><FiCheck />Strong written and verbal communication skills</li>
                                            <li><FiCheck />Ability to work independently and as part of a team</li>
                                        </ul>
                                        <h4 className="title">Technical Requirements:</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Experience with Flutter widgets, animations, and custom UI components</li>
                                            <li><FiCheck />Knowledge of Agile software development methodologies</li>
                                            <li><FiCheck />Familiarity with mobile app deployment on App Store and Google Play Store</li>
                                            <li><FiCheck />Experience with Git version control</li>
                                        </ul>
                                        <p>If you're a passionate and motivated Flutter developer, submit your CV now and fill out the form.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}


                </main>

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--60 bg_color--1">
                    <WebDevelopmentContactPage vacancy={'Mobile App Development'} application='mobile_app' />
                </div>
                {/* End Contact Page Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Career_Mobile_App;