import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";


class Shopify extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Shopify || Noorisys Technologies Pvt Ltd' pageUrl='/shopify' metaTitle="Shopify" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Create an SEO-optimized and easily customizable eCommerce website with an attractive dashboard using Shopify and the expertise of Noorisys." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title='Shopify'
                    dataBlackOverlay={5}
                    bgImage='bg_image--25'
                    buttonTitle={'Hire Now'}
                    buttonLink={'/contact-us'}
                    p='Build your own eCommerce website with us that is highly SEO optimized, easily customizable with an attractive dashboard.'
                />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        {/* <h4 className="tilte">One Full</h4> */}
                                        <p className="justifypara">Shopify is a very useful and fast growing e-commerce platform, introduced by Tobias Lütke and Scott Lake. It provides merchandisers’ flexibility in managing the appearance and content of a site through its highly modular architecture.</p>
                                        <p className="justifypara">It shows scalable performance and is normally appreciated by users due to multiple control options.</p>
                                        <h4 className="title">HOW IT CAN BENEFIT YOUR BUSINESS?</h4>
                                        <p className="justifypara">Establishing and running an e-commerce store has become easier than before. Shopify solutions from Noorisys Technologies can help you establish your online store and run it within a limited time frame. We don't only focus on the integration of payment gateways to your website but also adding essential advantages.</p>
                                        <p className="justifypara">Our developers can help you automate your business process with usable features of this open source platform. From facilitating your product display to getting payments, you can have everything.</p>
                                        <h4 className="title">You can also realize the following benefits:</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck />Shopify has an effective and intuitive interface to help create and manage your product catalog.</li>
                                            <li className="justifypara"><FiCheck />It offers you to easily manage your shipments, orders and integrate various payment methods.</li>
                                            <li className="justifypara"><FiCheck />Shopify gives customers the choice to create their own accounts, keep track of their shopping history, and contact the site owner through online forms.</li>
                                            <li className="justifypara"><FiCheck />With Shopify you can get access to various promotional tools such as organizing price promotions and using coupon codes.</li>
                                            <li className="justifypara"><FiCheck />Hundreds of additions are available for installing other functionalities later.</li>
                                            <li className="justifypara"><FiCheck />Shopify framework has many latest SEO tools which can be seamlessly integrated into your google analytics account for studying custom behavior patterns of your website and optimizing it for better ranking results.</li>
                                        </ul>


                                        <h4 className="title">How Shopify IS EXCELLENT THAN OTHER FRAMEWORKS?</h4>
                                        <p className="justifypara">There are many options for eCommerce CMS such as using ecommerce plugins with WordPress or Drupal eCommerce modules.</p>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck />Shopify is a robust platform (but expensive) meant for developing highly flexible online stores.</li>
                                            <li className="justifypara"><FiCheck />It is supported by a large user community who often share ideas and give suggestions for improvising features.</li>
                                            <li className="justifypara"><FiCheck />There is clarity in the operation and management of Shopify websites.</li>
                                            <li className="justifypara"><FiCheck />With its effective SEO tools, Shopify helps you get quality traffic and convert them into sales.</li>
                                            <li className="justifypara"><FiCheck />Shopify supports various payment options and international currencies in order to give users a perfect shopping experience.</li>
                                        </ul>
                                        <h4 className="title">OUR SERVICES</h4>
                                        <p className="justifypara">Noorisys Technologies provides a wide array of solutions including Shopify development, Shopify customization, Shopify integration with third party integration, Shopping carts with Shopify CMS, Shopify upgrade, maintenance and support service.</p>
                                        <h4 className="title">Our Shopify solutions provide the following:</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck />Shopify custom Landing Page Design.</li>
                                            <li className="justifypara"><FiCheck />Shopify plugins creation and installation.</li>
                                            <li className="justifypara"><FiCheck />Shipping and Payment modules development.</li>
                                            <li className="justifypara"><FiCheck />Shopify upgrade service.</li>
                                            <li className="justifypara"><FiCheck />Our Shopify consultants can assist you narrow your ecommerce choices.</li>
                                            <li className="justifypara"><FiCheck />Shopify integration with another party application.</li>
                                            <li className="justifypara"><FiCheck />Conversion from any present platform to Shopify e-commerce</li>
                                            <li className="justifypara"><FiCheck />Creating shopping carts using Shopify.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>
                {/* End Page Wrapper  */}




                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Shopify;