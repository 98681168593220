import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import { Link } from "react-router-dom";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
// import NursingAppDemo from "./NursingAppDemo";
// import NursingAdminDemo from "./NursingAdminDemo";
const CommonMasonry = React.lazy(() => import("./CommonMasonry"))
// import CoursierDelivrable from "./CoursierDeliverables";
// import PlatformUsed from "../component/Verbos/PlatformUsed";
// import VerbosSimilarApps from "../component/Verbos/VerbosSimilarApps";
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const PortfolioList = [
    {
        image: 'nursing-1',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'nursing-2',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'nursing-3',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'nursing-4',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },

]
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
// const technologies = [

//     { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
//     { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
//     { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
//     { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

// ]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #8ab2ff 0%, #4636fc 100%)', link: '/healthcare-insurance-marketplace', img: 'healthcare.webp', h2: 'DocFinder', p: 'Healthcare Insurance Marketplace' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/primary-care-management-system', img: 'federate.webp', h2: 'Federate', p: 'Primry Care Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/doctor-appointment-and-online-pharmacy', img: 'mediconnect.webp', h2: 'MediConnect', p: 'Doctor Appointment and pharmacy' },
]


class NursingPlatformPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Nursing Platform || Noorisys Technologies Pvt Ltd' pageUrl='/nursing-platform' metaTitle="Nursing Platform" metaImage="/assets/images/portfolio/dp-portfolio-12.jpg"
                    metaDescription="Nursing Platform is an application for hospitals to create surgical notes for surgeons in which nurses will be able to create, view, duplicate, merge and print surgical notes" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Nursing Platform"
                    customColor="custom-nursing-color"
                    dataBlackOverlay={7}
                    bgImage="bg_image--51"
                    p="Web / Android / iOS Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Web / Android / iOS Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>Platform for Nurses</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>PHP, CodeIgniter, Angular, Android, JAVA, iOS, Swift</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title custom-nursing-color">Platform for Nurses</h3>
                                        {/* <p className="subtitle">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p> */}
                                        <p className="justifypara">Nursing Platform is an application for hospitals to create surgical notes for surgeons in which nurses/users will be able to create, view, duplicate, merge and print surgical notes. Users and surgical note templates will be created by hospital admins. Hospital admins will be created by super-admin with access to make limited number of notes.</p>
                                        <h4 className="title">Platforms</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color={'#00dddd'} />Front Landing Website</li>
                                            <li><FiCheck color={'#00dddd'} />User Panel (Web + Android + iOS)</li>
                                            <li><FiCheck color={'#00dddd'} />Admin Panel (Web + Android + iOS)</li>
                                            <li><FiCheck color={'#00dddd'} />Super-Admin Panel (Web)</li>

                                        </ul>

                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color={'#00dddd'} />Solution for maintaining surgical elements</li>
                                            <li><FiCheck color={'#00dddd'} />Multilingual</li>
                                            <li><FiCheck color={'#00dddd'} />Notification</li>
                                            <li><FiCheck color={'#00dddd'} />Cross-platform application</li>
                                            <li><FiCheck color={'#00dddd'} />Suitable for all hospitals</li>
                                            <li><FiCheck color={'#00dddd'} />Quick processing</li>
                                            <li><FiCheck color={'#00dddd'} />Fully responsive design</li>
                                            <li><FiCheck color={'#00dddd'} />Attractive dashboard</li>
                                            <li><FiCheck color={'#00dddd'} />Data Security</li>
                                            <li><FiCheck color={'#00dddd'} />User-friendly</li>

                                            {/* <li><FiCheck color={'#00dddd'} />Alerts and notifications.</li> */}

                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-nursing-color'} left={'d-sm-block'} paddingTop={'pt--30'} />



                                        {/* <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> */}
                                    </div>
                                    {/* <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30"
                // style={{backgroundColor:'#00dddd'}}
                >
                    <div className="container">
                        {/*  <CoursierDelivrable title={'16 Deliverables'} items={deliverables} class={'coursier'} value={[7, 5, 4]} />
                        </div>
                        <div className="ptb--90">
                            <div className="container">
                                <PlatformUsed color='custom-coursier-color' items={technologies} />
                            </div>
                        </div>
                        <div className="rn-portfolio-area bg_color--1 ptb--40">
                            <div className="container"> */}
                        <div className="row">
                            {/* <NursingAppDemo />
                             <NursingAdminDemo /> */}
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h3 className="title">Screenshots</h3>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" PortfolioList={PortfolioList} folder={'nursing'} />
                            </React.Suspense>
                        </div>

                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-nursing-color'} paddingTop={'pt--30'} />
                    </div>
                    <div className="pt--30">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <VerbosSimilarApps color='custom-nursing-color' title={'Similar Apps'} items={similarapps} slidestoshow={[3, 3]} />
                        </React.Suspense>
                    </div>
                    <div className="wrapper pt--30 pb--20">
                        <div className="container">
                            <div className="row ">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--10 text-center">
                                        <Link className="rn-button-style--2 btn-nursing-color" to="/portfolio"><span>View All Our Projects</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default NursingPlatformPortfolioDetails;
