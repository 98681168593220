import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const CoursierDelivrable = React.lazy(() => import("./CoursierDeliverables"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// const SocialShare =[
//     {Social:<FaGlobe />,link:' https://annachi.fr/ '}
// ]
const deliverables = [
    { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
    { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #8ab2ff 0%, #4636fc 100%)', link: '/healthcare-insurance-marketplace', img: 'healthcare.webp', h2: 'DocFinder', p: 'Healthcare Insurance Marketplace' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/primary-care-management-system', img: 'federate.webp', h2: 'Federate', p: 'Primry Care Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]
const technologies = [

    { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
    { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
    { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
    { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

]
const userAppButtonNames = ['MediConnect App', 'Select User Type', 'Account', 'Search Doctor', 'Search Doctor Result', 'Doctor Details', 'Select Date & Time', 'My Appointments', 'Appointments Details', 'Notifications', 'Medicines'];
const userAppImageAddress = [
    '/assets/images/portfolio/coursier-medical/coursier-1.webp',
    '/assets/images/portfolio/coursier-medical/coursier-2.webp',
    '/assets/images/portfolio/coursier-medical/coursier-3.webp',
    '/assets/images/portfolio/coursier-medical/coursier-4.webp',
    '/assets/images/portfolio/coursier-medical/coursier-5.webp',
    '/assets/images/portfolio/coursier-medical/coursier-6.webp',
    '/assets/images/portfolio/coursier-medical/coursier-7.webp',
    '/assets/images/portfolio/coursier-medical/coursier-8.webp',
    '/assets/images/portfolio/coursier-medical/coursier-9.webp',
    '/assets/images/portfolio/coursier-medical/coursier-10.webp',
    '/assets/images/portfolio/coursier-medical/coursier-11.webp',
];

const doctorAppButtonNames = ['MediConnect App', 'Select User Type', 'Account', 'My Appointments', 'Appointment Details', 'Medicines', 'Notification', 'My Profile', 'Reviews', 'Recent Orders', 'Shop By Category', 'Saved Addresses'];
const doctorAppImageAddress = [
    '/assets/images/portfolio/coursier-medical/coursier-1.webp',
    '/assets/images/portfolio/coursier-medical/coursier-2.webp',
    '/assets/images/portfolio/coursier-medical/doctor-1.webp',
    '/assets/images/portfolio/coursier-medical/coursier-3.webp',
    '/assets/images/portfolio/coursier-medical/coursier-4.webp',
    '/assets/images/portfolio/coursier-medical/coursier-5.webp',
    '/assets/images/portfolio/coursier-medical/coursier-6.webp',
    '/assets/images/portfolio/coursier-medical/coursier-7.webp',
    '/assets/images/portfolio/coursier-medical/coursier-9.webp',
    '/assets/images/portfolio/coursier-medical/coursier-8.webp',
    '/assets/images/portfolio/coursier-medical/coursier-10.webp',
    '/assets/images/portfolio/coursier-medical/doctor-2.webp'
];

const adminButtonNames = ['Dashboard', 'Manage Medical Stores', 'Add Medical Store', 'Update Medical Store', 'Manage Delivery Man', 'Add Delivery Man', 'Update Delivery Man', 'Manage Patients', 'Patients Details', 'Manage Doctors', 'Doctor Details'];
const adminImaageAddress = [
    '/assets/images/portfolio/doctor-appointment-with-ai/admin/admin-1.webp',
    '/assets/images/portfolio/doctor-appointment-with-ai/admin/admin-2.webp',
    '/assets/images/portfolio/doctor-appointment-with-ai/admin/admin-3.webp',
    '/assets/images/portfolio/doctor-appointment-with-ai/admin/admin-4.webp',
    '/assets/images/portfolio/doctor-appointment-with-ai/admin/admin-5.webp',
    '/assets/images/portfolio/doctor-appointment-with-ai/admin/admin-6.webp',
    '/assets/images/portfolio/doctor-appointment-with-ai/admin/admin-7.webp',
    '/assets/images/portfolio/doctor-appointment-with-ai/admin/admin-8.webp',
    '/assets/images/portfolio/doctor-appointment-with-ai/admin/admin-9.webp',
    '/assets/images/portfolio/doctor-appointment-with-ai/admin/admin-10.webp',
    '/assets/images/portfolio/doctor-appointment-with-ai/admin/admin-11.webp',
];
class DoctorAppointmentSystemwithAI extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Doctor Appointment System with AI' pageUrl='/doctor-appointment-and-online-pharmacy' metaTitle="Doctor Appointment System with AI" metaImage="/assets/images/portfolio/dp-portfolio-51.jpg"
                    metaDescription="smart appointment booking system provides patients with an easy way of booking a doctor’s appointment online as well as the purchase of medicines." />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb titlehtml='Doctor Appointment System <br /> with AI' dataBlackOverlay={7} bgImage='bg_image--129' customColor='custom-coursier-color' p='Web / Android / iOS Application' />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4> */}
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-coursier-color">Doctor Appointment System with AI</h3>
                                        {/* <p className="subtitle">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p> */}
                                        <p className="justifypara">This smart appointment booking system provides patients with an easy way of booking a doctor’s appointment online as well as the purchase of medicines. Users can get assistance from an AI chatbot to select the most suitable available doctors on the platform based on the problems they share in the chat.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#2f64af" />Online Doctor Appointment</li>
                                            <li><FiCheck color="#2f64af" />Audio/Video Call and Chat</li>
                                            <li><FiCheck color="#2f64af" />AI Chatbot</li>
                                            <li><FiCheck color="#2f64af" />Appointment Reminders</li>
                                            <li><FiCheck color="#2f64af" />User Reviews</li>
                                            <li><FiCheck color="#2f64af" />Order Medicine Online</li>
                                            <li><FiCheck color="#2f64af" />Pharmacy Access</li>
                                            <li><FiCheck color="#2f64af" />Health Information</li>
                                            <li><FiCheck color="#2f64af" />User Management</li>
                                            <li><FiCheck color="#2f64af" />Payment Gateway</li>
                                            <li><FiCheck color="#2f64af" />Delivery Management</li>
                                            <li><FiCheck color="#2f64af" />Multilingual</li>
                                            <li><FiCheck color="#2f64af" />Secure Transactions</li>
                                        </ul>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--3 ptb--80" style={{ background: "linear-gradient(to right, #2f64af, #53bfd0)" }} data-black-overlay='3'>
                    <div className="container" >
                        <React.Suspense fallback={<div>loading...</div>} >
                            <CoursierDelivrable title={'16 Deliverables'} items={deliverables} class={'coursier'} value={[7, 5, 4]} />
                        </React.Suspense>
                    </div>
                </div>
                <div className="ptb--90">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-coursier-color' items={technologies} />
                        </React.Suspense>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 ptb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='Patients can use the platform to book appointments with doctors, purchase medicine from various pharmacies, and access health-related information. They can also use the platform to view their medical records and communicate with their doctors.'
                                    buttonNames={userAppButtonNames}
                                    image={userAppImageAddress}
                                    alt='mediconnect user'
                                    color='mediconnect'
                                />
                                <ProductCommonApp
                                    h3='Doctor App'
                                    p='Doctors can create their accounts manage their appointments and prescribe medicines.'
                                    buttonNames={doctorAppButtonNames}
                                    image={doctorAppImageAddress}
                                    alt='mediconnect doctor'
                                    color='mediconnect'
                                />
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='The admin is responsible for managing and maintaining the website. They have access to the backend of the platform and can manage user accounts, appointments, pharmacy listings, and other aspects of the website.'
                                    buttonNames={adminButtonNames}
                                    image={adminImaageAddress}
                                    alt='mediconnect admin'
                                    color='mediconnect'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-coursier-color' title={'Similar Apps'} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-coursier' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default DoctorAppointmentSystemwithAI;
