import React, { Fragment, useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import PlatformUsed from "../component/Verbos/PlatformUsed";
// import PrimaryCareDelivrables from "./PrimaryCareDeliverables";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import { useLocation } from "react-router-dom";
import CoursierDelivrable from "./CoursierDeliverables";
import PrimaryCareDelivrables from "./PrimaryCareDeliverables";
import GetaQuoteButton from "../component/GetaQuoteButton";
import GetaquotePopup from "../component/header/getaquotePopup";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const VerbosSimilarApps = React.lazy(() =>
    import("../component/Verbos/VerbosSimilarApps")
);
export default function PortfolioJson({
    metaTags,
    breadCrumbs,
    socialShare,
    title,
    p,
    features,
    colorCode,
    deliverablesProps,
    deliverables,
    technologies,
    titleClass,
    screens,
    similarApps,
    portfolioButton,
    primaryCare,
    isVisible,
    showPopup,
    closePopup,
    getAquote,
    pageName
}) {
    const location = useLocation();
    useEffect(() => {
        // Function to track page view
        const trackPageView = () => {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: location.pathname,
                page_path: location.pathname,
            });

            var _hsq = (window._hsq = window._hsq || []);
            _hsq.push(["setPath", location.pathname]);
            _hsq.push(["trackPageView"]);
        };

        trackPageView();
    }, [location.pathname]);
    return (
        <Fragment>
            <PageHelmet
                pageTitle={metaTags.pageTitle}
                pageUrl={metaTags.pageUrl}
                metaTitle={metaTags.metaTitle}
                metaImage={metaTags.metaImage}
                metaDescription={metaTags.metaDescription}
            />
            <Header />
            <CommonBreadcrumb
                title={breadCrumbs.title}
                customColor={breadCrumbs.customColor}
                dataBlackOverlay={breadCrumbs.dataBlackOverlay}
                bgImage={breadCrumbs.bgImage}
                p={breadCrumbs.p}
                pb={breadCrumbs.pb}
                socialShare={socialShare}
            />
            <div className="rn-portfolio-details ptb--60 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-details">
                                <div className="inner">
                                    <h3 className={`rn-page-title ${titleClass}`}>
                                        {title}
                                    </h3>
                                    {p.map((data, index) => (
                                        <p className="justifypara" key={index}>
                                            {data}
                                        </p>
                                    ))}
                                    {Array.isArray(features) ?
                                        <>
                                            {features?.map((feature, index) => (
                                                <div key={index}>

                                                    <h4 className="title">{feature.title}</h4>
                                                    {feature.p &&
                                                        feature?.p?.map((p, index) => (
                                                            <p className="justifypara" key={index}>{p}</p>
                                                        ))
                                                    }
                                                    <ul className="list-style--1">
                                                        {feature?.li?.map((item, index) => (
                                                            <li className="justifypara" key={index}>
                                                                <FiCheck color={feature.colorCode} />
                                                                {item}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                            {getAquote &&
                                                <GetaQuoteButton showPopup={showPopup} pageName={pageName} left={'d-sm-block'} />
                                            }
                                        </>
                                        :
                                        <>
                                            <h4 className="title">{features.title}</h4>
                                            <ul className="list-style--1">
                                                {features?.li?.map((item, index) => (
                                                    <li className="justifypara" key={index}>
                                                        <FiCheck color={colorCode} />
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                            {getAquote &&
                                                <GetaQuoteButton showPopup={showPopup} pageName={pageName} left={'d-sm-block'} paddingTop={'pt--30'} paddingBottom={''} />
                                            }
                                        </>
                                    }
                                    {getAquote && <GetaquotePopup isVisible={isVisible} closePopup={closePopup} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {deliverablesProps && (primaryCare ?
                <>
                    <div
                        className="rn-portfolio-area bg_color--3 ptb--80"
                        style={{ backgroundColor: `#${deliverablesProps.colorCode}` }}
                        data-black-overlay="3"
                    >
                        <div className="container">
                            <PrimaryCareDelivrables
                                title={deliverablesProps.title}
                                items={deliverables}
                                class={deliverablesProps.class}
                                value={deliverablesProps.value}
                                margin={deliverablesProps.margin}
                            />
                        </div>
                    </div>
                    {getAquote &&
                        <GetaQuoteButton showPopup={showPopup} pageName={pageName} paddingTop={'pt--40'} />
                    }
                </>
                :
                <>
                    <div
                        className="rn-portfolio-area bg_color--3 ptb--80"
                        style={{ backgroundColor: `#${deliverablesProps.colorCode}` }}
                        data-black-overlay="3"
                    >
                        <div className="container">
                            <CoursierDelivrable
                                title={deliverablesProps.title}
                                items={deliverables}
                                class={deliverablesProps.class}
                                value={deliverablesProps.value}
                                margin={deliverablesProps.margin}
                            />
                        </div>
                    </div>
                    {getAquote &&
                        <GetaQuoteButton showPopup={showPopup} pageName={pageName} paddingTop={'pt--40'} />
                    }
                </>
            )
            }

            <div className="ptb--60">
                <div className="container">
                    <PlatformUsed
                        color={titleClass}
                        items={technologies}
                    />
                </div>
            </div>
            {getAquote &&
                <GetaQuoteButton showPopup={showPopup} pageName={pageName} paddingBottom={'pb--30'} />
            }
            {/* Start portfolio Area  */}
            <div className="rn-portfolio-area bg_color--1 ">
                {
                    screens?.map((screen, index) => (
                        screen.type === 'app' ?
                            <>
                                <div className="container" key={index}>
                                    <div className="row">
                                        <React.Suspense fallback={<div>loading...</div>}>
                                            <ProductCommonApp
                                                h3={screen.h3}
                                                p={screen.p}
                                                buttonNames={screen.appButtonNames}
                                                image={screen.appImages}
                                                alt={screen.alt}
                                                color={screen.color}
                                            />
                                        </React.Suspense>
                                    </div>
                                </div>
                                {getAquote &&
                                    <GetaQuoteButton showPopup={showPopup} pageName={pageName} paddingBottom={'pb--20'} />
                                }
                            </>
                            :
                            <>
                                <div className="container" key={index}>
                                    <div className="row">
                                        <React.Suspense fallback={<div>loading...</div>}>
                                            <CommonProductWeb
                                                h3={screen.h3}
                                                p={screen.p}
                                                buttonNames={screen.adminButtonNames}
                                                image={screen.adminImageAddress}
                                                alt={screen.alt}
                                                color={screen.color}
                                            />
                                        </React.Suspense>
                                    </div>
                                </div>
                                {getAquote &&
                                    <GetaQuoteButton showPopup={showPopup} pageName={pageName} paddingTop={'pt--30'} paddingBottom={'pb--30'} />
                                }
                            </>
                    ))}
                <React.Suspense fallback={<div>loading...</div>}>
                    <VerbosSimilarApps
                        color={titleClass}
                        title={similarApps.title}
                        items={similarApps.items}
                        slidestoshow={similarApps.slidesToShow}
                    />
                </React.Suspense>
            </div>
            <CommonPortfolioButtons
                class={portfolioButton.class}
                text={portfolioButton.text}
                padding={portfolioButton.padding}
            />
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            <Footer />
        </Fragment>
    );
}
