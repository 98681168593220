import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import CalendarModal from "../popup/CalenderModal";
import CommonSliderBanner from "../component/common/CommonSliderBanner";
const DataAssesmentAbout = React.lazy(() => import("../component/HomeLayout/DataAssesmentAbout"));

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Data Protection Assessment',
        // description: 'Coding the cores of the lives and transforming the business concepts for our clients.',
        description1: 'Stay Compliant with the Digital Personal Data Protection Act',
        // buttonTexts: 'Blueprint for Compliance',
        modalTexts: 'Blueprint for Compliance',
        buttonLinks: 'https://meetings-eu1.hubspot.com/afifa-ansari?embed=true'
    }
]

class DataProtectionAssesment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
        };
    }

    openModal = () => {
        this.setState({ isModalOpen: true });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };
    render() {
        return (
            <Fragment>
                <Helmet pageTitle="Data Protection Assesment || Noorisys Technologies" pageUrl="/data-protection-assessment" metaTitle="Navigating India's DPDP Landscape with Noorisys Technologies" metaImage="/assets/images/bg/paralax/bg-image-3-overlay.jpg"
                    metaDescription="With Noorisys Technologies by your side, DPDP compliance isn't a challenge to be met; it's a transformation to be embraced. Together, let's navigate this new terrain, pioneering privacy excellence and setting a benchmark for responsible data stewardship."
                    metaKeywords="best mobile app development company in uk ,web and mobile app development company,custom web design and development services,custom web development company ,mobile app development company in uk" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                <CalendarModal show={this.state.isModalOpen} onHide={this.closeModal} />
                {/* Start Slider Area   */}
                {/* <div className="slider-wrapper">
                    Start Single Slide
                    {SlideList.map((value, index) => (
                        <div className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--33" key={index} data-black-overlay="6">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                            {value.description1 ? <p className="description">{value.description1}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            {value.buttonText ? <div className="slide-btn"><button onClick={this.openModal} className="rn-button-style--2 btn-primary-color" >{value.buttonText}</button></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    End Single Slide
                </div> */}
                <CommonSliderBanner
                    slideList={SlideList}
                    classes={'slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--33'}
                    col={'col-lg-12'}
                    titleClass={'theme-gradient'}
                    openModal={this.openModal}
                    dataBlackOverlay={6}
                />
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area about-position-top bg_color--5 pb--40">
                    <React.Suspense fallback={<div>loading...</div>} >
                        <DataAssesmentAbout />
                    </React.Suspense>
                </div>
                {/* End About Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--60 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h4 className="title">Crafting Precise Terms and Conditions</h4>
                                            <p className="description justifypara">Our seasoned experts collaborate closely with your team to design and implement meticulous terms and conditions that encompass every facet of data handling. From data collection to storage, sharing practices to encryption protocols, and data retention policies to secure data deletion mechanisms – our solutions create a robust foundation that stands unwavering against the scrutiny of the DPDP.</p>
                                            <br />
                                        </div>
                                    </div>
                                    <div className="about-inner inner mt--30">
                                        <div className="section-title">
                                            <h4 className="title">Empowering Consent Management</h4>
                                            <p className="description justifypara">In an era where trust is a currency, Noorisys guides you through crafting consent management strategies that not only meet DPDP requirements but also resonate with your customers. We seamlessly integrate explicit consent processes into your data collection practices, ensuring your compliance journey is built on transparency and respect.</p>
                                            <br />
                                        </div>
                                    </div>
                                    <div className="about-inner inner mt--30">
                                        <div className="section-title">
                                            <h4 className="title">Mastering the Rights of Erasure and Modification</h4>
                                            <p className="description justifypara">Enhance user experience and data control by implementing features that honor the "Right to Erasure" and "Right to Modify." Noorisys empowers your users to take charge of their data, ensuring secure and seamless data deletions and modifications, all within the framework of the DPDP.</p>
                                        </div>
                                    </div>
                                    <div className="about-button d-flex justify-content-center mt--30">
                                        <button className="rn-button-style--2 btn-solid" onClick={this.openModal} >Safeguard Your Data</button>
                                    </div>
                                    {/* <div className="about-button d-flex justify-content-center mt--30">
                                        <a className="rn-button-style--2 btn-solid" href='https://meetings-eu1.hubspot.com/afifa-ansari?embed=true' >Safeguard Your Data</a>
                                    </div> */}
                                </div>

                                <div className="col-lg-5 mt_md--40 mt_sm--40">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/data-assesment-1.webp" alt="Data Protection" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--60 bg_color--5">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5 mt_md--40 mt_sm--40">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/data-assesment-2.webp" alt="data security" loading="lazy" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h4 className="title">Data Security Amplified</h4>
                                            <p className="description justifypara">Our commitment to data security is unwavering. Through advanced encryption methodologies and robust two-factor authentication implementations, we elevate your data protection practices to guard against unauthorized access and breaches.</p>
                                            <br />
                                        </div>
                                    </div>
                                    <div className="about-inner inner mt--30">
                                        <div className="section-title">
                                            <h4 className="title">Navigating Minor Consent</h4>
                                            <p className="description justifypara">For interactions involving minors, Noorisys ensures your compliance by guiding you through the process of obtaining explicit consent from parents or legal guardians, aligned with DPDP stipulations.</p>
                                        </div>
                                        <br />
                                    </div>
                                    <div className="about-inner inner mt--30">
                                        <div className="section-title">
                                            <h4 className="title">Seamless Data Nomination and Storage Location Compliance</h4>
                                            <p className="description justifypara">Embrace innovation with our data nomination strategies, granting users the power to designate data ownership nominees. Additionally, our consultation ensures optimal data storage locations, whether within India or compliant international jurisdictions.</p>
                                        </div>
                                    </div>
                                    <div className="about-button d-flex justify-content-center mt--30">
                                        <button className="rn-button-style--2 btn-solid" onClick={this.openModal} >Start Practicing Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Columns Area  */}
                <div className="rn-columns-area pt--60 pb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h4 className="title">Benefits that Transcend Compliance:</h4>
                                    <ul className="list-style--1">
                                        <li><FiCheck /> Protect your customers' personal data</li>
                                        <li><FiCheck /> Reduce the risk of data breaches</li>
                                        <li><FiCheck /> Elevate your organization's reputation</li>
                                        <li><FiCheck /> Achieve unwavering compliance with the DPDP</li>
                                    </ul>
                                    <h4 className="title">Our Assurance: Unwavering Confidence in Data Protection</h4>
                                    <p className="justifypara">At Noorisys Technologies, we don't merely offer services; we offer confidence. We're resolute in the belief that our Data Protection Assessment service is your safeguard against the compromise of your customers' personal data. We offer a 100% satisfaction guarantee, underscoring our commitment to your data protection journey.</p>
                                    <p className="justifypara">Contact us now to unearth the extensive scope of our DPDP solutions. Empower your business, fortify customer trust, and pioneer a future where data integrity reigns supreme.</p>
                                    {/* <div className="about-button d-flex justify-content-center mt--30">
                                        <a className="rn-button-style--2 btn-solid" href='https://meetings-eu1.hubspot.com/afifa-ansari?embed=true' > Book A Free Consultation</a>
                                    </div> */}
                                    <div className="about-button d-flex justify-content-center mt--30">
                                        <button className="rn-button-style--2 btn-solid" onClick={this.openModal} >Book A Free Consultation</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </Fragment>
        )
    }
}
export default DataProtectionAssesment;