import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";


class Java extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Java || Noorisys Technologies Pvt Ltd' pageUrl='/java' metaTitle="Java" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Develop high-performance and scalable applications with Java and the expertise of Noorisys." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title='Java'
                    dataBlackOverlay={5}
                    bgImage='bg_image--25'
                    buttonTitle={'Hire Now'}
                    buttonLink={'/contact-us'}
                    p='Custom oriented and pioneering methodologies facilitate all the requisite functionality and eliminate any failures of the product.'
                />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        <p className="justifypara">The Java Development team at Noorisys deliver multifaceted and large applications is no more difficult task as we guarantee our client for our Java Developers’ thoroughly practical industry knowledge and the appropriate delivery of software products. Outsourcing Java Development with us offers you the breathe-easy feeling for every stage of SDLC from project specification to project support & upholding.</p>
                                        <p className="justifypara">Noorisys Technologies - Android software development team creates unique and creative apps from scratch using the best strategy, design and technical expertise. With years of experience in Android app programming, we have delivered several apps successfully.</p>
                                        <p className="justifypara">Java is a general purpose programming language which can be separated into 3 sections- JDK, J2EE and J2ME. The language is prepared with a variety of advanced development tools for Java Web Application Development.</p>
                                        <p className="justifypara">At Noorisys we are Hire Dedicated Java Developers to work under your supervision and support your Java team accruing significant skills and commitment entirely for your project and business requirements.</p>

                                        <h4 className="title">OUR PROVIDING FOR JAVA APPLICATION DEVELOPMENT</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Custom Application Development.</li>
                                            <li><FiCheck />Business Application Development.</li>
                                            <li><FiCheck />Messaging Application Development & Communication.</li>
                                            <li><FiCheck />Game application Development.</li>
                                            <li><FiCheck />Rich API application Development.</li>
                                            <li><FiCheck />Application integration services.</li>
                                            <li><FiCheck />Social networking application Development.</li>
                                            <li><FiCheck />Application Development consulting services.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Java;