import React, { Component, useEffect, useState } from 'react'
import Layout1 from './Layout1'
import Layout2 from './Layout2'
import axios from 'axios'
import { GET_BASE_URL } from '../utils/constant'
import Layout3 from './Layout3'
import Blog from './Blog'


export default function DynamicLayout({ match }) {
    const { postId } = match.params;
    const [layout, setLayout] = useState(null);
    const [content, setContent] = useState(null)


    // console.log(postId)
    const getDetail = async () => {
        const formData = new FormData();
        formData.append("post_id", postId);
        try {
            const response = await axios.post(`${GET_BASE_URL}get-post`, formData);
            console.log(response);
            setContent(response.data.data);
            setLayout(response.data.data.layout)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDetail()
    }, [postId])

    return (
        <>
            {layout === 1 && <Layout1 content={content} />}
            {layout === 2 && <Layout2 content={content} />}
            {layout === 3 && <Layout3 content={content} />}
            {!layout && <div>Loading...</div>}
            {(layout !== 1 && layout !== 2 && layout !== 3 && layout) && <Blog />}
        </>
    )
}

