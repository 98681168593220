import React from "react";
import { FiClock, FiUser } from "react-icons/fi";

export default function CommonBlogBanner({ title, date, writer, titlehtml, image, titleClass }) {
    const formattedDate = new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    return (
        <div className={`rn-page-title-area pt--120 pb--190 bg_image ${image ? image : 'bg_image--26'} `}  style={{ backgroundImage: image ? `url(${image})` : undefined }} data-black-overlay="7">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="blog-single-page-title text-center pt--100">
                            {title && <h2 className={`title ${titleClass ? titleClass : 'theme-gradient'} `} >{title}</h2>}
                            {titlehtml && <h2 className="title theme-gradient" dangerouslySetInnerHTML={{ __html: titlehtml }} />}
                            <ul className="blog-meta d-flex justify-content-center align-items-center">
                                <li><FiClock /> {formattedDate}</li>
                                <li><FiUser />{writer.toUpperCase()}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}