// CommonSlider.js

import React from 'react';
import { Link } from 'react-router-dom';  // Make sure to import necessary dependencies
import TextLoop from 'react-text-loop';
const CommonTextLoop = ({ slideList }) => {
    return (
        <div className="slider-wrapper">
            {slideList.map((value, index) => (
                <div className={`slide designer-portfolio slider-style-3 d-flex align-items-center justify-content-center bg_image ${value.bgClass}`} key={index}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className={` ${value.ourProduct ? value.ourProduct : 'designer-thumbnail'}`}>
                                    <img src={value.imageSrc} alt="Slider" loading='lazy' />
                                </div>
                            </div>
                            <div className="col-lg-7 mt_md--40 mt_sm--40">
                                <div className={`inner ${value.textPosition}`}>
                                    <h1 className="title" style={{ color: value.titleColor }}>Get Your <br />
                                        {value.textLoopItems ? (
                                            <TextLoop>
                                                {value.textLoopItems.map((item, loopIndex) => (
                                                    <span key={loopIndex} dangerouslySetInnerHTML={{ __html: item }} ></span>
                                                ))}
                                            </TextLoop>
                                        ) : (
                                            value.title
                                        )}
                                    </h1>
                                    <h2 style={{ color: value.titleColor }}>NOW</h2>
                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* <div className={`view-more-btn mt--10 ${{${value.text} ? ${value.text:'text-center'}}}`}> */}
                                        <div className={`view-more-btn mt--10 ${value.textPosition ? value.textPosition : 'text-center'}`}>
                                            <Link className="rn-button-style--2 btn-solid" to="/contact-us"><span>Send an inquiry</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CommonTextLoop;
