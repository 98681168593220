import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// const SocialShare =[
//     {Social:<FaGlobe />,link:' https://annachi.fr/ '}
// ]

const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #8ab2ff 0%, #4636fc 100%)', link: '/healthcare-insurance-marketplace', img: 'healthcare.webp', h2: 'DocFinder', p: 'Healthcare Insurance Marketplace' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/primary-care-management-system', img: 'federate.webp', h2: 'Federate', p: 'Primry Care Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

class DataScraper extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Data Scraper || Noorisys Technologies' pageUrl='/data-scraper' metaTitle="Data Scraper || Noorisys Technologies" metaImage="/assets/images/portfolio/dp-portfolio-51.jpg"
                    metaDescription="We have developed a data scraper, a tool designed to automatically extract useful information from websites and organise it in a structured format like a spreadsheet or database." />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb title='Data Scraper' dataBlackOverlay={7} bgImage='bg_image--7' customColor='custom-coursier-color' />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4> */}
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-coursier-color">Data Scraper</h3>
                                        <p className="justifypara">We have developed a data scraper, a tool designed to automatically extract useful information from websites and organise it in a structured format like a spreadsheet or database.</p>
                                        <p className="justifypara">Imagine you need to gather information, such as prices or product details, from multiple websites for comparison. Instead of manually visiting each site and copying data, our scraper automates this process efficiently and accurately. Here's how it works:</p>
                                        <p className="justifypara"></p>
                                        <h4>Accessing Websites:
                                        </h4>
                                        <p className="justifypara"> The scraper sends requests to access the desired webpages.
                                        </p>
                                        <h4>Extracting Data:</h4>
                                        <p className="justifypara"> It identifies and collects the specific information, such as text, images, or links, displayed on the webpage.
                                        </p>
                                        <h4>Organising Data:</h4>
                                        <p className="justifypara"> The collected data is cleaned and stored in a neat format, ready for analysis or reporting.
                                        </p>
                                        <p className="justifypara">This tool can be used for various purposes, including market research, tracking competitor prices, or gathering reviews. However, while data scraping is powerful, it must be used responsibly to comply with the terms and conditions of the websites being accessed.
                                        </p>
                                        <p className="justifypara">Our project simplifies data collection, saving time and effort while ensuring accuracy.</p>

                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}


                <React.Suspense fallback={<div>loading...</div>} >
                    <VerbosSimilarApps color='custom-coursier-color' title={'Other Apps'} items={similarapps} slidestoshow={[3, 3]} />
                </React.Suspense>
                <CommonPortfolioButtons class='btn-coursier' text='View All Our Projects' />
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default DataScraper;
