import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
// import DoctorAppointmentAdminDemo from "./DoctorAppointmentAdminDemo";
//  import CoursierDelivrable from "./CoursierDeliverables";
// import PlatformUsed from "../component/Verbos/PlatformUsed";
// import CoursierAppDemo from "./CoursierAppDemo";
// import CoursierAdminDemo from "./CoursierAdminDemo";
// import VerbosSimilarApps from "../component/Verbos/VerbosSimilarApps";
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const CommonMasonry = React.lazy(() => import("./CommonMasonry"))
const PortfolioList = [
    {
        image: 'doctor-appointment-1',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'doctor-appointment-2',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'doctor-appointment-3',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
]
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
// const technologies = [

//     { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
//     { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
//     { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
//     { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

// ]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #8ab2ff 0%, #4636fc 100%)', link: '/healthcare-insurance-marketplace', img: 'healthcare.webp', h2: 'DocFinder', p: 'Healthcare Insurance Marketplace' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/primary-care-management-system', img: 'federate.webp', h2: 'Federate', p: 'Primry Care Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

class DoctorAppointmentandHospitalPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Doctor Appointment and Hospital Management' pageUrl='/doctor-appointment-and-hospital-management' metaTitle="Doctor Appointment and Hospital Management" metaImage="/assets/images/portfolio/dp-portfolio-24.webp"
                    metaDescription="Book doctor and hospital appointments easily with our web-based appointment management system. View available slots, select date and time, and streamline your healthcare experience."
                    metaKeywords="online doctor appointment booking system,doctor appointment management system" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Doctor Appointment and Hospital Management"
                    customColor="custom-doctor-appointment-color"
                    dataBlackOverlay={7}
                    bgImage="bg_image--63"
                    p="Web Application"
                />

                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Web Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>Doctor Appointment and Hospital Management</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>PHP, CodeIgniter</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title custom-doctor-appointment-color">Doctor Appointment and Hospital Management</h3>
                                        <p className="justifypara">The proposed project is a smart appointment booking system that provides patients or any user an easy way of booking a doctor’s as well as hospital's appointment online. This is a web based application that overcomes the issue of managing and booking appointments according to user’s choice or demands. The task sometimes becomes very tedious for the compounder or doctor himself in manually allotting appointments for the users as per their availability. Hence this project offers an effective solution where users can view various booking slots available and select the preferred date and time.</p>
                                        <p className="justifypara"> This system also allows users to cancel their booking anytime. The system provides an additional feature of view the previous patient’s medical file as per the patient’s permission. In this doctor generate the patient prescription and patient share the prescription to multiple pharmacists. Only one pharmacist can accept this prescription and delivered this prescription to patient. Admin will be able to view all the platform user's view, their flow, and manage the users like doctor, hospital, pharmacist, patient etc.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color={'#005d93'} />Easy registration of the patient.</li>
                                            <li><FiCheck color={'#005d93'} />Online booking</li>
                                            <li><FiCheck color={'#005d93'} />Digitalization of your processes and flows.</li>
                                            <li><FiCheck color={'#005d93'} />Without login you can easily search the available doctors on the platform</li>
                                            <li><FiCheck color={'#005d93'} />Secure the patient's prescription and medical file.</li>
                                            <li><FiCheck color={'#005d93'} />Any time, any doctors view the patient’s medical file and analyze about the patient.</li>
                                            <li><FiCheck color={'#005d93'} />Track the medical reports.</li>
                                            <li><FiCheck color={'#005d93'} />Easy cancellation and switch the patient from one doctor to onother.</li>
                                            <li><FiCheck color={'#005d93'} />Multi language</li>
                                            <li><FiCheck color={'#005d93'} />And many more….</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-docotor-aapointment-color'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h3 className="title">Screenshots</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" PortfolioList={PortfolioList} folder={'doctor-appointment'} />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-docotor-aapointment-color'} paddingTop={'pt--30'} />
                    <div className="pt--30">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <VerbosSimilarApps color='custom-doctor-appointment-color' title='Apps' items={similarapps} slidestoshow={[3, 3]} />
                        </React.Suspense>
                    </div>
                </div>
                <CommonPortfolioButtons class='btn-docotor-aapointment-color' text='View All Our Projects' />
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default DoctorAppointmentandHospitalPortfolioDetails;
