import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";

class Angular extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Angular || Noorisys Technologies Pvt Ltd' pageUrl='/angular' metaTitle="Angular" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Discover the power of Angular with Noorisys. Our skilled Angular developers specialize in creating highly-interactive and data-driven web applications." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title='Angular'
                    dataBlackOverlay={5}
                    bgImage='bg_image--25'
                    buttonTitle={'Hire Now'}
                    buttonLink={'/contact-us'}
                    p='Our Skilled Angular Developers Enable Us to Deliver Highly-Interactive & Data-Driven Angular Web Applications.'
                />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        <p className="justifypara">At Noorisys Technologies, we are a top-running AngularJS web development company having delivered a number of mobile and web apps that are highly secure, robust, easy to maintain and extensible with various additional features.</p>
                                        <p className="justifypara">In the work delivered by us, we aim to simplify both development as well as testing of applications offering a framework for Client side model View Controller (MVC) architecture. You get scalable AngularJS web applications with great features that serve the complex requirement of businesses of diverse verticals.</p>
                                        <p className="justifypara">We have a team of Angular JS developers using cutting-edge tools and latest technologies thus offering brilliant and extensive AngularJS development services. Using the exclusive AngularJS features, such as Two Way Data Binding, HTML Template, Directives, Dependency Injection and Testing, our developers build a data-driven web application. With us you can build a better customer network with access to latest tools and technologies thus allowing the development of cost-effective mobile applications.</p>

                                        <h4 className="title">OUR SERVICES</h4>
                                        <p className="justifypara">Noorisys Technologies provides a wide array of solutions including Angular development, Angular customization, Angular integration with third party integration, shopping carts with Angular CMS, Angular upgrade, maintenance and support service.</p>
                                        <h4 className="title">Our Angular solutions provide the following:</h4>

                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck />Angular custom Landing Page Design.</li>
                                            <li className="justifypara"><FiCheck />Angular plugins creation and installation.</li>
                                            <li className="justifypara"><FiCheck />Shipping and Payment modules development.</li>
                                            <li className="justifypara"><FiCheck />Angular upgrade service.</li>
                                            <li className="justifypara"><FiCheck />Our Angular consultants can assist you narrow your ecommerce choices.</li>
                                            <li className="justifypara"><FiCheck />Angular integration with another party application.</li>
                                            <li className="justifypara"><FiCheck />Conversion from any present platform to Angular e-commerce.</li>
                                            <li className="justifypara"><FiCheck />Creating shopping carts using Angular.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>
                {/* End Page Wrapper  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Angular;