import React, { Component, useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
// import PortfolioMasonry from './PortfolioMasonry';
import { FiCheck } from "react-icons/fi";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// import CourierPortfolioMasonry from "./CourierMasonry";
// import HospitalMasonry from "./HospitalMasonry";
// import HealthcareMasonry from "./HealthcareMasonry";
// import HotelMasonry from "./HotelMasonry";
// import MultingualPregnancyMasonry from "./MultingualPregnancyMasonry";
// import TabExample from "../component/Verbos/Tabexample";
// import MultilingualAdminDemo from "./MultilingualAdminDemo";
// import Delivrable from "../component/Verbos/Deleverable";
// import PlatformUsed from "../component/Verbos/PlatformUsed";
// import SliderPortfolio from "../component/Verbos/SliderPortfolio";
// import VerbosSimilarApps from "../component/Verbos/VerbosSimilarApps";
// import FooterType from "../component/Verbos/FooterType";
// import Download1 from "../component/Verbos/Download1";

// const SocialShare = [
//     { Social: <FaGlobe />, link: 'https://janamapp.co.uk/ ' }
// ]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
]
const userAppButtonNames = ['Create Account', 'Verify OTP', 'Login', 'Order Products', 'Select Order Frequency', 'Order Summary', 'Payment', 'My Orders', 'Profile', 'Update Profile', 'Share App'];
const userImageAddress = [
    '/assets/images/portfolio/water-donation-app/user-app/water-donation-user-1.webp',
    '/assets/images/portfolio/water-donation-app/user-app/water-donation-user-3.webp',
    '/assets/images/portfolio/water-donation-app/user-app/water-donation-user-2.webp',
    '/assets/images/portfolio/water-donation-app/user-app/water-donation-user-4.webp',
    '/assets/images/portfolio/water-donation-app/user-app/water-donation-user-5.webp',
    '/assets/images/portfolio/water-donation-app/user-app/water-donation-user-6.webp',
    '/assets/images/portfolio/water-donation-app/user-app/water-donation-user-7.webp',
    '/assets/images/portfolio/water-donation-app/user-app/water-donation-user-8.webp',
    '/assets/images/portfolio/water-donation-app/user-app/water-donation-user-9.webp',
    '/assets/images/portfolio/water-donation-app/user-app/water-donation-user-10.webp',
    '/assets/images/portfolio/water-donation-app/user-app/water-donation-user-11.webp',
];
const adminAppButtonNames = ['Dashboard', 'Manage Cities', 'Manage Mosque', 'Manage Zone', 'Manage Banners', 'Add Imam', 'Add Water Products', 'Manage Users', 'Delivery Person', 'Manage Orders', 'Delivery Wallet', 'My Profile'];
const adminImaageAddress = [
    '/assets/images/portfolio/water-donation-app/admin/admin-1.webp',
    '/assets/images/portfolio/water-donation-app/admin/admin-2.webp',
    '/assets/images/portfolio/water-donation-app/admin/admin-3.webp',
    '/assets/images/portfolio/water-donation-app/admin/admin-4.webp',
    '/assets/images/portfolio/water-donation-app/admin/admin-5.webp',
    '/assets/images/portfolio/water-donation-app/admin/admin-6.webp',
    '/assets/images/portfolio/water-donation-app/admin/admin-7.webp',
    '/assets/images/portfolio/water-donation-app/admin/admin-8.webp',
    '/assets/images/portfolio/water-donation-app/admin/admin-9.webp',
    '/assets/images/portfolio/water-donation-app/admin/admin-10.webp',
    '/assets/images/portfolio/water-donation-app/admin/admin-11.webp',
    '/assets/images/portfolio/water-donation-app/admin/admin-12.webp',
];
function usePageViewTracking(location) {
    useEffect(() => {
        window.gtag("config", "AW-16650476770", {
            page_title: location.pathname,
            page_path: location.pathname,
        });
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', location.pathname]);
        _hsq.push(['trackPageView']);
    }, [location]);
}
function WaterDonationPortfolio(props) {
    usePageViewTracking(props.location);
    return (
        <React.Fragment>
            <PageHelmet pageTitle='Water Donation App || Noorisys Technologies Pvt Ltd' pageUrl='/water-dontation-app' metaTitle="Water Donation App" metaImage="/assets/images/portfolio/dp-portfolio-09.jpg"
                metaDescription="platform connects users, mosques, delivery personnel, and admins, enabling seamless water donation and efficient delivery management through integrated mobile and web applications." />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <CommonBreadcrumb
                title="Water Donatiom App"
                customColor="theme-gradient"
                dataBlackOverlay={7}
                bgImage="bg_image--142"
                p="Android / iOS Application"
                pb='pb--120'
            />
            {/* End Breadcrump Area */}

            {/* Start Portfolio Details */}

            <div className="rn-portfolio-details ptb--40 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-details">
                                <div className="inner">
                                    <h3 className="rn-page-title custom-water-donation-color">Water Donation App</h3>
                                    <p className="justifypara">The platform connects users, mosques, delivery personnel, and admins, enabling seamless water donation and efficient delivery management through integrated mobile and web applications.</p>
                                    <h4 className="title">User Functionality</h4>
                                    <ul className="list-style--1">
                                        <li><FiCheck style={{ color: '#245456' }} />Language Selection</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Registration and Login</li>
                                        <li><FiCheck style={{ color: '#245456' }} />My Profile</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Notifications</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Contact Us</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Guest Login</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Home Page</li>
                                        <li><FiCheck style={{ color: '#245456' }} />View Mosques in Need</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Order Water for Mosques</li>
                                        <li><FiCheck style={{ color: '#245456' }} />My Orders</li>
                                    </ul>

                                    <h4 className="title">Delivery Personnel Functionality</h4>
                                    <ul className="list-style--1">
                                        <li><FiCheck style={{ color: '#245456' }} />Registration and Login</li>
                                        <li><FiCheck style={{ color: '#245456' }} />My Profile</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Wallet and Bank Account Management</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Change Online/Offline Status</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Notifications</li>
                                        <li><FiCheck style={{ color: '#245456' }} />View and Accept Orders</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Update Delivery Status</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Upload Delivery Photos</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Order History</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Wallet</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Withdrawals</li>

                                    </ul>
                                    <h4 className="title">Imam Functionality</h4>
                                    <ul className="list-style--1">
                                        <li><FiCheck style={{ color: '#245456' }} />Registration and Login</li>
                                        <li><FiCheck style={{ color: '#245456' }} />My Profile</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Submit Requests for Water Delivery</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Notifications</li>
                                        <li><FiCheck style={{ color: '#245456' }} />View Mosque Needs Status</li>

                                    </ul>
                                    <h4 className="title">Admin Functionality</h4>
                                    <ul className="list-style--1">
                                        <li><FiCheck style={{ color: '#245456' }} />Dashboard Overview</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Users</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Delivery Personnel</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Imams</li>
                                        <li><FiCheck style={{ color: '#245456' }} />View Imam Requests</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Orders</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Water Products</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Other Products</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Delivery Charges</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Handle Withdrawal Requests</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Cities and Zones</li>
                                        <li><FiCheck style={{ color: '#245456' }} />Manage Banners</li>
                                    </ul>



                                    {/* <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Details */}

            {/* <FooterType /> */}

            {/* Start portfolio Area  */}
            <div className="rn-portfolio-area bg_color--1 ptb--30 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center service-style--3 mb--30">
                                {/* <p></p> */}
                                {/* <h3 className="title">Screenshots</h3> */}
                                {/* <p></p> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="ptb--60">
                <SliderPortfolio />
                </div> */}

                {/* <Delivrable /> */}

                {/* <PlatformUsed /> */}
                <div className="">
                    {/* <FooterType /> */}
                </div>


                <div className="wrapper bg_color--3">
                    <div className="container" >
                        <div className="row ">
                            {/* <MultingualPregnancyMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" /> */}
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='Users can search for mosques, place water delivery requests to donate, and track order statuses from their mobile application.'
                                    buttonNames={userAppButtonNames}
                                    image={userImageAddress}
                                    alt='mommycare user'
                                    color='water-donation-app'
                                />
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='Admin can oversee all user activities, manage requests, and optimise delivery operations through a centralised admin panel.'
                                    buttonNames={adminAppButtonNames}
                                    image={adminImaageAddress}
                                    alt='mommycare admin'
                                    color='water-donation-admin'
                                />
                                {/* <MultilingualAdminDemo /> */}
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="ptb--60">

                    </div>

                </div>
                <React.Suspense fallback={<div>loading...</div>} >
                    <VerbosSimilarApps color='custom-water-donation-color' items={similarapps} title={'Apps'} slidestoshow={[3, 3]} />
                </React.Suspense>
                <CommonPortfolioButtons class='btn-water-donation' text='View All Our Projects' />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <VerbosSimilarApps /> */}
                            <div className="custom_download_app">
                                {/* <Download1 /> */}
                            </div>
                            <div className="section-title text-center service-style--3 mb--10">
                                {/* <h3 className="title">Screenshots</h3> */}

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* End portfolio Area  */}



            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />


        </React.Fragment>
    )
}
export default WaterDonationPortfolio;
