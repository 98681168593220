import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import PlatformUsed from "../component/Verbos/PlatformUsed";
import CoursierDelivrable from "./CoursierDeliverables";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));

// import VerbosSimilarApps from "../component/Verbos/VerbosSimilarApps";
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// const CommonMasonry = React.lazy(()=>import ('./CommonMasonry'))
// const PortfolioList = [
//     {
//         image: 'portrey-1',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'portrey-2',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
// ]
const deliverables = [
    { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'provider.png', h3: 'Provider App', h4: 'Android' },
    { item_bg: 'android.png', item_icon: 'provider.png', h3: 'Provider App', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'provider.png', h3: 'Provider ', h4: 'Web' },
    { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
]
const technologies = [

    { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
    { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
    // { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
    { h5: 'PHP', p: 'Admin Panel Development', img: 'php.png' },

]

const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
]

const userButtonNames = ['Splash Screen', 'Select Option', 'Register', 'Login', 'Forgot Password', 'Home', 'Bookings', 'Messages', 'Profile', 'Categories', 'Settings'];
const userImageAddress = [
    '/assets/images/portfolio/servicemate/user-1.webp',
    '/assets/images/portfolio/servicemate/user-2.webp',
    '/assets/images/portfolio/servicemate/user-3.webp',
    '/assets/images/portfolio/servicemate/user-4.webp',
    '/assets/images/portfolio/servicemate/user-5.webp',
    '/assets/images/portfolio/servicemate/user-6.webp',
    '/assets/images/portfolio/servicemate/user-10.webp',
    '/assets/images/portfolio/servicemate/user-11.webp',
    '/assets/images/portfolio/servicemate/user-9.webp',
    '/assets/images/portfolio/servicemate/user-7.webp',
    '/assets/images/portfolio/servicemate/user-12.webp',
];

const adminButtonNames = ['Dashboard', 'Categories', 'Sub Categories', 'Services', 'Manage Provider', 'Manage User', 'Manage Jobs', 'Manage Booking', 'Manage Commision'];
const adminImageAddress = [
    '/assets/images/portfolio/servicemate/admin-1.webp',
    '/assets/images/portfolio/servicemate/admin-2.webp',
    '/assets/images/portfolio/servicemate/admin-3.webp',
    '/assets/images/portfolio/servicemate/admin-4.webp',
    '/assets/images/portfolio/servicemate/admin-5.webp',
    '/assets/images/portfolio/servicemate/admin-6.webp',
    '/assets/images/portfolio/servicemate/admin-7.webp',
    '/assets/images/portfolio/servicemate/admin-8.webp',
    '/assets/images/portfolio/servicemate/admin-9.webp',
];

class PorteryPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Portery Portfolio || Noorisys Technologies Pvt Ltd' pageUrl='/service-provider-marketplace' metaTitle="Service Provider Marketplace" metaImage="/assets/images/portfolio/dp-portfolio-13.jpg"
                    metaDescription="A service provider marketplace that simplifies the process of finding and booking any kind of service. From home repairs to personal grooming, our platform connects customers with reliable service providers and delivers them straight to their doorstep." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Service Provider Marketplace"
                    customColor="custom-portery-color"
                    dataBlackOverlay={7}
                    bgImage="bg_image--52"
                    p="Web Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Web Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>Multiple Services Platform</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>PHP, CodeIgniter, Bootstrap, Javascript, jQuery, HTML, CSS</h6>
                                            </div>
                                        </div> */}
                                        <h3 className="rn-page-title custom-portery-color">Multiple Services Platform</h3>
                                        <p className="justifypara">This is an on-demand service provider marketplace that connects customers with professionals to book any kind of service. Services may range from household work like plumbing, moving and carpentry to services that can be carried out in the merchants’ establishment like salon, spa, photography and even services that can be delivered over video chat like consulting and legal services etc. </p>


                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color={'#126075'} />Easy to book services</li>
                                            <li><FiCheck color={'#126075'} />Categories and subcategories of services</li>
                                            <li><FiCheck color={'#126075'} />Verified provider accounts</li>
                                            <li><FiCheck color={'#126075'} />Defined working hours and days</li>
                                            <li><FiCheck color={'#126075'} />Chat</li>
                                            <li><FiCheck color={'#126075'} />Video Calling</li>
                                            <li><FiCheck color={'#126075'} />Rating and Reviews</li>
                                            <li><FiCheck color={'#126075'} />Google Map Integration</li>
                                            <li><FiCheck color={'#126075'} />Stripe Integration</li>

                                            {/* <li><FiCheck color={'#126075'} />Alerts and notifications.</li> */}

                                        </ul>

                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-portrey'} left={'d-sm-block'} paddingTop={'pt--30'} />


                                        {/* <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> */}
                                    </div>
                                    {/* <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30"
                    style={{ backgroundColor: '#126075' }}
                >
                    <div className="container">
                        <CoursierDelivrable title={'7 Deliverables'} items={deliverables} class={'coursier'} value={[7, 5, 4]} />
                    </div>
                </div>
                <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-portrey'} paddingTop={'pt--30'} />
                <div className="ptb--30">
                    <div className="container">
                        <PlatformUsed color='custom-portery-color' items={technologies} />
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-portrey'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--1 ptb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='To book a service, a customer will be required to search for providers near their desired location, select the preferred date and time based on the provider’s availability and securely pay for the service.'
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt='service provider user'
                                    color='service-provider'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-portrey'} paddingTop={'pt--10'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='Admin can manage customers, providers, services, bookings, transactions and other aspects of the website.'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='service provider admin'
                                    color='service-provider'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-portrey'} paddingTop={'pt--20'} paddingBottom={`pb--20`} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-portery-color' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>

                    <CommonPortfolioButtons class='btn-portrey' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default PorteryPortfolioDetails;
