import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import PlatformUsed from "../component/Verbos/PlatformUsed";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
const technologies = [

    { h5: '', p: '', img: '' },
    { h5: 'Android', p: 'Android & iOS development', img: 'android.png' },
    { h5: 'Java', p: 'Backend Development', img: 'java.png' },
    // { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
    // { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

const userAppButtonNames = ['Splash Screen', 'Login', 'Dashboard', 'Attendance', 'Extra Activity'];
const userAppImages = [
    '/assets/images/portfolio/school-management/user-1.webp',
    '/assets/images/portfolio/school-management/user-2.webp',
    '/assets/images/portfolio/school-management/user-3.webp',
    '/assets/images/portfolio/school-management/user-4.webp',
    '/assets/images/portfolio/school-management/user-5.webp',
];

class SchoolManagmentPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='School Management System' pageUrl='/school-management-system' metaTitle="School Management System" metaImage="/assets/images/portfolio/dp-portfolio-31.webp"
                    metaDescription="This school management system is one of the best software to effectively manage the administrative activities of the schools. It lets the schools monitor and manage their activities, resources and information in one place."
                    metaKeywords="school management system software,school management systems uk,school management software" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="School Management System"
                    customColor="custom-school-color"
                    dataBlackOverlay={7}
                    bgImage="bg_image--70"
                    p="Android Application"
                />

                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Android Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>E-Learning</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>Android, Java, PHP</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title custom-school-color">School Management System</h3>
                                        <p className="justifypara">School management system makes parents SMART and children SMARTER through an app. The School management system connects Parents & Students to the school through its proprietary software using an optimal mix of voice, SMS & data notifications that fosters highest level of collaboration between Parents and Teachers.</p>
                                        <p className="justifypara">This partnership between parents & school yields the best possible outcome for the students helping them realize their full potential. A true Potential -that’s just not limited to academics but extends in the other realms of life like sports, extracurricular activity etc - leading to an all-round personality development & fulfilling careers. Happiness, they say, is only incidental to the students who are enjoying a fulfilling & a rewarding school life.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Students can see their homework</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Students can see their attendance</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Study material can be access by student or parents</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Parents can see timetable</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Parents can see the holiday list, exam time table</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Parents can see live vehicle tracking</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Easy to use</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Easy registration process</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Nominate child for Extra Curricular activity through app</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Participation in online & in-app surveys</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Endless possibilities of collaboration between school and parents to help secure a happy future for child</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Emergency message from the school Principal</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Regular grooming report of child</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Creates true benchmark for child’s performance</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Cataloguing of all the study material shared by school in all formats in a single tab</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />Download study material option</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />leading to an all-round personality development</li>
                                            <li className="justifypara"><FiCheck color={'#FAAE1A'} />And many more….</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-school-management'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30">
                </div>
                <div className="ptb--30">
                    <div className="container">
                        <PlatformUsed color='custom-school-color' items={technologies} />
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-school-management'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--1 ptb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='Patients can use the platform to book appointments with doctors, purchase medicine from various pharmacies, and access health-related information. They can also use the platform to view their medical records and communicate with their doctors.'
                                    buttonNames={userAppButtonNames}
                                    image={userAppImages}
                                    alt='school app'
                                    color='school-portfolio'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-school-management'} paddingTop={'pt--30'} paddingBottom={`pb--30`} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-school-color' title={'Other Apps'} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-school-management' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default SchoolManagmentPortfolioDetails;
