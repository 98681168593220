import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'VueJS', p: 'Admin Panel', img: 'vue.png' },
    { h5: 'VueJS', p: 'User Panel', img: 'vue.png' },
]

const adminButtonNames = ['Home', 'Orders', 'Products', 'Analytics', 'List View', 'Discounts'];
const adminImageAddress = [
    '/assets/images/portfolio/collection/admin/admin-01.webp',
    '/assets/images/portfolio/collection/admin/admin-02.webp',
    '/assets/images/portfolio/collection/admin/admin-03.webp',
    '/assets/images/portfolio/collection/admin/admin-04.webp',
    '/assets/images/portfolio/collection/admin/admin-05.webp',
    '/assets/images/portfolio/collection/admin/admin-06.webp',
];

const userButtonNames = ['Home', 'Shirts Product', 'Denim Product', 'Store Locator', 'Product Details', 'Add to Cart', 'Register', 'My Cart', 'Delivery', 'Payment', 'Order History'];
const userImageAddress = [
    '/assets/images/portfolio/collection/user/user-01.webp',
    '/assets/images/portfolio/collection/user/user-02.webp',
    '/assets/images/portfolio/collection/user/user-03.webp',
    '/assets/images/portfolio/collection/user/user-04.webp',
    '/assets/images/portfolio/collection/user/user-05.webp',
    '/assets/images/portfolio/collection/user/user-06.webp',
    '/assets/images/portfolio/collection/user/user-07.webp',
    '/assets/images/portfolio/collection/user/user-08.webp',
    '/assets/images/portfolio/collection/user/user-09.webp',
    '/assets/images/portfolio/collection/user/user-10.webp',
    '/assets/images/portfolio/collection/user/user-11.webp',
];

class CollectionPortfolio extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Collection' pageUrl='/Apparel-ecommerce' metaTitle="This eCommerce website offers a diverse range of clothing items, including shirts, trousers, blazers, and denim. Users can easily navigate through the platform using the search bar to find specific items." />
                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Apparel eCommerce"
                    customColor="custom-collection-portfolio"
                    dataBlackOverlay={5}
                    bgImage="bg_image--116"
                    p="Web Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-collection-portfolio">Apparel eCommerce</h3>
                                        <p className="justifypara">This eCommerce website offers a diverse range of clothing items, including shirts, trousers, blazers, and denim. Users can easily navigate through the platform using the search bar to find specific items. Additionally, there is a dedicated sale section for discounted items. The website allows customers to add desired items to their cart and proceed to checkout, where various payment methods are available.</p>
                                        <h3 className="custom-collection-portfolio">Features</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#1F603B" />Login and Register</li>
                                            <li><FiCheck color="#1F603B" />Browse by Category</li>
                                            <li><FiCheck color="#1F603B" />Search Bar</li>
                                            <li><FiCheck color="#1F603B" />Sale Section</li>
                                            <li><FiCheck color="#1F603B" />Add to Cart and Checkout</li>
                                            <li><FiCheck color="#1F603B" />Apply Coupon Cards</li>
                                            <li><FiCheck color="#1F603B" />Manage Delivery Address</li>
                                            <li><FiCheck color="#1F603B" />Add to Wishlist</li>
                                            <li><FiCheck color="#1F603B" />Order History</li>
                                            <li><FiCheck color="#1F603B" />Various Payment Methods</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-collection'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-collection-portfolio' items={technologies} />
                        </React.Suspense>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-collection'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 pb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='User'
                                    p={`Users can explore a wide range of clothing items, utilize a convenient search bar, and take advantage of the sale section for discounts on this eCommerce platform.`}
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt="collection user"
                                    color='collection'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-collection'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin'
                                    p={`The admin oversees and manages the eCommerce platform, handling tasks such as user management, product listings, category, stock management and overseeing financial transactions and billing processes.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt="collection admin"
                                    color='collection'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-collection'} paddingTop={'pt--30'} paddingBottom={'pb--30'} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        {/* <VerbosSimilarApps color='custom-food-delivery-product' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} /> */}
                        <VerbosSimilarApps color='custom-collection-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-collection' text='View All Our Projects' />

                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default CollectionPortfolio;
