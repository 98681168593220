import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import { FaGlobe } from "react-icons/fa";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

const SocialShare = [
    { Social: <FaGlobe />, link: ' https://www.razabooks.com/' }
]
const userButtonNames = ['Homepage', 'Registration', 'Login', 'All Books', 'Books With Language Filter', 'Books With Filter'];
const userImageAddress = [
    '/assets/images/portfolio/online-book/books-1.webp',
    '/assets/images/portfolio/online-book/books-5.webp',
    '/assets/images/portfolio/online-book/books-6.webp',
    '/assets/images/portfolio/online-book/books-2.webp',
    '/assets/images/portfolio/online-book/books-3.webp',
    '/assets/images/portfolio/online-book/books-4.webp',
];

const adminButtonNames = ['Dashboard', 'Product List', 'Sales', 'List Sales', 'Pos Sales', 'Deliveries', 'List Gift Cards', 'List Quotation', 'Add Quotation', 'List Purchase', 'List Expenses'];
const adminImageAddress = [
    '/assets/images/portfolio/online-book/admin-1.webp',
    '/assets/images/portfolio/online-book/admin-2.webp',
    '/assets/images/portfolio/online-book/admin-3.webp',
    '/assets/images/portfolio/online-book/admin-4.webp',
    '/assets/images/portfolio/online-book/admin-5.webp',
    '/assets/images/portfolio/online-book/admin-6.webp',
    '/assets/images/portfolio/online-book/admin-7.webp',
    '/assets/images/portfolio/online-book/admin-8.webp',
    '/assets/images/portfolio/online-book/admin-9.webp',
    '/assets/images/portfolio/online-book/admin-10.webp',
    '/assets/images/portfolio/online-book/admin-11.webp',
];

class OnlineBookShopPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Online Book Shop || Noorisys Technologies Pvt Ltd' pageUrl='/online-book' metaTitle="Online Book Shop" metaImage="/assets/images/portfolio/dp-portfolio-14.jpg"
                    metaDescription="Online Book Shop is a platform for selling all kinds of books online. You can purchase the books by category and language." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Online Book Shop"
                    customColor="custom-online-book-color"
                    dataBlackOverlay={7}
                    bgImage="bg_image--53"
                    p="WEB Application"
                    socialShare={SocialShare}
                    pb='pb--120'
                />

                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details pt--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>WEB Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>eCommerce</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>Php</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title custom-online-book-color">Online Book Shop</h3>
                                        <p className="justifypara">Online Book Shop is a system for selling the all kinds of books online. You can purchase the books by the Category and Language. This project deals with developing an e-commerce website for Online Book Sale. It provides the user with a catalog of different books available for purchase in the store. In order to facilitate online purchase a shopping cart is provided to the user. A customer can create, sign in to his account, place items into a shopping cart and purchase using his credit card details.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color={'#2196f3'} />Clean navigational menu with an In-Site Search and books categories</li>
                                            <li><FiCheck color={'#2196f3'} />Books descriptions</li>
                                            <li><FiCheck color={'#2196f3'} />Good quality books photos with thumbnails</li>
                                            <li><FiCheck color={'#2196f3'} />Easy Registration and sign in process</li>
                                            <li><FiCheck color={'#2196f3'} />Mobile responsiveness</li>
                                            <li><FiCheck color={'#2196f3'} />Various payment methods</li>
                                            <li><FiCheck color={'#2196f3'} />Tracking option for orders</li>
                                            <li><FiCheck color={'#2196f3'} />Multi-Language</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-online-book'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 pb--30">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='User Panel'
                                    p='Users can search, filter, add to favourite, add to cart and purchase books.'
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt='online-book user'
                                    color='online-book'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-online-book'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='Admin can manage products, sales, deliveries, quotations, purchases and other platform settings.'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='online-book admin'
                                    color='online-book'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-online-book'} paddingTop={'pt--30'} paddingBottom={'pb--30'} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-online-book-color' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-online-book' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default OnlineBookShopPortfolioDetails;
