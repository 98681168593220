import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
// import ServiceCenterAppDemo from "./ServiceCenterAppDemo";
//  import CoursierDelivrable from "./CoursierDeliverables";
// import PlatformUsed from "../component/Verbos/PlatformUsed";
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const CommonMasonry = React.lazy(() => import('./CommonMasonry'))
const PortfolioList = [
    {
        image: 'service-center-01',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'service-center-02',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'service-center-03',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
]
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
// const technologies = [

//     { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
//     { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
//     { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
//     { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

// ]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]
class ServiceCenterPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Service center booking system' pageUrl='/service-center-booking-system' metaTitle="Service Center Booking System" metaImage="/assets/images/portfolio/dp-portfolio-39.webp"
                    metaDescription="Book service appointments for your bike with our mobile app. Browse products, make inquiries, view available appointments, receive messages, and more." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    p={'Android Application'}
                    titlehtml={'Service Center <br /> Booking System'}
                    bgImage={'bg_image--78'}
                    dataBlackOverlay={7}
                    customColor={'custom-service-center-color'}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Android Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>Service Center Booking System</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>Android</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title custom-service-center-color">Service Center Booking System</h3>
                                        <p className="justifypara">This app is developed for Service center booking system - An authorized dealer based in Aurangabad. This app is developed for provding services to clients based in Aurangabad and around. Using this application, a client can check the available products, merchandizes and Enquiry for Bikes. Users can also surf through the list of available products, call service center, receive messages from the dealer etc.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck />Clean navigational menu with an In-App Search and product categories</li>
                                            <li className="justifypara"><FiCheck />Detailed product descriptions</li>
                                            <li className="justifypara"><FiCheck />Good quality product photos with image zoom and thumbnails</li>
                                            <li className="justifypara"><FiCheck />Customer rating</li>
                                            <li className="justifypara"><FiCheck />We keep doing the checking and sorting our products before having them in our App.</li>
                                            <li className="justifypara"><FiCheck />Cover in this app complete vehicle details</li>
                                            <li className="justifypara"><FiCheck />App find google map showroom location</li>
                                            <li className="justifypara"><FiCheck />Find vehical selec model no and mobile no and get details</li>
                                            <li className="justifypara"><FiCheck />And many more....</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-service-center'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30"
                // style={{backgroundColor:'#B71A25'}}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h3 className="title">Screenshots</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" PortfolioList={PortfolioList} folder={'service-center'} />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-service-center'} paddingTop={'pt--30'} />
                    <div className="pt--30">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <VerbosSimilarApps color='custom-service-center-color' title='Apps' items={similarapps} slidestoshow={[3, 3]} />
                        </React.Suspense>
                    </div>
                    <CommonPortfolioButtons class='btn-service-center' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default ServiceCenterPortfolioDetails;
