import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { AppPortfolio } from "../page-demo/script";
import './commonproductweb.css';

const ProductCommonApp = (props) => {
    const slider = useRef(null);
    const [clickedButtonIndex, setClickedButtonIndex] = useState(0);

    const handleButtonClick = (index) => {
        setClickedButtonIndex(index);
        slider.current.slickGoTo(index);
    };
    console.log(props.color)
    return (
        <section className="common_product_app_screens">
            <div className="container px-0">
                <div className="row no-gutters align-items-center m-3">
                    <div className="col-12 col-md-4 pb-md-5">
                        <div className="intregration_content px-0">
                            <h3>{props.h3}</h3>
                            <p className="justifypara">{props.p}</p>
                            <div className="slider-button w-100">
                                <div id="user-app" className="row w-100">
                                    {props.buttonNames.map((data, index) => (
                                        <div className="col-12 col-md-6" key={index}>
                                            <a
                                                className={`slider_btn btn btn-primary btn_six ${props.color} mb-2 ${clickedButtonIndex === index ? "active" : ""
                                                    }`}
                                                title={data}
                                                onClick={() => handleButtonClick(index)}
                                            >
                                                <span>{data}</span>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-8 pl-lg-5">
                        <div className={`slider_box add-margin`}>
                            <div className="mobile_frame">
                                <img
                                    src="assets/images/user/Frame.png"
                                    className="img-fluid"
                                    alt="food frame"
                                />
                            </div>
                            <Slider
                                ref={slider}
                                {...AppPortfolio}
                                className="owl-carousel owl-carousel1"
                            >
                                {props.image.map((data, index) => (
                                    <div key={index}>
                                        <img
                                            className="carousel-item-image"
                                            src={data}
                                            alt={`${props.alt} ${index + 1}`}
                                            loading="lazy"
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProductCommonApp;
