import React, { Component, Fragment } from "react";
import Slider from "react-slick";

class CoursierDelivrable extends Component {
    constructor(props) {
        super(props);
        this.sliderRef = React.createRef();
    }
    handleNextClick = () => {
        if (this.sliderRef.current && !this.sliderRef.current.slickNext()) {
            return;
        }
        this.sliderRef.current.slickNext();
    };

    handlePreviousClick = () => {
        if (this.sliderRef.current && !this.sliderRef.current.slickPrev()) {
            return;
        }
        this.sliderRef.current.slickPrev();
    };

    render() {
        const DeliverPortfolio = {
            // loop: false,
            // margin: 15,
            // nav: false,
            // owlDots: false,
            // autoWidth: true,
            // items: 1, 
            // dots: false,
            infinite: true,
            autoplay: true,
            swipeable: true,
            // slidesToShow: this.props.value[0],
            slidesToShow: this.props.value[0],
            slidesToScroll: 1,
            centerMode: false,
            // arrows: true,
            // nextArrow: <SampleNextArrow />,
            // prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 350,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '50px',
                        slidesToShow: 1,
                        dots: false
                    },
                },
                {
                    breakpoint: 426,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '50px',
                        slidesToShow: 2,
                        dots: false
                    },
                },
                {
                    breakpoint: 650,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '50px',
                        slidesToShow: 3,
                        dots: false
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '50px',
                        slidesToShow: this.props.value[2],
                        dots: false
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '50px',
                        slidesToShow: this.props.value[1],
                        dots: false
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '50px',
                        slidesToShow: this.props.value[1],
                        dots: false
                    },
                },
                {
                    breakpoint: 1250,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '50px',
                        slidesToShow: this.props.value[0],
                        dots: false
                    },
                },
                {
                    breakpoint: 1440,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '100px',
                        slidesToShow: this.props.value[0],
                        dots: false
                    },
                },
            ],
        }
        return (
            <Fragment>
                <div className="app-info">
                    <div className="body_wrapper">

                        <section className="get_started_area app_deliverables" style={{ width: '100%', overflow: 'hidden' }}>
                            <div className="background">
                                <div className="shap one"></div>
                                <div className="shap two"></div>
                                <div className="shap one three"></div>
                                <div className="shap two four"></div>
                            </div>
                            <div className="app_deliverables_content">
                                <div className="container">
                                    <div className="row">
                                        <div className="hosting_title erp_title text-center mb-0 text-center col-12 pb-5">
                                            <h2 style={{ color: '#fff' }} className="title" data-wow-delay="0.1s">
                                                {this.props.title}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="deliverables_item">
                                <div className="row">
                                    {/* <div className="col-12 col-md-12 col-lg-5"></div> --> */}
                                    <div className="col-12 col-md-12 col-lg-12 " style={{ position: 'relative' }}>
                                        <Slider ref={this.sliderRef} {...DeliverPortfolio} className="deliverables_carousel owl-carousel owl-theme">
                                            {this.props.items.map((value, index) => (
                                                <div key={index} className="item  wow  fadeInUp" data-wow-delay="0.1s"  >
                                                    <div className="item_inner"  >
                                                        <div className={this.props.class}>
                                                            {/* <img src={value.item_bg} /> */}
                                                            <img src={`/assets/images/deliverables/${value.item_bg}`} alt='coursier' />
                                                        </div>
                                                        <div className="app_icon">
                                                            {/* <img src={value.item_icon} className="img-fluid" /> */}
                                                            <img src={`/assets/images/deliverables/${value.item_icon}`} className="img-fluid" alt="deliver" />
                                                        </div>
                                                        <h3 style={{ marginLeft: this.props.margin ? '-17px' : '0px', fontSize: '14px' }}>{value.h3}</h3>
                                                        {/* <h3>User App</h3> */}
                                                        <h4>{value.h4}</h4>
                                                        {/* <h4>Android</h4> */}
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>

                                </div>
                            </div>

                        </section>

                    </div>

                </div>
            </Fragment>
        )
    }
}
export default CoursierDelivrable;