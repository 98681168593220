import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";

class Swift extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Swift || Noorisys Technologies Pvt Ltd' pageUrl='/swift' metaTitle="Swift" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Get your iOS applications in high-performance and scalable Swift language developed by Noorisys. Our Swift developers will build robust and efficient applications for you." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title='Swift'
                    dataBlackOverlay={5}
                    bgImage='bg_image--25'
                    buttonTitle={'Hire Now'}
                    buttonLink={'/contact-us'}
                    p='Our Swift App developers are highly experienced in delivering critical applications in various industries.'
                />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        <p className="justifypara">Swift is a programming language for iOS, macOS, watchOS, tvOS, and Linux applications. Noorisys focuses on providing clients pioneering solutions that go a long way in helping improve the profitability of their business.</p>
                                        <p className="justifypara">By following all client specifications, we develop iOS apps that are custom built for satisfying all client requirements, meeting their prospects and achieving their objectives. At the same time, we assure that their end users are not disappointed - they are user oriented and have kept their expectations in mind as well.</p>

                                        <h4 className="title">EXPERTISE AND EXPERIENCE</h4>
                                        <p className="justifypara">We bring to our Swift applications a high degree of scalability and innovation, which is a requirement for clients who want to use them for improving their business procedure, productivity, decision making or improving brand awareness.</p>
                                        <p className="justifypara">These two qualities are an output of the expertise and experience of our developers on the latest version of the iOS platform. They have proven competence in all aspects of iOS development, which enables them to satisfy all customers expectations through intuitive apps that take user interaction to another level.</p>

                                        <h4 className="title">PROFESSIONAL SERVICES</h4>
                                        <p className="justifypara">It is a spirit of professionalism that allows us to follow a culture of continuous learning and development, which enables us to use the latest iOS SDKs and all the appropriate tools to bring innovative and progressive applications to our clients. From formulation to submission, we have the proven ability to deliver professional solutions that provide an extraordinary user experience.</p>

                                        <h4 className="title">ALWAYS CUTTING EDGE</h4>

                                        <p className="justifypara">Our applications are cutting edge to the point that they don’t lose their accessibility. With our great experience in the integrated development environment provided by the iOS, we create apps that stand out that are not just known for their advanced features, but also their user sociability and the fact that end users can optimize their use. This in essence, is what cutting edge means for GFL Technologies.</p>

                                        <h4 className="title">OUR AREAS OF EXPERTISE ARE</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />iPhone Application Development</li>
                                            <li><FiCheck />Business Applications & Sales Applications Development</li>
                                            <li><FiCheck />iPhone Game Development</li>
                                            <li><FiCheck />iPhone Widgets Development</li>
                                            <li><FiCheck />Themes, Mocks, and Icons Designing</li>
                                            <li><FiCheck />Web Services Integration For iPhone</li>
                                            <li><FiCheck />BMobile Web Development iPhone</li>
                                            <li><FiCheck />Social Networking Application Development For iPhone</li>
                                            <li><FiCheck />iPhone Mobile commerce solution</li>
                                            <li><FiCheck />Weather forecast Applications</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>
                {/* End Page Wrapper  */}




                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Swift;