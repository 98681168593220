import React from 'react';

const ServiceSection = ({ title, subtitle, description, services, bgColor }) => (
    <div className={`service-area ptb--30 ${bgColor}`}>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title text-center mb--30">
                        <h2>{title}</h2>
                        {subtitle && <h3>{subtitle}</h3>}
                        <p dangerouslySetInnerHTML={{ __html: description }}></p>
                    </div>
                </div>
            </div>
            <div className="row service-one-wrapper">
                {services.map((val, i) => (
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                        <div className="service service__style--2">
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h3 className="title">{val.title}</h3>
                                <p>{val.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default ServiceSection;
