import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

// const SocialShare =[
//     {Social:<FaGlobe />,link:' https://annachi.fr/ '}
// ]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'PHP', p: 'Admin Panel', img: 'php.png' },
    { h5: 'CodeIgniter', p: 'Backend Development', img: 'ci4.png' },
    { h5: 'Mysql', p: 'Database', img: 'mysql.png' },
    // { h5: 'Native  Android', p: 'App Development', img: 'native.png' },
    { h5: 'Flutter', p: 'App Development', img: 'flutter.png' },
]

const userButtonNames = ['Register', 'Login', 'Home', 'Create Group', 'Chat', 'Profile'];
const userImageAddress = [
    '/assets/images/portfolio/social-media-platform/user-01.webp',
    '/assets/images/portfolio/social-media-platform/user-02.webp',
    '/assets/images/portfolio/social-media-platform/user-03.webp',
    '/assets/images/portfolio/social-media-platform/user-04.webp',
    '/assets/images/portfolio/social-media-platform/user-05.webp',
    '/assets/images/portfolio/social-media-platform/user-06.webp',
];
class SocialMediaPlatformPortfolio extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Social Media Platform' pageUrl='/social-media-platform' metaTitle="This social media platform is designed to offer a comprehensive and dynamic user experience with a wide array of features catering to diverse interests. " />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Social Media Platform"
                    customColor="custom-social-media-platform-portfolio"
                    dataBlackOverlay={5}
                    bgImage="bg_image--119"
                    p="Web Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-social-media-platform-portfolio">Social Media Platform</h3>
                                        <p className="justifypara">This social media platform is designed to offer a comprehensive and dynamic user experience with a wide array of features catering to diverse interests. The News Feed keeps users abreast of the latest updates from friends and pages, while the Ad Post feature facilitates seamless promotion and advertising. Users can express their creativity through Albums and Reels, curate content with Saved Posts, and explore trending topics with Popular Posts. </p>
                                        <p className="justifypara">The platform promotes community building through personalized groups and pages, fosters meaningful discussions in Forums, and keeps users informed about upcoming events and job opportunities. Additionally, users can discover and share multimedia content like Movies, connect with friends through the Find Friends feature, and engage in real-time conversations with the Chat functionality. The platform also incorporates interactive elements such as Followers, Likes, and Comments, providing a vibrant and engaging social networking environment.</p>
                                        <h3 className="custom-social-media-platform-portfolio">Features</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#DD377C" />News Feed</li>
                                            <li><FiCheck color="#DD377C" />Like </li>
                                            <li><FiCheck color="#DD377C" />Share</li>
                                            <li><FiCheck color="#DD377C" />Comments</li>
                                            <li><FiCheck color="#DD377C" />Add Post</li>
                                            <li><FiCheck color="#DD377C" />Albums</li>
                                            <li><FiCheck color="#DD377C" />Reels</li>
                                            <li><FiCheck color="#DD377C" />Saved Posts</li>
                                            <li><FiCheck color="#DD377C" />Popular Posts</li>
                                            <li><FiCheck color="#DD377C" />My Groups</li>
                                            <li><FiCheck color="#DD377C" />My Pages</li>
                                            <li><FiCheck color="#DD377C" />Blog</li>
                                            <li><FiCheck color="#DD377C" />Events</li>
                                            <li><FiCheck color="#DD377C" />Forum</li>
                                            <li><FiCheck color="#DD377C" />Movies</li>
                                            <li><FiCheck color="#DD377C" />Jobs</li>
                                            <li><FiCheck color="#DD377C" />Find friends</li>
                                            <li><FiCheck color="#DD377C" />Chat</li>
                                            <li><FiCheck color="#DD377C" />Followers</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-social-media-platform'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-social-media-platform-portfolio' items={technologies} />
                        </React.Suspense>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-social-media-platform'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 pb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='User Panel'
                                    p={`Users on this social media platform enjoy a versatile experience, engaging with features like News Feed, Ad Posts, Reels, and more. Whether connecting with friends, exploring content, or participating in forums, the platform caters to diverse interests and interactions.`}
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt="user"
                                    color='social-media-platform'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-social-media-platform'} paddingTop={'pt--30'} paddingBottom={'pb--30'} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-social-media-platform-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-social-media-platform' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default SocialMediaPlatformPortfolio;
