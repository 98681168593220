import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'Next JS', p: 'User Panel', img: 'nextjs.png' },
    { h5: 'PHP', p: 'Admin Panel', img: 'php.png' },
    { h5: 'CodeIgniter', p: 'Backend Development', img: 'ci4.png' },
    { h5: 'Mysql', p: 'Database', img: 'mysql.png' },
]

const adminButtonNames = ['Login', 'Dashboard', 'Users', 'Sub Admins', 'Add Sub Admin', 'Roles', 'Embassy', 'Visa Packages', 'Add Visa Packages', 'Profile', 'Cancellation Policies', 'Edit Cancellation Policy'];
const adminImageAddress = [
    '/assets/images/portfolio/ota/admin/admin-01.webp',
    '/assets/images/portfolio/ota/admin/admin-02.webp',
    '/assets/images/portfolio/ota/admin/admin-03.webp',
    '/assets/images/portfolio/ota/admin/admin-11.webp',
    '/assets/images/portfolio/ota/admin/admin-04.webp',
    '/assets/images/portfolio/ota/admin/admin-05.webp',
    '/assets/images/portfolio/ota/admin/admin-06.webp',
    '/assets/images/portfolio/ota/admin/admin-12.webp',
    '/assets/images/portfolio/ota/admin/admin-07.webp',
    '/assets/images/portfolio/ota/admin/admin-08.webp',
    '/assets/images/portfolio/ota/admin/admin-10.webp',
    '/assets/images/portfolio/ota/admin/admin-09.webp',
];

const userButtonNames = ['Home', 'Cab', 'Umrah', 'Umrah Details', 'Ziyarat', 'Ziyarat Details'];
const userImageAddress = [
    '/assets/images/portfolio/ota/user/user-01.webp',
    '/assets/images/portfolio/ota/user/user-02.webp',
    '/assets/images/portfolio/ota/user/user-03.webp',
    '/assets/images/portfolio/ota/user/user-05.webp',
    '/assets/images/portfolio/ota/user/user-04.webp',
    '/assets/images/portfolio/ota/user/user-06.webp',
];

class OTAPortfolio extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Online Travel Agency' pageUrl='/online-travel-agency' metaTitle="Digital Solution For Civil Documents" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription="This platform seamlessly connects millions of travellers around the world with memorable experiences, a variety of transportation options, and incredible places to stay – from homes to hotels, and much more." />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Online Travel Agency"
                    customColor="custom-queue-management-portfolio"
                    dataBlackOverlay={5}
                    bgImage="bg_image--114"
                    p="Web Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-queue-management-portfolio">Online Travel Agency</h3>
                                        <p className="justifypara">The Online Travel Agency Platform offers a seamless experience for users to explore and book Umrah and Ziyarat Packages. Additionally, users can easily search for cab services at their destination by specifying arrival and departure details along with the number of passengers. The platform further streamlines the travel process by enabling users to search for specific countries for visa requirements through a convenient search bar. This comprehensive system enhances the travel planning experience, providing users with a one-stop solution for their pilgrimage and travel needs.</p>
                                        <h3 className="custom-queue-management-portfolio">Features</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#586bbc" />View and book Umrah Packages</li>
                                            <li><FiCheck color="#586bbc" />View and book Ziyarat Packages</li>
                                            <li><FiCheck color="#586bbc" />Search and book cab services</li>
                                            <li><FiCheck color="#586bbc" />Search and book visa</li>
                                            <li><FiCheck color="#586bbc" />Hotel Booking</li>
                                            <li><FiCheck color="#586bbc" />Flight Booking</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-ota'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-queue-management-portfolio' items={technologies} />
                        </React.Suspense>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-ota'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 pb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='User'
                                    p={`Users can effortlessly navigate and customize their travel experience on the Online Travel Agency Platform, with features like booking Umrah and Ziyarat Packages, searching for cab services, and exploring visa requirements for specific countries.`}
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt="BTK provider"
                                    color='ota-user'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-ota'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin'
                                    p={`The admin of the Online Travel Agency platform manages and oversees package bookings, cab services, visa information, and ensures the seamless functioning of the entire travel system.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt="BTK admin"
                                    color='queue-management-admin'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-ota'} paddingTop={'pt--30'} paddingBottom={`pb--20`} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-queue-management-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-ota' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default OTAPortfolio;
