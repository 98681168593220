import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import PlatformUsed from "../component/Verbos/PlatformUsed";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const CommonProductWeb = React.lazy(() => import("../portfolio/CommonProductWeb"));


// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
const technologies = [

    { h5: '', p: '', img: '' },
    { h5: 'Angular', p: 'User Panel Development', img: 'angular.png' },
    { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
    { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/online-shop', img: 'shop.webp', h2: 'EasyShop', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
]
const adminImageAddress = [
    '/assets/images/our-products/helpdesk-ticketing-platform/admin/admin-01.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/admin/admin-02.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/admin/admin-03.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/admin/admin-04.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/admin/admin-05.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/admin/admin-06.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/admin/admin-07.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/admin/admin-08.webp',
];
const adminButtonNames = [
    'Dashboard', 'Profile', 'Recent Tickets', 'Total Tickets', 'Article List', 'Feature List', 'Customers', 'Settings'
];
const userImageAddress = [
    '/assets/images/our-products/helpdesk-ticketing-platform/user/user-01.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/user/user-09.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/user/user-10.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/user/user-11.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/user/user-12.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/user/user-13.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/user/user-02.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/user/user-03.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/user/user-04.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/user/user-05.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/user/user-06.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/user/user-07.webp',
    '/assets/images/our-products/helpdesk-ticketing-platform/user/user-08.webp',

];
const userButtonNames = [
    'Home', 'Knowledge', `FAQ's`, 'Ticket Support', 'Guest Ticket', 'Contact us', 'Register', 'Login', 'Dashboard', 'Profile', 'Create Tickets', 'Active Tickets', 'All Notifications'
];

class HelpDeskProduct extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Helpdesk Ticketing Platform' pageUrl='/helpdesk-ticketing-platform' metaTitle="comprehensive platform for handling customer queries, managing support tickets, and facilitating efficient communication and collaboration between customers and support agents." metaImage="/assets/images/portfolio/dp-portfolio-32.webp"
                    metaDescription="comprehensive platform for handling customer queries, managing support tickets, and facilitating efficient communication and collaboration between customers and support agents." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Helpdesk Ticketing Platform"
                    // customColor="custom-helpdesk-product"
                    customColor="theme-gradient"
                    dataBlackOverlay={6}
                    bgImage="bg_image--125"
                    p="Web Application"
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />

                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Android Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>Asset Management</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>Android, Java</h6>
                                            </div> 
                                    </div>*/}
                                        <h3 className="rn-page-title custom-helpdesk-product">Helpdesk Ticketing Platform</h3>
                                        <p className="justifypara">This is a comprehensive platform for handling customer queries, managing support tickets, and facilitating efficient communication and collaboration between customers and support agents.</p>
                                        <h4 className="title">User Functionalities </h4>
                                        <p>Users can create, manage and monitor support tickets efficiently. They can track the status of their tickets, whether they are active, closed, or on hold, through a comprehensive dashboard.</p>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#406CFF'} />Dashboard</li>
                                            <li className="justifypara"><FiCheck color={'#406CFF'} />Registration and Login</li>
                                            <li className="justifypara"><FiCheck color={'#406CFF'} />Profile Management</li>
                                            <li className="justifypara"><FiCheck color={'#406CFF'} />Password Management</li>
                                            <li className="justifypara"><FiCheck color={'#406CFF'} />Dashboard Overview</li>
                                            <li className="justifypara"><FiCheck color={'#406CFF'} />Ticket Creation and Management</li>
                                            <li className="justifypara"><FiCheck color={'#406CFF'} />View Active, Closed and On-Hold Tickets</li>
                                            <li className="justifypara"><FiCheck color={'#406CFF'} />Ticket Details and History</li>
                                        </ul>

                                        <h4 className="title">Super Admin Panel Functionalities</h4>
                                        <p>The super admin panel controls user accounts, tickets, articles and system settings.</p>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#406CFF'} />Dashboard</li>
                                            <li className="justifypara"><FiCheck color={'#406CFF'} />Customer Details Oversight</li>
                                            <li className="justifypara"><FiCheck color={'#406CFF'} />Global Ticket Oversight</li>
                                            <li className="justifypara"><FiCheck color={'#406CFF'} />Article and Category Management</li>
                                            <li className="justifypara"><FiCheck color={'#406CFF'} />Roles and Permissions</li>
                                            <li className="justifypara"><FiCheck color={'#406CFF'} />Employee/Support Agent/Admin Management</li>
                                            <li className="justifypara"><FiCheck color={'#406CFF'} />Deleted Ticket Management</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <div className="d-sm-block d-flex justify-content-center align-items-center pt--30">
                                            <button className="get-a-quote btn-helpdesk-ticketing text-center" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-helpdesk-product' items={technologies} />
                        </React.Suspense>
                        <div className="d-flex justify-content-center align-items-center pt--30">
                            <button className="get-a-quote btn-helpdesk-ticketing text-center" onClick={this.showPopup}>
                                <span>Get a quote </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Super Admin Panel'
                                    p='Users can create, manage and monitor support tickets efficiently. They can track the status of their tickets, whether they are active, closed, or on hold, through a comprehensive dashboard.'
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt='helpdesk user'
                                    color='helpdesk-product'
                                />
                            </React.Suspense>

                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-helpdesk-ticketing text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Super Admin Panel'
                                    p='The super admin panel controls user accounts, tickets, articles and system settings.'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='helpdesk super admin'
                                    color='helpdesk-product'
                                />
                            </React.Suspense>

                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center ptb--30">
                        <button className="get-a-quote btn-helpdesk-ticketing text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-helpdesk-product' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                </div>
                <CommonPortfolioButtons class='get-a-quote btn-helpdesk-ticketing' text='View All Our Products' link='/our-products' padding='pb--20' />

                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment >
        )
    }
}
export default HelpDeskProduct;
