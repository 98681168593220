import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { videoTagString, VideoTag } from 'react-video-tag';
import { Link } from "react-router-dom";

import Helmet from "../component/common/Helmet";
import ServiceDetails from "../creative_homePage/ServiceDetails";
import IndustriesClientReveiw from "./IndustriesClientReveiw";
// import VerbosSimilarApps from "../component/Verbos/VerbosSimilarApps";
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const NoorisysVideo = React.lazy(() => import('./NoorisysVideo'));
videoTagString({ src: '/assets/images/service/video.mp4', poster: '/assets/images/bg/bg-image-24.jpg' })

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        title: 'Ecommerce Platform Development',
        description: 'World population has crossed the 8 billion mark. You have a lot to sell on this planet. Start your eCommerce business now!',
        buttonText: 'Contact Us',
        buttonLink: '/contact-us'
    }
]

const ecomTest = [
    {
        textPosition: 'inner text-center',
        bgImage: 'bg_image--28',
        category: "'Highly recommended- Noorisys responded quickly and worked through every issue that arose during testing, delivering a great final product that is stable and performs exactly as specified. It is now live on Google Play, and receiving a very positive response from users, no small feat for a new app! I would definitely work with them again. Wonderful job!'",
        title: '',
        description: '',
        buttonText: '',
        buttonLink: '',
        name: '- DULITH HERATH',
        img: '/assets/images/client/dulith_herath.webp',
        alt: 'Dulith herath'
    },
]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

const data = [
    {
        title: 'Kapruka',
        description:
            'Kapruka is Sri Lanka’s largest eCommerce system that organizes the products record and the other information about the customers. The project shows the product category and then product details. From the product details, the product can be added to cart and can be bought. The system can be used by multiple users and may grow as time passes. Necessary measures have been taken to make the system as fast as possible.',
        features: [
            'Clean navigational menu with an In-Site Search and product categories.',
            'Detailed product descriptions.',
            'Tracking option for orders.',
            'Engaging and informative About Us page with contact details.',
            'Various payment methods.',
            'Multi-Language.',
            'Greeting Message option on product.',
        ],
        image: '/assets/images/service/Kapruka_img.png',
        alt: 'kapruka',
        h4: true
    },
    {
        title: 'WooCommerce',
        description: [
            'The Collection is a WooCommerce platform that specifically sells apparel for men. The brand promotes the Make-In-India initiative on a large scale.',
            'The main objective is to build an interface that is easily navigated. A simple yet astonishing layout is used that can go well with any theme. The admin panel is enabled to manage the inventory, flick through order processing and generate transaction reports.',
        ],
        features: [
            'Clean navigational menu with an In-Site Search and categories.',
            'Product description.',
            'Easy registration and sign in process.',
            'Mobile responsiveness.',
            'Various payment methods.',
            'Tracking option for orders.',
        ],
        image: '/assets/images/service/Ecommerce_for_apparels.png',
        alt: 'collection',
        h4: true
    },
    {

        title: 'Sandwich Application',
        description: 'Annachi is a grocery buying and selling platform that sells Indian groceries for the French market. It helps customers to choose and browse the products at their convenient time and place the order with peace of mind. It sells everything from spices, sauces, seasonal fruits and vegetables, snacks, cereals and grains, beverages to many more. The aim is to provide the best quality hygienic foods to the customers in France who are longing to devour Indian flavors.',
        features: [
            'Good quality product photos with image zoom and thumbnails.',
            'Multiple payment options.',
            'Prime customer features.',
            'Easy to browse.',
            'Shipping.',
            'Recipes.',
            'Alerts and notifications.',
        ],
        image: '/assets/images/service/Sandwich_app_Annachi_img.png',
        alt: 'annachi',
        h4: true
    },
]

const data2 = [
    {
        title: 'Data Security',
        noFeature: true,
        features: [
            'We understand the sensitive nature of data.',
            'Noorisys is an ISO 27001 compliant organisation.',
            'Your information and healthcare data is safe with us.',
            'Successful performance in each and every penetration testing.',
            'We have been assessed for GDPR compliance and policy frameworks.',
            'Regular audit by external agencies and assessment is done by stakeholders.',
            'Being an ISO certified organisation we handle data with much care and diligence.',
            'Strict Access Control Policy to protect any possible threat to data and information.',
        ],
        image: '/assets/images/service/service-14.webp',
        alt: 'data security',
        whatasApp: 'Schedule a call with our security expert',
        whatasAppText: 'schedule a call with security expert'
    },
    {
        title: 'All in One Solution',
        noFeature: true,
        features: [
            'What you exactly require is all here under one roof.',
            'Do not worry about the complex onboarding process.',
            'Skip the endless interviews, we have done that for you.',
            'An experienced team for you from the prominent institutes.',
            'Perfect team coordination, quick development and faster delivery.',
            'Our all in one solution reduces your efforts in project management.',
            'Hire a trustworthy and cost effective resource that speaks your language.',
            'No more new-hire paperwork, we already found and vetted the best talents.',
        ],
        image: '/assets/images/service/service-15.webp',
        alt: 'all in one solutions',
        whatasApp: 'Schedule a meeting with our Business Analyst',
        whatasAppText: 'schedule a call with business analyst'
    },
    {
        title: 'Trusted',
        noFeature: true,
        features: [
            'Trusted by the leading organisations in the world.',
            'Carrying the badge of trust for more than 11 years.',
            'Hired by semi-government and government agencies.',
            'Working with reputed brands of healthcare and fitness sector.',
            'Endorsed by more than 30 well known names of the healthcare industry.',
            'We believe in the professional ethics and cultural values driven by people.',
            'Our long journey and client history tells our good will status in the market.',
        ],
        image: '/assets/images/service/service-16.webp',
        alt: 'trusted',
        whatasAppText: 'schedule a call for partner with you',
        whatasAppButton: 'Partner with us today'
    },
]

class eCommerce extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <Fragment>
                <Helmet pageTitle="e-Commerce || Noorisys Technologies Pvt Ltd" pageUrl='/ecommerce' metaTitle="eCommerce" metaImage="/assets/images/bg/bg-image-28.jpg"
                    metaDescription="Enhance your e-commerce business with Noorisys' expertise. From custom website development to payment gateway integration, we have the solution for you." />
                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center" key={index} data-black-overlay="6">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-8">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="video-background">
                                <VideoTag autoPlay={`${true}`} muted={`${true}`} playsInline={`${true}`} loop={`${true}`} src={`${"/assets/images/service/video.mp4"}`} poster={`${"/assets/images/bg/bg-image-24.jpg"}`} />
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}


                </div>
                {/* End Slider Area   */}



                {/* Start Page Wrapper */}
                <ServiceDetails data={data} bgColor="bg_color--5" padding={`ptb--30`} />
                {/* End Page Wrapper */}

                <IndustriesClientReveiw Testimonial={ecomTest} bgColor={'bg_color--1'} />

                {/* Start Page Wrapper */}
                <ServiceDetails data={data2} bgColor="bg_color--5" />
                {/* End Page Wrapper */}
                <React.Suspense fallback={<div>loading...</div>} >
                    <NoorisysVideo />
                </React.Suspense>







                <div className="portfolio-area ptb--40 bg_color--5">
                    <div className="pt--40">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <VerbosSimilarApps color='theme-gradient' title={'Healthcare Apps'} items={similarapps} slidestoshow={[3, 3]} />
                        </React.Suspense>
                    </div>
                </div>



                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default eCommerce;