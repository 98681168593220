import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FaNode, FaLaravel, FaPython, FaReact, FaWordpress, FaShopify, FaJava, FaSwift, FaApple } from "react-icons/fa";
import { SiAngular, SiCodeigniter, SiFlutter, SiKotlin, SiMongodb, SiOracle } from "react-icons/si";
import { DiDotnet, DiSqllite, DiMysql, DiPostgresql, DiMsqlServer } from "react-icons/di";
import { Link } from 'react-router-dom';
import CommonSliderBanner from "../component/common/CommonSliderBanner";

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Tech-Stack',
        description: 'Latest programming languages and frameworks driving your success',
        description3: 'Take a look at our Tech-Stack',
        description1: '13 years of Technical excellence in Information Technology',
        buttonText: 'Contact Us',
        buttonLink: '/contact-us'
    }
]


const WebDevelopment = [
    {
        icon: <FaNode />,
        title: 'NodeJS',
        description: 'If you’re worried about faster performance and reliability, then, our Node JS development solution is for you.',
        url: '/node-js'
    },
    {
        icon: <DiDotnet />,
        title: '.NET',
        description: 'If you care about resilient software architecture, good app performance and lead the market, you’re at the right place.',
        url: '/dot-net'
    },
    {
        icon: <FaLaravel />,
        title: 'Laravel',
        description: 'Laravel is one of the most popular backend web application frameworks with expressive, elegant syntax.',
        url: '/laravel'
    },
    {
        icon: <SiCodeigniter />,
        title: 'CI 4',
        description: 'CodeIgniter, a proven, agile and open PHP framework, enables programmers to develop next gen dynamic applications.',
        url: '/ci4'
    },
    {
        icon: <FaPython />,
        title: 'Python',
        description: 'Python enables swift and quick task completion, allowing you to merge systems effectively  while handling bulk data at the same time.',
        url: '/python'
    },
    {
        icon: <SiAngular />,
        title: 'Angular',
        description: 'Our Skilled Angular Developers Enable Us to Deliver Highly-Interactive & Data-Driven Angular Web Applications.',
        url: '/angular'
    },
    {
        icon: <FaReact />,
        title: 'ReactJS',
        description: 'Your perfect companion, if you want to build a complex application that needs faster and voluminous data processing with scalability.',
        url: '/react-js'
    },
    {
        icon: <FaWordpress />,
        title: 'WordPress',
        description: 'One of the most effective CMS ever with simple functionality, quick and easy installation, number of themes and plugins features.',
        url: '/wordpress'
    },
    {
        icon: <FaShopify />,
        title: 'Shopify',
        description: 'Build your own e-commerce website with us that is highly SEO optimized, easily customizable with an attractive dashboard.',
        url: '/shopify'
    },
]

const MobileDevelopment = [
    {
        icon: <SiFlutter />,
        title: 'Flutter',
        description: 'Create a smooth and easy UI for both iOS and Android.',
        url: '/flutter'
    },
    {
        icon: <FaReact />,
        title: 'React Native',
        description: 'Now develop mobile apps for both iOS and Android simultaneously. ',
        url: '/react-native'
    },
    {
        icon: <FaJava />,
        title: 'Java',
        description: 'With its ability to move from one computer system to another, Java is one of a kind.',
        url: '/java'
    },
    {
        icon: <FaSwift />,
        title: 'Swift',
        description: 'Most reliable, fast and efficient application development language.',
        url: '/swift'
    },
    {
        icon: <SiKotlin />,
        title: 'Kotlin',
        description: 'Develop cross-platform applications that can run on multiple operating systems.',
        url: '/kotlin'
    },
    {
        icon: <FaApple />,
        title: 'Objective C',
        description: 'High performance app development with more versatility.',
        url: '/objective-c'
    },

]

const Databases = [
    {
        icon: <DiMsqlServer />,
        title: 'MS SQL',
        description: 'One of the most secure database servers which reduces the risk of attacks. It allows communication with relational databases.'
    },
    {
        icon: <DiMysql />,
        title: 'My SQL',
        description: 'One of the most popular database management systems that seamlessly integrates with websites and web applications.'
    },
    {
        icon: <SiMongodb />,
        title: 'MongoDB',
        description: 'Changing the data model and formats without disrupting applications is simplified with low maintenance.'
    },
    {
        icon: <DiPostgresql />,
        title: 'PostgreSQL',
        description: 'With automating the time consuming tasks and cost efficiency while expanding the infrastructure, PostgreSQL is one of a kind.'
    },
    {
        icon: <SiOracle />,
        title: 'Oracle',
        description: 'Store and retrieve information with Oracle that runs on multiple networking protocols and hardware platforms.'
    },
    {
        icon: <DiSqllite />,
        title: 'SQLite',
        description: 'SQLite can run anywhere that can handle low to medium traffic, and secures your system with its less bugs prone ability.'
    },

]
class TechStack extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Tech Stack || Noorisys Technologies Pvt Ltd' pageUrl='/tech-stack' metaTitle=" We cover all the latest technologies." metaImage="/assets/images/bg/bg-image-15.jpg"
                    metaDescription="Stay on top of the latest technologies with Noorisys. We cover almost every technology that an IT company can offer and transform business concepts for our clients." />
                <Header />

                {/* Start Slider Area   */}
                {/* <div className="slider-wrapper">
                    Start Single Slide
                    {SlideList.map((value, index) => (
                        <div className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--15" key={index} data-black-overlay="6">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.description1 ? <p className="description">{value.description1}</p> : ''}
                                            {value.description3 ? <p className="description">{value.description3}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    End Single Slide
                </div> */}
                <CommonSliderBanner
                    classes={'slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--15'}
                    col={'col-lg-12'}
                    titleClass={'theme-gradient'}
                    slideList={SlideList}
                    dataBlackOverlay={6}
                />
                {/* End Slider Area   */}



                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--60 pb--10 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2>Web Development</h2>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {WebDevelopment.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <Link to={val.url}>
                                        <div className="service service__style--8">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--60 pb--10 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2>Mobile Application</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {MobileDevelopment.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <Link to={val.url}>
                                        <div className="service service__style--8">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}


                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--60 pb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Database</h2>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {Databases.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>

                                    <div className="service service__style--8">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default TechStack;