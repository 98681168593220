import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PageHelmet from "./Helmet";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import CommonBreadcrumb from "./CommonBreadcrumb";


class PrivacyPolicy extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Privacy Policy || Noorisys Technologies Pvt Ltd' pageUrl='/privacy-policy' metaTitle="The Ultimate Guide to Chatbots || Noorisys Technologies" metaImage="/assets/images/bg/blog-02.png"
                    metaDescription="Explore the latest trends and insights with our blog. Stay informed and up-to-date with our in-depth articles." />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Privacy Policy"
                    dataBlackOverlay={7}
                    bgImage="bg_image--26"
                />
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p className="justifypara" >At Noorisys Technologies Pvt. Ltd. we are committed to protecting the privacy and security of the personal information of our clients and visitors to our website and mobile applications (collectively, the "Services"). This privacy policy (the "Policy") explains how we collect, use, disclose, and safeguard your information when you use our services. Please read this Policy carefully. By using our services, you consent to our collection, use, disclosure, and safeguarding of your information as described in this Policy.</p>
                                        <p className="justifypara" >We may update this Policy from time to time to reflect changes to our privacy practices. If we make material changes to this Policy, we will notify you by email or by posting a notice on our website prior to the change becoming effective.</p>
                                        <h4 className="title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.	What Information Do We Collect?</h4>
                                        <h4 className="title"> 1.1 Personal Information </h4>
                                        <p className="justifypara" >When you use our services, we may collect personal information from you, such as your name, email address, phone number, company name, job title, and billing information. We may also collect information about your use of our services, such as your IP address, device type, browser type, operating system, and referring URLs.</p>
                                        <h4 className="title"> 1.2 Non-Personal Information</h4>
                                        <p className="justifypara" >We may also collect non-personal information about you, such as demographic information or information about your use of our services. This information cannot be used to identify you personally.</p>

                                        <h4 className="title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.	How Do We Use Your Information?</h4>
                                        <h4 className="title">2.1 Personal Information</h4>
                                        <p className="justifypara" >We may use your personal information for the following purposes:</p>
                                        <ul className="list-style">
                                            <li className="justifypara" >To provide and improve our services, including customizing your user experience and responding to your inquiries and requests;</li>
                                            <li className="justifypara" >To communicate with you about our services, including sending you information about updates, new features, and promotions;</li>
                                            <li className="justifypara" >To process your payments and transactions;</li>
                                            <li className="justifypara" >To comply with applicable laws and regulations</li>
                                            <li className="justifypara" >For other purposes that are reasonably necessary to provide our services.</li>
                                        </ul>
                                        <br />
                                        <h4 className="title">2.2 Non-Personal Information</h4>
                                        <p className="justifypara" >We may use non-personal information for any purpose, including to improve our services and for marketing and advertising purposes.</p>
                                        <h4 className="title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.	How Do We Protect Your Information?</h4>
                                        <p className="justifypara" >We implement a variety of security measures to protect your information from unauthorized access, use, or disclosure. We use encryption to protect sensitive information transmitted online, and we protect your information offline by restricting access to our systems and data centers. </p>
                                        <h4 className="title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.	How Do We Share Your Information?</h4>
                                        <p className="justifypara" >We do not share your personal information with third parties except as described in this Policy. We may share your personal information with our service providers, such as payment processors and hosting providers, to provide our services. We may also share your personal information with our business partners, such as marketing and advertising partners, to promote our services. We may disclose your personal information if required by law or in response to a court order or subpoena.</p>
                                        <h4 className="title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.	Third-Party Links and services</h4>
                                        <p className="justifypara" >Our services may contain links to third-party websites or services. We are not responsible for the privacy practices of those websites or services, and we encourage you to read the privacy policies of each website or service you visit.</p>
                                        <h4 className="title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.	Children's Privacy</h4>
                                        <p className="justifypara" >Our services are not intended for children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are under the age of 13, please do not provide us with any personal information.</p>
                                        <h4 className="title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.	Your Choices and Rights</h4>
                                        <p className="justifypara" >You may have certain choices and rights regarding your personal information, including the right to access, correct, or delete your personal information, and the right to object to or restrict certain processing of your personal information. To exercise these rights, please contact us using the contact information provided below.</p>
                                        <h4 className="title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.	Changes to this Privacy Policy</h4>
                                        <p className="justifypara" >We may update this Policy from time to time to reflect changes to our privacy practices. The effective date of the current version of this Policy is noted above. If we make material changes to this Policy, we will notify you by email or by posting a notice on our website prior to the change becoming effective.</p>
                                        <h4 className="title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9.	Contact Us</h4>
                                        <p className="justifypara" >If you have any questions or concerns about this policy, please contact us at:</p>
                                        <p className="justifypara" >Noorisys Technologies Pvt. Ltd. </p>
                                        <p>mail to us at :<a className='btn-transparent rn-btn-dark' href="mailto:info@noorisys.com">info@noorisys.com</a></p>
                                        <p> contact us at :<a className='btn-transparent rn-btn-dark' href="tel:+91 77966 14664">+91 77966 14664</a></p>
                                        <p className="justifypara" >We will respond to your inquiry within a reasonable time.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default PrivacyPolicy;